import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { ref } from "yup";
import axios from "axios";
import Layout from "../screener/layout";
import * as Yup from "yup";
import { useFormik } from "formik";
import authSlice from "../store/slices/auth.ts";
import transparent_color_full_logo from "../images/transparent_color_full_logo.svg";
import { config } from '../screener/constants'


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function Register() {
var REACT_APP_API_URL = config.url.REACT_APP_API_URL


  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { state } = useLocation();


  const plan = state.plan || {};


  useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  const handleRegister = (
    first_name: string,
    last_name: string,
    email: string,
    password: string,
    membership_plan: string
  ) => {
    setLoading(false);
    axios
      .post(
        `${REACT_APP_API_URL}/auth/register/`,
        { first_name, last_name, email, password },
        { withCredentials: true }
      )
      .then((res) => {
        dispatch(
          authSlice.actions.setAuthTokens({
            token: res.data.access,
          })
        );

        if (membership_plan !== "B" && res.status === 201) {
          navigate("/secure-payment", {
            state: { plan: membership_plan, uid: res.data.user.id },
          });
        } else {
          navigate("/login");
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 409) {
          setMessage("A user with that email already exists.");
        }
      });
  };

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      confirmPassword: "",
      newsletter: "",
      terms: "",
    },
    onSubmit: (values) => {
      setLoading(true);
      handleRegister(
        values.firstname,
        values.lastname,
        values.email,
        values.password,
        plan
      );
    },
    validationSchema: Yup.object({
      firstname: Yup.string().trim().required("First name is required"),
      lastname: Yup.string().trim().required("Last name is required"),
      email: Yup.string().email().trim().required("Email is required"),
      password: Yup.string()
        .trim()
        .required("Required")
        .min(8, "Must be 8 characters or more")
        .matches(/[a-z]+/, "One lowercase character")
        .matches(/[A-Z]+/, "One uppercase character")
        .matches(/[@$!%*#?&]+/, "One special character")
        .matches(/\d+/, "One number"),
      confirmPassword: Yup.string()
        .trim()
        .required("Please confirm your password")
        .oneOf([ref("password")], "Passwords do not match"),
      terms: Yup.string()
        .required("You must agree to terms and conditions."),
    }),
  });

  return (
    <Layout>
      <div id="main">
        <div className="inner">
          <div id="content">
            <div className="col-xl-10">
              <div className="card rounded-3 text-black">
                <div className="row g-0">
                  <div className="col-lg-6">
                    <div className="card-body p-md-5 mx-md-4">
                      <div className="text-center">
                        <img
                          src={transparent_color_full_logo}
                          style={{ width: "80%" }}
                          alt="logo"
                        />
                        <br />
                        <br />
                      </div>
                      <form method="post" onSubmit={formik.handleSubmit}>
                        <h4>Register</h4>

                        <div className="form-outline mb-4">
                          <input
                            type="text"
                            name="firstname"
                            placeholder="First Name"
                            value={formik.values.firstname}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.errors.firstname ? (
                            <div>{formik.errors.firstname} </div>
                          ) : null}
                        </div>

                        <div className="form-outline mb-4">
                          <input
                            type="text"
                            name="lastname"
                            placeholder="Last Name"
                            value={formik.values.lastname}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.errors.lastname ? (
                            <div>{formik.errors.lastname} </div>
                          ) : null}
                        </div>

                        <div className="form-outline mb-4">
                          <input
                            id="email"
                            type="email"
                            placeholder="Email"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.errors.email ? (
                            <div>{formik.errors.email} </div>
                          ) : null}
                        </div>

                        <div className="form-outline mb-4">
                          <input
                            name="password"
                            placeholder="Password"
                            type="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.errors.password ? (
                            <div>{formik.errors.password} </div>
                          ) : null}
                        </div>

                        <div className="form-outline mb-4">
                          <input
                            name="confirmPassword"
                            placeholder="Confirm Password"
                            type="password"
                            value={formik.values.confirmPassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.errors.confirmPassword ? (
                            <div>{formik.errors.confirmPassword} </div>
                          ) : null}
                        </div>

                        <div className="form-outline mb-4">
                          <input
                            id="terms"
                            className="form-check-input"
                            type="checkbox"
                            name="terms"
                            value={formik.values.terms}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          <label className="form-check-label" htmlFor="terms">
                            Agree to terms and conditions
                          </label>
                          {formik.errors.terms ? (
                            <div>{formik.errors.terms} </div>
                          ) : null}
                        </div>
                        <div className="flex items-center justify-between">
                          <div
                            className="text-danger text-center my-2"
                            hidden={false}
                          >
                            {message}
                          </div>
                        </div>
                        <a href="/terms-and-conditions" target="_blank">View Terms and Conditions</a>
                              
                        <div className="text-center pt-1 mb-5 pb-1">
                          <button
                            type="submit"
                            disabled={loading}
                            className="btn btn-primary btn-block fa-lg gradient-custom-3 mb-3"
                          >
                            Register
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-lg-6 d-flex align-items-center gradient-custom-2">
                    <div className="text-white px-3 py-4 p-md-5 mx-md-4">
                      <h4 className="mb-4">
                        Where halal investing meets value investing
                      </h4>
                      <p className="mb-0">
                        Investing in stocks is a way for Muslim families to prepare for life essentials such as educating children, home ownership, haaj, or retirement.  The historical growth of the stock market provides a risk-controlled method to build and protect wealth.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Register;
