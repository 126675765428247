// Constants.js
const prod = {
 url: {

    REACT_APP_API_URL: 'https://halalstockscreener.com/api/v1',

    REACT_APP_BASE_URL: 'https://halalstockscreener.com',
    
    REACT_API_IMAGE_URL: '',

    STRIPE_PUBLIC_KEY: "pk_live_51MnCwNHEt9ezyY3MFOxvd5oSCig5UkSQ6LhizOw2yzAnNayGjWHfBCiscLHtPaqY8cPycEb6lPrhGoqurTjxXh6q00520dbQal"
}
};


const dev = {
 url: {

    REACT_APP_API_URL: 'http://127.0.0.1:8000/api/v1',

    REACT_APP_BASE_URL: 'http://127.0.0.1:3000',

    REACT_API_IMAGE_URL: 'http://127.0.0.1:8000',

    STRIPE_PUBLIC_KEY: "pk_test_51L0kR5Chd33xeumOXbFMYl3VPTn5QXWKUwAMyY6FbAk8vmzd4foD3J2KSrJHkGTBYMZcMahmVkgFkhiFq7DArLfp00oduK9iF8"
 }
};


export const config = process.env.NODE_ENV === 'development' ? dev : prod;



