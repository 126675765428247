import React, { useState, useEffect, useRef } from "react";
import { useLocation, Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import Layout from "./layout";
import getFundamentalsData from "./getFundamentalsData_";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, defaults } from "chart.js/auto";
// import { Chart } from "react-chartjs-2";
import Badge from 'react-bootstrap/Badge';
import { Chart, Interaction } from 'chart.js';
import {CrosshairPlugin,Interpolate} from 'chartjs-plugin-crosshair';

Chart.register(CrosshairPlugin);
Interaction.modes.interpolate = Interpolate


function CompanyFundamentals() {
  const [companyData, setCompanyData] = useState([]);
  const [FilteredByDateData3yr, setFilteredByDateData3yr] = useState([]);
  const [FilteredByDateData5yr, setFilteredByDateData5yr] = useState([]);
  const [FilteredByDateData10yr, setFilteredByDateData10yr] = useState([]);
  const [PricesYTD, setPricesYTD] = useState([]);
  const [Prices1Yr, setPrices1yr] = useState([]);
  const [Prices3yr, setPrices3yr] = useState([]);
  const dataFetchedRef = useRef(false);
  const location = useLocation();

  function getSameDayOfYear(date, yearsToAdd){
	date = typeof(date) === "string" ? new Date(date) : date;
	var retDate = new Date(+date);
	retDate.setFullYear(retDate.getFullYear() + yearsToAdd);
	
	var diff = date.getDay() - retDate.getDay();
	retDate.setDate(retDate.getDate() + diff);
	return retDate;
  }

  const today = new Date();
  const oneYearAgo = getSameDayOfYear(today, -1);
  const threeYearsAgo = getSameDayOfYear(today, -3);
  const fiveYearsAgo = getSameDayOfYear(today, -5);
  const tenYearsAgo = getSameDayOfYear(today, -10);

useEffect(() => {


	const ticker= location.state;
	if (dataFetchedRef.current) return;
	dataFetchedRef.current = true;
	getFundamentalsData(ticker).then((data) => setCompanyData(data['fundamentals_list']));

}, []);


useEffect(() => {
	setFilteredByDateData3yr(
		companyData[0]?.fundamentals.filter((obj) => {
			return (
				new Date(obj.date) >=
				threeYearsAgo
			);
		})
	);
	setFilteredByDateData5yr(
		companyData[0]?.fundamentals.filter((obj) => {
			return (
				new Date(obj.date) >=
				fiveYearsAgo
			);
		})
	);
	setFilteredByDateData10yr(
		companyData[0]?.fundamentals.filter((obj) => {
			return (
				new Date(obj.date) >=
				tenYearsAgo
			);
		})
	);
	setPricesYTD(
		companyData[0]?.eod_data.filter((obj) => {
			var datestring = obj.date.toString().replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3");
			return (
				new Date(datestring).getFullYear() ==
				new Date().getFullYear()
			);
		})
	);
	setPrices1yr(
		companyData[0]?.eod_data.filter((obj) => {
			var datestring = obj.date.toString().replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3");
			return (
				new Date(datestring) >=
				oneYearAgo
			);
		})
	);
	setPrices3yr(
		companyData[0]?.eod_data.filter((obj) => {
			var datestring = obj.date.toString().replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3");
			return (
				new Date(datestring) >=
				threeYearsAgo
			);
		})
	);
}, [companyData]);

useEffect(() => {
	window.scrollTo(0, 0);
}, []);

/* ============================================= Price Chart Data YTD, 1yr, 5yr =========================================================== */



const price_dates_ytd = PricesYTD?.map((item) => item.date.toString().replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3"));
const prices_ytd = PricesYTD?.map((item) => item.close);
const price_dates_1yr = Prices1Yr?.map((item) => item.date.toString().replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3"));
const prices_1yr = Prices1Yr?.map((item) => item.close);
const price_dates_3yr = Prices3yr?.map((item) => item.date.toString().replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3"));
const prices_3yr = Prices3yr?.map((item) => item.close);
const price_chart_ytd = {
	labels: price_dates_ytd,
	datasets: [
		{
			label: "Price",
			fill: false,
			lineTension: 0.5,
			backgroundColor: "rgba(75,192,192,1)",
			borderColor: "rgba(0,0,0,1)",
			borderWidth: 2,
			data: prices_ytd,
		},
	],
};

const price_chart_1yr = {
	labels: price_dates_1yr,
	datasets: [
		{
			label: "Price",
			fill: false,
			lineTension: 0.5,
			backgroundColor: "rgba(75,192,192,1)",
			borderColor: "rgba(0,0,0,1)",
			borderWidth: 2,
			data: prices_1yr,
		},
	],
};

const price_chart_3yr = {
	labels: price_dates_3yr,
	datasets: [
		{
			label: "price",
			fill: false,
			lineTension: 0.5,
			backgroundColor: "rgba(75,192,192,1)",
			borderColor: "rgba(0,0,0,1)",
			borderWidth: 2,
			data: prices_3yr,
		},
	],
};
/* ============================================== 3 year data for graphs ==================================================================  */

	const dates_3yr = FilteredByDateData3yr?.map((item) => item.date);
	const totalrevenue_values_3yr = FilteredByDateData3yr?.map((item) => item.totalrevenue);
	const eps_values_3yr = FilteredByDateData3yr?.map((item) => item.EPerS);
	const roe_values_3yr = FilteredByDateData3yr?.map((item) => item.RoE);
	const dtoe_values_3yr = FilteredByDateData3yr?.map((item) => item.debtToEquity);
	const roa_values_3yr = FilteredByDateData3yr?.map((item) => item.ROAssets);
	const roic_values_3yr = FilteredByDateData3yr?.map((item) => item.RoIC);



	const totalrevenue_state_3yr = {
		labels: dates_3yr,
		datasets: [
			{
				label: "Revenue",
				fill: false,
				lineTension: 0.5,
				backgroundColor: "rgba(75,192,192,1)",
				borderColor: "rgba(0,0,0,1)",
				borderWidth: 2,
				data: totalrevenue_values_3yr,
			},
		],
	};

	const eps_state_3yr = {
		labels: dates_3yr,
		datasets: [
			{
				label: "EPS",
				fill: false,
				lineTension: 0.5,
				backgroundColor: "rgba(75,192,192,1)",
				borderColor: "rgba(0,0,0,1)",
				borderWidth: 2,
				data: eps_values_3yr,
			},
		],
	};

	const roe_state_3yr = {
		labels: dates_3yr,
		datasets: [
			{
				label: "ROE",
				fill: false,
				lineTension: 0.5,
				backgroundColor: "rgba(75,192,192,1)",
				borderColor: "rgba(0,0,0,1)",
				borderWidth: 2,
				data: roe_values_3yr,
			},
		],
	};

	const dtoe_state_3yr = {
		labels: dates_3yr,
		datasets: [
			{
				label: "DtoE",
				fill: false,
				lineTension: 0.5,
				backgroundColor: "rgba(75,192,192,1)",
				borderColor: "rgba(0,0,0,1)",
				borderWidth: 2,
				data: dtoe_values_3yr,
			},
		],
	};

	const roa_state_3yr = {
		labels: dates_3yr,
		datasets: [
			{
				label: "ROA",
				fill: false,
				lineTension: 0.5,
				backgroundColor: "rgba(75,192,192,1)",
				borderColor: "rgba(0,0,0,1)",
				borderWidth: 2,
				data: roa_values_3yr,
			},
		],
	};

	const roic_state_3yr = {
		labels: dates_3yr,
		datasets: [
			{
				label: "ROIC",
				fill: false,
				lineTension: 0.5,
				backgroundColor: "rgba(75,192,192,1)",
				borderColor: "rgba(0,0,0,1)",
				borderWidth: 2,
				data: roic_values_3yr,
			},
		],
	};


/* ============================================== 5 year data for graphs ==================================================================  */

	const dates_5yr = FilteredByDateData5yr?.map((item) => item.date);
	const totalrevenue_values_5yr = FilteredByDateData5yr?.map((item) => item.totalrevenue);
	const eps_values_5yr = FilteredByDateData5yr?.map((item) => item.EPerS);
	const roe_values_5yr = FilteredByDateData5yr?.map((item) => item.RoE);
	const dtoe_values_5yr = FilteredByDateData5yr?.map((item) => item.debtToEquity);
	const roa_values_5yr = FilteredByDateData5yr?.map((item) => item.ROAssets);
	const roic_values_5yr = FilteredByDateData5yr?.map((item) => item.RoIC);



	const totalrevenue_state_5yr = {
		labels: dates_5yr,
		datasets: [
			{
				label: "Revenue",
				fill: false,
				lineTension: 0.5,
				backgroundColor: "rgba(75,192,192,1)",
				borderColor: "rgba(0,0,0,1)",
				borderWidth: 2,
				data: totalrevenue_values_5yr,
			},
		],
	};

	const eps_state_5yr = {
		labels: dates_5yr,
		datasets: [
			{
				label: "EPS",
				fill: false,
				lineTension: 0.5,
				backgroundColor: "rgba(75,192,192,1)",
				borderColor: "rgba(0,0,0,1)",
				borderWidth: 2,
				data: eps_values_5yr,
			},
		],
	};

	const roe_state_5yr = {
		labels: dates_5yr,
		datasets: [
			{
				label: "ROE",
				fill: false,
				lineTension: 0.5,
				backgroundColor: "rgba(75,192,192,1)",
				borderColor: "rgba(0,0,0,1)",
				borderWidth: 2,
				data: roe_values_5yr,
			},
		],
	};

	const dtoe_state_5yr = {
		labels: dates_5yr,
		datasets: [
			{
				label: "DtoE",
				fill: false,
				lineTension: 0.5,
				backgroundColor: "rgba(75,192,192,1)",
				borderColor: "rgba(0,0,0,1)",
				borderWidth: 2,
				data: dtoe_values_5yr,
			},
		],
	};

	const roa_state_5yr = {
		labels: dates_5yr,
		datasets: [
			{
				label: "ROA",
				fill: false,
				lineTension: 0.5,
				backgroundColor: "rgba(75,192,192,1)",
				borderColor: "rgba(0,0,0,1)",
				borderWidth: 2,
				data: roa_values_5yr,
			},
		],
	};

	const roic_state_5yr = {
		labels: dates_5yr,
		datasets: [
			{
				label: "ROIC",
				fill: false,
				lineTension: 0.5,
				backgroundColor: "rgba(75,192,192,1)",
				borderColor: "rgba(0,0,0,1)",
				borderWidth: 2,
				data: roic_values_5yr,
			},
		],
	};


/* ============================================== 10 year data for graphs ==================================================================  */

	const dates_10yr = FilteredByDateData10yr?.map((item) => item.date);
	const totalrevenue_values_10yr = FilteredByDateData10yr?.map((item) => item.totalrevenue);
	const eps_values_10yr = FilteredByDateData10yr?.map((item) => item.EPerS);
	const roe_values_10yr = FilteredByDateData10yr?.map((item) => item.RoE);
	const dtoe_values_10yr = FilteredByDateData10yr?.map((item) => item.debtToEquity);
	const roa_values_10yr = FilteredByDateData10yr?.map((item) => item.ROAssets);
	const roic_values_10yr = FilteredByDateData10yr?.map((item) => item.RoIC);



	const totalrevenue_state_10yr = {
		labels: dates_10yr,
		datasets: [
			{
				label: "Revenue",
				fill: false,
				lineTension: 0.5,
				backgroundColor: "rgba(75,192,192,1)",
				borderColor: "rgba(0,0,0,1)",
				borderWidth: 2,
				data: totalrevenue_values_10yr,
			},
		],
	};

	const eps_state_10yr = {
		labels: dates_10yr,
		datasets: [
			{
				label: "EPS",
				fill: false,
				lineTension: 0.5,
				backgroundColor: "rgba(75,192,192,1)",
				borderColor: "rgba(0,0,0,1)",
				borderWidth: 2,
				data: eps_values_10yr,
			},
		],
	};

	const roe_state_10yr = {
		labels: dates_10yr,
		datasets: [
			{
				label: "ROE",
				fill: false,
				lineTension: 0.5,
				backgroundColor: "rgba(75,192,192,1)",
				borderColor: "rgba(0,0,0,1)",
				borderWidth: 2,
				data: roe_values_10yr,
			},
		],
	};

	const dtoe_state_10yr = {
		labels: dates_10yr,
		datasets: [
			{
				label: "DtoE",
				fill: false,
				lineTension: 0.5,
				backgroundColor: "rgba(75,192,192,1)",
				borderColor: "rgba(0,0,0,1)",
				borderWidth: 2,
				data: dtoe_values_10yr,
			},
		],
	};

	const roa_state_10yr = {
		labels: dates_10yr,
		datasets: [
			{
				label: "ROA",
				fill: false,
				lineTension: 0.5,
				backgroundColor: "rgba(75,192,192,1)",
				borderColor: "rgba(0,0,0,1)",
				borderWidth: 2,
				data: roa_values_10yr,
			},
		],
	};

	const roic_state_10yr = {
		labels: dates_10yr,
		datasets: [
			{
				label: "ROIC",
				fill: false,
				lineTension: 0.5,
				backgroundColor: "rgba(75,192,192,1)",
				borderColor: "rgba(0,0,0,1)",
				borderWidth: 2,
				data: roic_values_10yr,
			},
		],
	};

/* =================================== toggle tabs on click =================================== */

const price_values = [
	{ id: "tab1", text: "YTD" },
	{ id: "tab2", text: "1 Year" },
	{ id: "tab3", text: "3 Years" },
];
const [priceActiveId, setPriceActiveId] = useState("tab1");

const values = [
	{ id: "tab1", text: "3 Year Charts" },
	{ id: "tab2", text: "5 Year Charts" },
	{ id: "tab3", text: "10 Year Charts" },
];
const [activeId, setActiveId] = useState("tab1");

var financial_status_tag = '';
	if(companyData[0]?.financial_halal_status_heat_map ==='PASS')
	{
		financial_status_tag = <Badge bg="success">PASS</Badge>;
	}
	else if(companyData[0]?.financial_halal_status_heat_map ==='FAIL')
	{
		financial_status_tag = <Badge bg="danger">FAIL</Badge>;
	}
	else
	{
		financial_status_tag = <Badge bg="warning" text="dark">CAUTION</Badge>;
	}

return (
<Layout>
<div id="main">
	<div className="inner">
		<div id="content">
			<section className="main">
				<header>
					<h1><strong>{companyData[0]?.name} ({companyData[0]?.ticker})</strong></h1>
				</header>
				<h4><strong>Industry:</strong> {companyData[0]?.industry}</h4>
				<br></br>
				<h4><strong>Description</strong></h4>
				<h5>{companyData[0]?.description}</h5>
				<br></br>
				<h4>
					<p><strong>Overall Halal Status: {companyData[0]?.overall_halal_status ? <Badge bg="success">PASS</Badge>: <Badge bg="danger">FAIL</Badge>}</strong></p>
					<p>Business Halal Status*: {companyData[0]?.business_halal_status ? <Badge bg="success">PASS</Badge>: <Badge bg="danger">FAIL</Badge>}</p>
					<p>Financial Halal Status**: {financial_status_tag}</p>
					<p style={{'fontSize':'.8em'}}>*Business halal status refers to the company's business practices which are <Link to="/screener-methodology" className="accent1" style={{'textDecoration':'underline'}}>screened for compatability with halal principles.</Link><br/>**Financial halal status is the company's status according to the <Link to="/screener-methodology" className="accent1" style={{'textDecoration':'underline'}}>AAOFI halal stock screening criteria.</Link></p>
					{companyData[0]?.financial_halal_status_heat_map === "CAUTION" ? (<p style={{'fontSize':'.8em'}}>A <Badge bg="warning" text="dark">CAUTION</Badge> designation means that the stock is currently passing the financial metrics and is considered halal. <br/>However, in its most recent financial statement, it was within 10% of accepted values.  <br/>In this case, you should be careful to watch for the next financal statment to ensure that it remains halal.</p>) : (<></>)}
				</h4>
				<h2>Valuation Data</h2>
				<br/>
			<MDBContainer className='fluid' style={{'border':'1px solid','color':'blk', padding:'10px', maxWidth:'100%'}}>
      <MDBRow className='mb-3'>
        <MDBCol size='3'>
          Latest Close Price: <strong>{companyData[0]?.current_price}</strong>
        </MDBCol>
        <MDBCol size='3'>
          1 day price change: <span style={{ fontWeight:'bold',
  				color: (companyData[0]?.current_price-companyData[0]?.day_previous_price)/companyData[0]?.day_previous_price*100 < 0 ? "red" : "green"
					}}>
  				{((companyData[0]?.current_price-companyData[0]?.day_previous_price)/companyData[0]?.day_previous_price*100).toFixed(2)}%
					</span>
        </MDBCol>
        <MDBCol size='3'>
        YTD Returns: <span style={{ fontWeight:'bold',
  				color: (companyData[0]?.current_price-companyData[0]?.year_begin_price)/companyData[0]?.year_begin_price*100 < 0 ? "red" : "green"
					}}>{((companyData[0]?.current_price-companyData[0]?.year_begin_price)/companyData[0]?.year_begin_price*100).toFixed(2)}%</span>
        </MDBCol>
        <MDBCol size='3'>
        12 Month Returns: <span style={{ fontWeight:'bold',
  				color: (companyData[0]?.current_price-companyData[0]?.one_year_ago_price)/companyData[0]?.one_year_ago_price*100 < 0 ? "red" : "green"
					}}>{((companyData[0]?.current_price-companyData[0]?.one_year_ago_price)/companyData[0]?.one_year_ago_price*100).toFixed(2)}%</span>
        </MDBCol>
       </MDBRow>
      <MDBRow className='mb-3'>
        <MDBCol size='3'>
          Most Recent Quarter: <strong>{companyData[0]?.valuation_data[0]?.date}</strong>
        </MDBCol>
        <MDBCol size='3'>
          Price to Earnings (PE): <span style={{ fontWeight:'bold',
  				color: companyData[0]?.valuation_data[0]?.PtoE < 0 ? "red" : "green"
					}}>{companyData[0]?.valuation_data[0]?.PtoE}</span>
        </MDBCol>
        <MDBCol size='3'>
        Revenue (ttm): <span style={{ fontWeight:'bold',
  				color: companyData[0]?.valuation_data[0]?.revenue_ttm < 0 ? "red" : "green"
					}}>{companyData[0]?.valuation_data[0]?.revenue_ttm.toLocaleString('en-US')}</span>
        </MDBCol>
        <MDBCol size='3'>
        Profit Margin: <span style={{ fontWeight:'bold',
  				color: companyData[0]?.valuation_data[0]?.profitmargin < 0 ? "red" : "green"
					}}>{companyData[0]?.valuation_data[0]?.profitmargin}</span>
        </MDBCol>
       </MDBRow>
      <MDBRow className='mb-3'>
        <MDBCol size='3'>          
        Earnings Per Share (EPS): <span style={{ fontWeight:'bold',
  				color: companyData[0]?.valuation_data[0]?.EPerS < 0 ? "red" : "green"
					}}>{companyData[0]?.valuation_data[0]?.EPerS}</span>
        </MDBCol>
        <MDBCol size='3'>
        Price to Earnings Growth (PEG): <span style={{ fontWeight:'bold',
  				color: companyData[0]?.valuation_data[0]?.PtoEG < 0 ? "red" : "green"
					}}>{companyData[0]?.valuation_data[0]?.PtoEG}</span>
        </MDBCol>
        <MDBCol size='3'>
          Revenue per Share (ttm): <span style={{ fontWeight:'bold',
  				color: companyData[0]?.valuation_data[0]?.revenue_per_share_ttm < 0 ? "red" : "green"
					}}>{companyData[0]?.valuation_data[0]?.revenue_per_share_ttm}</span>
        </MDBCol>
        <MDBCol size='3'>
          Gross Profit Margin (ttm): <span style={{ fontWeight:'bold',
  				color: companyData[0]?.valuation_data[0]?.grossprofitmargin_ttm < 0 ? "red" : "green"
					}}>{companyData[0]?.valuation_data[0]?.grossprofitmargin_ttm.toLocaleString('en-US')}</span>
        </MDBCol>
      </MDBRow>
      <MDBRow className='mb-3'>
        <MDBCol size='3'>          
          EPS Estimate (Current Quarter): <span style={{ fontWeight:'bold',
  				color: companyData[0]?.valuation_data[0]?.EPerS_estimate_current_quarter < 0 ? "red" : "green"
					}}>{companyData[0]?.valuation_data[0]?.EPerS_estimate_current_quarter}</span>
        </MDBCol>
        <MDBCol size='3'>
        Forward PE: <span style={{ fontWeight:'bold',
  				color: companyData[0]?.valuation_data[0]?.forward_PtoE < 0 ? "red" : "green"
					}}>{companyData[0]?.valuation_data[0]?.forward_PtoE}</span>
        </MDBCol>
        <MDBCol size='3'>
          Return on Equity (ttm): <span style={{ fontWeight:'bold',
  				color: companyData[0]?.valuation_data[0]?.RoE_ttm < 0 ? "red" : "green"
					}}>{companyData[0]?.valuation_data[0]?.RoE_ttm}</span>
        </MDBCol>
        <MDBCol size='3'>
          Operating Margin (ttm): <span style={{ fontWeight:'bold',
  				color: companyData[0]?.valuation_data[0]?.operatingmargin_ttm < 0 ? "red" : "green"
					}}>{companyData[0]?.valuation_data[0]?.operatingmargin_ttm}</span>
        </MDBCol>
      </MDBRow>
      <MDBRow className='mb-3'>
        <MDBCol size='3'>
          EPS Estimate (Current Year): <span style={{ fontWeight:'bold',
  				color: companyData[0]?.valuation_data[0]?.EPerS_estimate_current_year < 0 ? "red" : "green"
					}}>{companyData[0]?.valuation_data[0]?.EPerS_estimate_current_year}</span>
        </MDBCol>
        <MDBCol size='3'>
        Trailing PE: <span style={{ fontWeight:'bold',
  				color: companyData[0]?.valuation_data[0]?.trailing_PtoE < 0 ? "red" : "green"
					}}>{companyData[0]?.valuation_data[0]?.trailing_PtoE}</span>
        </MDBCol>
        <MDBCol size='3'>
          Return on Assets (ttm): <span style={{ fontWeight:'bold',
  				color: companyData[0]?.valuation_data[0]?.ROAssets_ttm < 0 ? "red" : "green"
					}}>{companyData[0]?.valuation_data[0]?.ROAssets_ttm}</span>
        </MDBCol>
        <MDBCol size='3'>
          Dividends Per Share: <span style={{ fontWeight:'bold',
  				color: companyData[0]?.valuation_data[0]?.dividends_per_share < 0 ? "red" : "green"
					}}>{companyData[0]?.valuation_data[0]?.dividends_per_share}</span>
        </MDBCol>
      </MDBRow>
      <MDBRow className='mb-3'>
        <MDBCol size='3'>
        EPS Estimate (Next Quarter): <span style={{ fontWeight:'bold',
  				color: companyData[0]?.valuation_data[0]?.EPerS_estimate_next_quarter < 0 ? "red" : "green"
					}}>{companyData[0]?.valuation_data[0]?.EPerS_estimate_next_quarter}</span>
        </MDBCol>
        <MDBCol size='3'>
        Price to Book (mrq): <span style={{ fontWeight:'bold',
  				color: companyData[0]?.valuation_data[0]?.PriceToBook_mrq < 0 ? "red" : "green"
					}}>{companyData[0]?.valuation_data[0]?.PriceToBook_mrq}</span>
        </MDBCol>
        <MDBCol size='3'>
          Enterprise Value: <span style={{ fontWeight:'bold',
  				color: companyData[0]?.valuation_data[0]?.enterprisevalue < 0 ? "red" : "green"
					}}>{companyData[0]?.valuation_data[0]?.enterprisevalue.toLocaleString('en-US')}</span>
        </MDBCol>
        <MDBCol size='3'>
          Dividend Yield: <span style={{ fontWeight:'bold',
  				color: companyData[0]?.valuation_data[0]?.dividendyield < 0 ? "red" : "green"
					}}>{companyData[0]?.valuation_data[0]?.dividendyield}</span>
        </MDBCol>
      </MDBRow>
      <MDBRow className='mb-3'>
        <MDBCol size='3'> 
        EPS Estimate (Next Year): <span style={{ fontWeight:'bold',
  				color: companyData[0]?.valuation_data[0]?.EPerS_estimate_next_year < 0 ? "red" : "green"
					}}>{companyData[0]?.valuation_data[0]?.EPerS_estimate_next_year}</span>        
        </MDBCol>
        <MDBCol size='3'>
        Price to Sales (ttm): <span style={{ fontWeight:'bold',
  				color: companyData[0]?.valuation_data[0]?.PriceToSales_ttm < 0 ? "red" : "green"
					}}>{companyData[0]?.valuation_data[0]?.PriceToSales_ttm}</span>
        </MDBCol>
        <MDBCol size='3'>
          Book Value: <span style={{ fontWeight:'bold',
  				color: companyData[0]?.valuation_data[0]?.bookvalue < 0 ? "red" : "green"
					}}>{companyData[0]?.valuation_data[0]?.bookvalue}</span>
        </MDBCol>
        <MDBCol size='3'>
          Management Effectiveness: <span style={{ fontWeight:'bold',
  				color: companyData[0]?.valuation_data[0]?.management_effectiveness < 0 ? "red" : "green"
					}}>{companyData[0]?.valuation_data[0]?.management_effectiveness}</span>
        </MDBCol>
      </MDBRow>
      <MDBRow className='mb-3'>
        <MDBCol size='3'> 
        Diluted EPS (ttm): <span style={{ fontWeight:'bold',
  				color: companyData[0]?.valuation_data[0]?.diluted_EPerS_ttm < 0 ? "red" : "green"
					}}>{companyData[0]?.valuation_data[0]?.diluted_EPerS_ttm}</span>         
        </MDBCol>
        <MDBCol size='3'>
        Quarterly Earning Growth (YOY): <span style={{ fontWeight:'bold',
  				color: companyData[0]?.valuation_data[0]?.quarterly_earnings_growth_yoy < 0 ? "red" : "green"
					}}>{companyData[0]?.valuation_data[0]?.quarterly_earnings_growth_yoy}</span>
        </MDBCol>
        <MDBCol size='3'>
          EBITDA: <span style={{ fontWeight:'bold',
  				color: companyData[0]?.valuation_data[0]?.ebitda < 0 ? "red" : "green"
					}}>{companyData[0]?.valuation_data[0]?.ebitda.toLocaleString('en-US')}</span>
        </MDBCol>
        <MDBCol size='3'>
          Wallstreet Target Price: <span style={{ fontWeight:'bold',
  				color: companyData[0]?.valuation_data[0]?.wallstreettargetprice < 0 ? "red" : "green"
					}}>{companyData[0]?.valuation_data[0]?.wallstreettargetprice}</span>
        </MDBCol>
      </MDBRow>
      <MDBRow className='mb-3'>
        <MDBCol size='3'> 
        Market Cap: <span style={{ fontWeight:'bold',
  				color: companyData[0]?.valuation_data[0]?.marketcap < 0 ? "red" : "green"
					}}>{companyData[0]?.valuation_data[0]?.marketcap.toLocaleString('en-US')}</span>         
        </MDBCol>
        <MDBCol size='3'>
        Quarterly Revenue Growth (ttm): <span style={{ fontWeight:'bold',
  				color: companyData[0]?.valuation_data[0]?.quarterly_revenue_growth_ttm < 0 ? "red" : "green"
					}}>{companyData[0]?.valuation_data[0]?.quarterly_revenue_growth_ttm}</span>
        </MDBCol>
        <MDBCol size='3'>
          Enerprise value to EBITDA: <span style={{ fontWeight:'bold',
  				color: companyData[0]?.valuation_data[0]?.EnterpriseValueToEbitda < 0 ? "red" : "green"
					}}>{companyData[0]?.valuation_data[0]?.EnterpriseValueToEbitda}</span>
        </MDBCol>
        <MDBCol size='3'>
          Enterprise Value to Revenue: <span style={{ fontWeight:'bold',
  				color: companyData[0]?.valuation_data[0]?.EnterpriseValueToRevenue < 0 ? "red" : "green"
					}}>{companyData[0]?.valuation_data[0]?.EnterpriseValueToRevenue}</span>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
    <br/>
    <br/>
    <br/>







	<div className="col-md-14">
						<div className="tab-block">
							<ul className="nav nav-tabs">
						        {price_values.map((val) => (
						          <li key={val.id} onClick={() => setPriceActiveId(val.id)} className={priceActiveId === val.id ? "nav-item active" : "nav-item"}>
						            <a className="nav-link" href={`#${val.id}`}>{val.text}</a>
						          </li>
						        ))}
							</ul>

							<div className="tab-content p30">
								<div className={priceActiveId === "tab1" ? "tab-pane active" : "tab-pane"}>
								<section className="main">
									<div >
										<Line
											data={price_chart_ytd}
											options={{
												responsive: true,
												plugins: {
													legend: {
														display: false,
													},
													title: {
														display: true,
														text: "Daily Close Prices YTD",
														font: {
															size: 15,
														},
														padding: {
															top: 0,
															left: 0,
															right: 0,
															bottom: 20,
														},
													},
													tooltip: {
														mode: 'interpolate',
														intersect: false
													  },
													  crosshair: {
														line: {
														  color: '#F66',  // crosshair line color
														  width: .5        // crosshair line width
														},
														sync: {
														  enabled: true,            // enable trace line syncing with other charts
														  group: 1,                 // chart group
														  suppressTooltips: false   // suppress tooltips when showing a synced tracer
														},
														zoom: {
														  enabled: true,                                      // enable zooming
														  zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box 
														  zoomboxBorderColor: '#48F',                         // border color of zoom box
														  zoomButtonText: 'Reset Zoom',                       // reset zoom button text
														  zoomButtonClass: 'reset-zoom',                      // reset zoom button class
														},
														callbacks: {
														  beforeZoom: () => function(start, end) {                  // called before zoom, return false to prevent zoom
															return true;
														  },
														  afterZoom: () => function(start, end) {                   // called after zoom
														  }
														}
													  },
												},
												scales: {
													x: {
														display: true,
														title: {
															display: true,
															text: "Date",
															font: {
																size: 15,
																lineHeight: 1.2,
															},
															padding: {
																top: 10,
																left: 0,
																right: 0,
																bottom: 0,
															},
														},
													},
													y: {
														display: true,
														title: {
															display: true,
															text: "Price ($)",
															font: {
																size: 15,
																lineHeight: 1.2,
															},
															padding: {
																top: 0,
																left: 0,
																right: 0,
																bottom: 20,
															},
														},
													},
												},
											}}
										/>
									</div>

										
								</section>
								</div>
								<div className={priceActiveId === "tab2" ? "tab-pane active" : "tab-pane"}>
									<section className="main">
									<div>
										<Line
											data={price_chart_1yr}
											options={{
												responsive: true,
												elements: {
													point: {
													  radius: .5,
													  hoverRadius: .5, // ex.: to make it bigger when user hovers put larger number than radius.
													}
												  },
												plugins: {
													legend: {
														display: false,
													},
													title: {
														display: true,
														text: "Daily Close Prices 1 Year",
														font: {
															size: 15,
														},
														padding: {
															top: 0,
															left: 0,
															right: 0,
															bottom: 20,
														},
													},
													tooltip: {
														mode: 'interpolate',
														intersect: false
													  },
													  crosshair: {
														line: {
														  color: '#F66',  // crosshair line color
														  width: .5        // crosshair line width
														},
														sync: {
														  enabled: true,            // enable trace line syncing with other charts
														  group: 1,                 // chart group
														  suppressTooltips: false   // suppress tooltips when showing a synced tracer
														},
														zoom: {
														  enabled: true,                                      // enable zooming
														  zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box 
														  zoomboxBorderColor: '#48F',                         // border color of zoom box
														  zoomButtonText: 'Reset Zoom',                       // reset zoom button text
														  zoomButtonClass: 'reset-zoom',                      // reset zoom button class
														},
														callbacks: {
														  beforeZoom: () => function(start, end) {                  // called before zoom, return false to prevent zoom
															return true;
														  },
														  afterZoom: () => function(start, end) {                   // called after zoom
														  }
														}
													  },
												},
												scales: {
													x: {
														display: true,
														title: {
															display: true,
															text: "Date",
															font: {
																size: 15,
																lineHeight: 1.2,
															},
															padding: {
																top: 10,
																left: 0,
																right: 0,
																bottom: 0,
															},
														},
													},
													y: {
														display: true,
														title: {
															display: true,
															text: "Price ($)",
															font: {
																size: 15,
																lineHeight: 1.2,
															},
															padding: {
																top: 0,
																left: 0,
																right: 0,
																bottom: 20,
															},
														},
													},
												},
											}}
										/>
									</div>														
								</section>
								</div>
								<div className={priceActiveId === "tab3" ? "tab-pane active" : "tab-pane"}>
									<section className="main">
									<div>
										<Line
											data={price_chart_3yr}
											options={{
												responsive: true,
												elements: {
													point: {
													  radius: .05,
													  hoverRadius: .1, // ex.: to make it bigger when user hovers put larger number than radius.
													}
												  },
												plugins: {
													legend: {
														display: false,
													},
													title: {
														display: true,
														text: "Daily Close Prices 3 Yrs",
														font: {
															size: 15,
														},
														padding: {
															top: 0,
															left: 0,
															right: 0,
															bottom: 20,
														},
													},
													tooltip: {
														mode: 'interpolate',
														intersect: false
													  },
													  crosshair: {
														line: {
														  color: '#F66',  // crosshair line color
														  width: .05        // crosshair line width
														},
														sync: {
														  enabled: true,            // enable trace line syncing with other charts
														  group: 1,                 // chart group
														  suppressTooltips: false   // suppress tooltips when showing a synced tracer
														},
														zoom: {
														  enabled: true,                                      // enable zooming
														  zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box 
														  zoomboxBorderColor: '#48F',                         // border color of zoom box
														  zoomButtonText: 'Reset Zoom',                       // reset zoom button text
														  zoomButtonClass: 'reset-zoom',                      // reset zoom button class
														},
														callbacks: {
														  beforeZoom: () => function(start, end) {                  // called before zoom, return false to prevent zoom
															return true;
														  },
														  afterZoom: () => function(start, end) {                   // called after zoom
														  }
														}
													  },
												},
												scales: {
													x: {
														display: true,
														title: {
															display: true,
															text: "Date",
															font: {
																size: 15,
																lineHeight: .5,
															},
															padding: {
																top: 10,
																left: 0,
																right: 0,
																bottom: 0,
															},
														},
													},
													y: {
														display: true,
														title: {
															display: true,
															text: "Price ($)",
															font: {
																size: 15,
																lineHeight: 1.2,
															},
															padding: {
																top: 0,
																left: 0,
																right: 0,
																bottom: 20,
															},
														},
													},
												},
											}}
										/>
									</div>															
								</section>
								</div>
							</div>
						</div>
					</div>
					<br/>
					<br/>










					<div className="col-md-14">
						<div className="tab-block">
							<ul className="nav nav-tabs">
						        {values.map((val) => (
						          <li key={val.id} onClick={() => setActiveId(val.id)} className={activeId === val.id ? "nav-item active" : "nav-item"}>
						            <a className="nav-link" href={`#${val.id}`}>{val.text}</a>
						          </li>
						        ))}
							</ul>

							<div className="tab-content p30">
								<div className={activeId === "tab1" ? "tab-pane active" : "tab-pane"}>
								<section className="main">
									<div className="graph-container">
										<Line
											data={totalrevenue_state_3yr}
											options={{
												responsive: true,
												plugins: {
													legend: {
														display: false,
													},
													title: {
														display: true,
														text: "Total Revenue",
														font: {
															size: 15,
														},
														padding: {
															top: 0,
															left: 0,
															right: 0,
															bottom: 20,
														},
													},
													tooltip: {
														mode: 'interpolate',
														intersect: false
													  },
													  crosshair: {
														line: {
														  color: '#F66',  // crosshair line color
														  width: 1        // crosshair line width
														},
														sync: {
														  enabled: true,            // enable trace line syncing with other charts
														  group: 1,                 // chart group
														  suppressTooltips: false   // suppress tooltips when showing a synced tracer
														},
														zoom: {
														  enabled: true,                                      // enable zooming
														  zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box 
														  zoomboxBorderColor: '#48F',                         // border color of zoom box
														  zoomButtonText: 'Reset Zoom',                       // reset zoom button text
														  zoomButtonClass: 'reset-zoom',                      // reset zoom button class
														},
														callbacks: {
														  beforeZoom: () => function(start, end) {                  // called before zoom, return false to prevent zoom
															return true;
														  },
														  afterZoom: () => function(start, end) {                   // called after zoom
														  }
														}
													  },
												},
												scales: {
													x: {
														display: true,
														title: {
															display: true,
															text: "Quarterly Report Date",
															font: {
																size: 15,
																lineHeight: 1.2,
															},
															padding: {
																top: 10,
																left: 0,
																right: 0,
																bottom: 0,
															},
														},
													},
													y: {
														display: true,
														title: {
															display: true,
															text: "Value ($)",
															font: {
																size: 15,
																lineHeight: 1.2,
															},
															padding: {
																top: 0,
																left: 0,
																right: 0,
																bottom: 20,
															},
														},
													},
												},
											}}
										/>
									</div>

									<div className="graph-container">
										<Line
											data={eps_state_3yr}
											options={{
												responsive: true,
												plugins: {
													legend: {
														display: false,
													},
													title: {
														display: true,
														text: "Earnings Per Share",
														font: {
															size: 15,
														},
														padding: {
															top: 0,
															left: 0,
															right: 0,
															bottom: 20,
														},
													},
													tooltip: {
														mode: 'interpolate',
														intersect: false
													  },
													  crosshair: {
														line: {
														  color: '#F66',  // crosshair line color
														  width: 1        // crosshair line width
														},
														sync: {
														  enabled: true,            // enable trace line syncing with other charts
														  group: 1,                 // chart group
														  suppressTooltips: false   // suppress tooltips when showing a synced tracer
														},
														zoom: {
														  enabled: true,                                      // enable zooming
														  zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box 
														  zoomboxBorderColor: '#48F',                         // border color of zoom box
														  zoomButtonText: 'Reset Zoom',                       // reset zoom button text
														  zoomButtonClass: 'reset-zoom',                      // reset zoom button class
														},
														callbacks: {
														  beforeZoom: () => function(start, end) {                  // called before zoom, return false to prevent zoom
															return true;
														  },
														  afterZoom: () => function(start, end) {                   // called after zoom
														  }
														}
													  },
												},
												scales: {
													x: {
														display: true,
														title: {
															display: true,
															text: "Quarterly Report Date",
															font: {
																size: 15,
																lineHeight: 1.2,
															},
															padding: {
																top: 10,
																left: 0,
																right: 0,
																bottom: 0,
															},
														},
													},
													y: {
														display: true,
														title: {
															display: true,
															text: "Value",
															font: {
																size: 15,
																lineHeight: 1.2,
															},
															padding: {
																top: 0,
																left: 0,
																right: 0,
																bottom: 20,
															},
														},
													},
												},
											}}
										/>
									</div>

									<div className="graph-container">
										<Line
											data={roe_state_3yr}
											options={{
												responsive: true,
												plugins: {
													legend: {
														display: false,
													},
													title: {
														display: true,
														text: "Return on Equity",
														font: {
															size: 15,
														},
														padding: {
															top: 0,
															left: 0,
															right: 0,
															bottom: 20,
														},
													},
													tooltip: {
														mode: 'interpolate',
														intersect: false
													  },
													  crosshair: {
														line: {
														  color: '#F66',  // crosshair line color
														  width: 1        // crosshair line width
														},
														sync: {
														  enabled: true,            // enable trace line syncing with other charts
														  group: 1,                 // chart group
														  suppressTooltips: false   // suppress tooltips when showing a synced tracer
														},
														zoom: {
														  enabled: true,                                      // enable zooming
														  zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box 
														  zoomboxBorderColor: '#48F',                         // border color of zoom box
														  zoomButtonText: 'Reset Zoom',                       // reset zoom button text
														  zoomButtonClass: 'reset-zoom',                      // reset zoom button class
														},
														callbacks: {
														  beforeZoom: () => function(start, end) {                  // called before zoom, return false to prevent zoom
															return true;
														  },
														  afterZoom: () => function(start, end) {                   // called after zoom
														  }
														}
													  },
												},
												scales: {
													x: {
														display: true,
														title: {
															display: true,
															text: "Quarterly Report Date",
															font: {
																size: 15,
																lineHeight: 1.2,
															},
															padding: {
																top: 10,
																left: 0,
																right: 0,
																bottom: 0,
															},
														},
													},
													y: {
														display: true,
														title: {
															display: true,
															text: "Value",
															font: {
																size: 15,
																lineHeight: 1.2,
															},
															padding: {
																top: 0,
																left: 0,
																right: 0,
																bottom: 20,
															},
														},
													},
												},
											}}
										/>
									</div>

									<div className="graph-container">
										<Line
											data={dtoe_state_3yr}
											options={{
												responsive: true,
												plugins: {
													legend: {
														display: false,
													},
													title: {
														display: true,
														text: "Debt to Equity",
														font: {
															size: 15,
														},
														padding: {
															top: 0,
															left: 0,
															right: 0,
															bottom: 20,
														},
													},
													tooltip: {
														mode: 'interpolate',
														intersect: false
													  },
													  crosshair: {
														line: {
														  color: '#F66',  // crosshair line color
														  width: 1        // crosshair line width
														},
														sync: {
														  enabled: true,            // enable trace line syncing with other charts
														  group: 1,                 // chart group
														  suppressTooltips: false   // suppress tooltips when showing a synced tracer
														},
														zoom: {
														  enabled: true,                                      // enable zooming
														  zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box 
														  zoomboxBorderColor: '#48F',                         // border color of zoom box
														  zoomButtonText: 'Reset Zoom',                       // reset zoom button text
														  zoomButtonClass: 'reset-zoom',                      // reset zoom button class
														},
														callbacks: {
														  beforeZoom: () => function(start, end) {                  // called before zoom, return false to prevent zoom
															return true;
														  },
														  afterZoom: () => function(start, end) {                   // called after zoom
														  }
														}
													  },
												},
												scales: {
													x: {
														display: true,
														title: {
															display: true,
															text: "Quarterly Report Date",
															font: {
																size: 15,
																lineHeight: 1.2,
															},
															padding: {
																top: 10,
																left: 0,
																right: 0,
																bottom: 0,
															},
														},
													},
													y: {
														display: true,
														title: {
															display: true,
															text: "Value",
															font: {
																size: 15,
																lineHeight: 1.2,
															},
															padding: {
																top: 0,
																left: 0,
																right: 0,
																bottom: 20,
															},
														},
													},
												},
											}}
										/>
									</div>

									<div className="graph-container">
										<Line
											data={roa_state_3yr}
											options={{
												responsive: true,
												plugins: {
													legend: {
														display: false,
													},
													title: {
														display: true,
														text: "Return on Assets",
														font: {
															size: 15,
														},
														padding: {
															top: 0,
															left: 0,
															right: 0,
															bottom: 20,
														},
													},
													tooltip: {
														mode: 'interpolate',
														intersect: false
													  },
													  crosshair: {
														line: {
														  color: '#F66',  // crosshair line color
														  width: 1        // crosshair line width
														},
														sync: {
														  enabled: true,            // enable trace line syncing with other charts
														  group: 1,                 // chart group
														  suppressTooltips: false   // suppress tooltips when showing a synced tracer
														},
														zoom: {
														  enabled: true,                                      // enable zooming
														  zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box 
														  zoomboxBorderColor: '#48F',                         // border color of zoom box
														  zoomButtonText: 'Reset Zoom',                       // reset zoom button text
														  zoomButtonClass: 'reset-zoom',                      // reset zoom button class
														},
														callbacks: {
														  beforeZoom: () => function(start, end) {                  // called before zoom, return false to prevent zoom
															return true;
														  },
														  afterZoom: () => function(start, end) {                   // called after zoom
														  }
														}
													  },
												},
												scales: {
													x: {
														display: true,
														title: {
															display: true,
															text: "Quarterly Report Date",
															font: {
																size: 15,
																lineHeight: 1.2,
															},
															padding: {
																top: 10,
																left: 0,
																right: 0,
																bottom: 0,
															},
														},
													},
													y: {
														display: true,
														title: {
															display: true,
															text: "Value",
															font: {
																size: 15,
																lineHeight: 1.2,
															},
															padding: {
																top: 0,
																left: 0,
																right: 0,
																bottom: 20,
															},
														},
													},
												},
											}}
										/>
									</div>	

									<div className="graph-container">
										<Line
											data={roic_state_3yr}
											options={{
												responsive: true,
												plugins: {
													legend: {
														display: false,
													},
													title: {
														display: true,
														text: "Return on Invested Capitol",
														font: {
															size: 15,
														},
														padding: {
															top: 0,
															left: 0,
															right: 0,
															bottom: 20,
														},
													},
													tooltip: {
														mode: 'interpolate',
														intersect: false
													  },
													  crosshair: {
														line: {
														  color: '#F66',  // crosshair line color
														  width: 1        // crosshair line width
														},
														sync: {
														  enabled: true,            // enable trace line syncing with other charts
														  group: 1,                 // chart group
														  suppressTooltips: false   // suppress tooltips when showing a synced tracer
														},
														zoom: {
														  enabled: true,                                      // enable zooming
														  zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box 
														  zoomboxBorderColor: '#48F',                         // border color of zoom box
														  zoomButtonText: 'Reset Zoom',                       // reset zoom button text
														  zoomButtonClass: 'reset-zoom',                      // reset zoom button class
														},
														callbacks: {
														  beforeZoom: () => function(start, end) {                  // called before zoom, return false to prevent zoom
															return true;
														  },
														  afterZoom: () => function(start, end) {                   // called after zoom
														  }
														}
													  },
												},
												scales: {
													x: {
														display: true,
														title: {
															display: true,
															text: "Quarterly Report Date",
															font: {
																size: 15,
																lineHeight: 1.2,
															},
															padding: {
																top: 10,
																left: 0,
																right: 0,
																bottom: 0,
															},
														},
													},
													y: {
														display: true,
														title: {
															display: true,
															text: "Value",
															font: {
																size: 15,
																lineHeight: 1.2,
															},
															padding: {
																top: 0,
																left: 0,
																right: 0,
																bottom: 20,
															},
														},
													},
												},
											}}
										/>
									</div>							
								</section>
								</div>
								<div className={activeId === "tab2" ? "tab-pane active" : "tab-pane"}>
									<section className="main">
									<div className="graph-container">
										<Line
											data={totalrevenue_state_5yr}
											options={{
												responsive: true,
												plugins: {
													legend: {
														display: false,
													},
													title: {
														display: true,
														text: "Total Revenue",
														font: {
															size: 15,
														},
														padding: {
															top: 0,
															left: 0,
															right: 0,
															bottom: 20,
														},
													},
													tooltip: {
														mode: 'interpolate',
														intersect: false
													  },
													  crosshair: {
														line: {
														  color: '#F66',  // crosshair line color
														  width: 1        // crosshair line width
														},
														sync: {
														  enabled: true,            // enable trace line syncing with other charts
														  group: 1,                 // chart group
														  suppressTooltips: false   // suppress tooltips when showing a synced tracer
														},
														zoom: {
														  enabled: true,                                      // enable zooming
														  zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box 
														  zoomboxBorderColor: '#48F',                         // border color of zoom box
														  zoomButtonText: 'Reset Zoom',                       // reset zoom button text
														  zoomButtonClass: 'reset-zoom',                      // reset zoom button class
														},
														callbacks: {
														  beforeZoom: () => function(start, end) {                  // called before zoom, return false to prevent zoom
															return true;
														  },
														  afterZoom: () => function(start, end) {                   // called after zoom
														  }
														}
													  },
												},
												scales: {
													x: {
														display: true,
														title: {
															display: true,
															text: "Quarterly Report Date",
															font: {
																size: 15,
																lineHeight: 1.2,
															},
															padding: {
																top: 10,
																left: 0,
																right: 0,
																bottom: 0,
															},
														},
													},
													y: {
														display: true,
														title: {
															display: true,
															text: "Value ($)",
															font: {
																size: 15,
																lineHeight: 1.2,
															},
															padding: {
																top: 0,
																left: 0,
																right: 0,
																bottom: 20,
															},
														},
													},
												},
											}}
										/>
									</div>

									<div className="graph-container">
										<Line
											data={eps_state_5yr}
											options={{
												responsive: true,
												plugins: {
													legend: {
														display: false,
													},
													title: {
														display: true,
														text: "Earnings Per Share",
														font: {
															size: 15,
														},
														padding: {
															top: 0,
															left: 0,
															right: 0,
															bottom: 20,
														},
													},
													tooltip: {
														mode: 'interpolate',
														intersect: false
													  },
													  crosshair: {
														line: {
														  color: '#F66',  // crosshair line color
														  width: 1        // crosshair line width
														},
														sync: {
														  enabled: true,            // enable trace line syncing with other charts
														  group: 1,                 // chart group
														  suppressTooltips: false   // suppress tooltips when showing a synced tracer
														},
														zoom: {
														  enabled: true,                                      // enable zooming
														  zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box 
														  zoomboxBorderColor: '#48F',                         // border color of zoom box
														  zoomButtonText: 'Reset Zoom',                       // reset zoom button text
														  zoomButtonClass: 'reset-zoom',                      // reset zoom button class
														},
														callbacks: {
														  beforeZoom: () => function(start, end) {                  // called before zoom, return false to prevent zoom
															return true;
														  },
														  afterZoom: () => function(start, end) {                   // called after zoom
														  }
														}
													  },
												},
												scales: {
													x: {
														display: true,
														title: {
															display: true,
															text: "Quarterly Report Date",
															font: {
																size: 15,
																lineHeight: 1.2,
															},
															padding: {
																top: 10,
																left: 0,
																right: 0,
																bottom: 0,
															},
														},
													},
													y: {
														display: true,
														title: {
															display: true,
															text: "Value",
															font: {
																size: 15,
																lineHeight: 1.2,
															},
															padding: {
																top: 0,
																left: 0,
																right: 0,
																bottom: 20,
															},
														},
													},
												},
											}}
										/>
									</div>

									<div className="graph-container">
										<Line
											data={roe_state_5yr}
											options={{
												responsive: true,
												plugins: {
													legend: {
														display: false,
													},
													title: {
														display: true,
														text: "Return on Equity",
														font: {
															size: 15,
														},
														padding: {
															top: 0,
															left: 0,
															right: 0,
															bottom: 20,
														},
													},
													tooltip: {
														mode: 'interpolate',
														intersect: false
													  },
													  crosshair: {
														line: {
														  color: '#F66',  // crosshair line color
														  width: 1        // crosshair line width
														},
														sync: {
														  enabled: true,            // enable trace line syncing with other charts
														  group: 1,                 // chart group
														  suppressTooltips: false   // suppress tooltips when showing a synced tracer
														},
														zoom: {
														  enabled: true,                                      // enable zooming
														  zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box 
														  zoomboxBorderColor: '#48F',                         // border color of zoom box
														  zoomButtonText: 'Reset Zoom',                       // reset zoom button text
														  zoomButtonClass: 'reset-zoom',                      // reset zoom button class
														},
														callbacks: {
														  beforeZoom: () => function(start, end) {                  // called before zoom, return false to prevent zoom
															return true;
														  },
														  afterZoom: () => function(start, end) {                   // called after zoom
														  }
														}
													  },
												},
												scales: {
													x: {
														display: true,
														title: {
															display: true,
															text: "Quarterly Report Date",
															font: {
																size: 15,
																lineHeight: 1.2,
															},
															padding: {
																top: 10,
																left: 0,
																right: 0,
																bottom: 0,
															},
														},
													},
													y: {
														display: true,
														title: {
															display: true,
															text: "Value",
															font: {
																size: 15,
																lineHeight: 1.2,
															},
															padding: {
																top: 0,
																left: 0,
																right: 0,
																bottom: 20,
															},
														},
													},
												},
											}}
										/>
									</div>

									<div className="graph-container">
										<Line
											data={dtoe_state_5yr}
											options={{
												responsive: true,
												plugins: {
													legend: {
														display: false,
													},
													title: {
														display: true,
														text: "Debt to Equity",
														font: {
															size: 15,
														},
														padding: {
															top: 0,
															left: 0,
															right: 0,
															bottom: 20,
														},
													},
													tooltip: {
														mode: 'interpolate',
														intersect: false
													  },
													  crosshair: {
														line: {
														  color: '#F66',  // crosshair line color
														  width: 1        // crosshair line width
														},
														sync: {
														  enabled: true,            // enable trace line syncing with other charts
														  group: 1,                 // chart group
														  suppressTooltips: false   // suppress tooltips when showing a synced tracer
														},
														zoom: {
														  enabled: true,                                      // enable zooming
														  zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box 
														  zoomboxBorderColor: '#48F',                         // border color of zoom box
														  zoomButtonText: 'Reset Zoom',                       // reset zoom button text
														  zoomButtonClass: 'reset-zoom',                      // reset zoom button class
														},
														callbacks: {
														  beforeZoom: () => function(start, end) {                  // called before zoom, return false to prevent zoom
															return true;
														  },
														  afterZoom: () => function(start, end) {                   // called after zoom
														  }
														}
													  },
												},
												scales: {
													x: {
														display: true,
														title: {
															display: true,
															text: "Quarterly Report Date",
															font: {
																size: 15,
																lineHeight: 1.2,
															},
															padding: {
																top: 10,
																left: 0,
																right: 0,
																bottom: 0,
															},
														},
													},
													y: {
														display: true,
														title: {
															display: true,
															text: "Value",
															font: {
																size: 15,
																lineHeight: 1.2,
															},
															padding: {
																top: 0,
																left: 0,
																right: 0,
																bottom: 20,
															},
														},
													},
												},
											}}
										/>
									</div>

									<div className="graph-container">
										<Line
											data={roa_state_5yr}
											options={{
												responsive: true,
												plugins: {
													legend: {
														display: false,
													},
													title: {
														display: true,
														text: "Return on Assets",
														font: {
															size: 15,
														},
														padding: {
															top: 0,
															left: 0,
															right: 0,
															bottom: 20,
														},
													},
													tooltip: {
														mode: 'interpolate',
														intersect: false
													  },
													  crosshair: {
														line: {
														  color: '#F66',  // crosshair line color
														  width: 1        // crosshair line width
														},
														sync: {
														  enabled: true,            // enable trace line syncing with other charts
														  group: 1,                 // chart group
														  suppressTooltips: false   // suppress tooltips when showing a synced tracer
														},
														zoom: {
														  enabled: true,                                      // enable zooming
														  zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box 
														  zoomboxBorderColor: '#48F',                         // border color of zoom box
														  zoomButtonText: 'Reset Zoom',                       // reset zoom button text
														  zoomButtonClass: 'reset-zoom',                      // reset zoom button class
														},
														callbacks: {
														  beforeZoom: () => function(start, end) {                  // called before zoom, return false to prevent zoom
															return true;
														  },
														  afterZoom: () => function(start, end) {                   // called after zoom
														  }
														}
													  },
												},
												scales: {
													x: {
														display: true,
														title: {
															display: true,
															text: "Quarterly Report Date",
															font: {
																size: 15,
																lineHeight: 1.2,
															},
															padding: {
																top: 10,
																left: 0,
																right: 0,
																bottom: 0,
															},
														},
													},
													y: {
														display: true,
														title: {
															display: true,
															text: "Value",
															font: {
																size: 15,
																lineHeight: 1.2,
															},
															padding: {
																top: 0,
																left: 0,
																right: 0,
																bottom: 20,
															},
														},
													},
												},
											}}
										/>
									</div>	

									<div className="graph-container">
										<Line
											data={roic_state_5yr}
											options={{
												responsive: true,
												plugins: {
													legend: {
														display: false,
													},
													title: {
														display: true,
														text: "Return on Invested Capitol",
														font: {
															size: 15,
														},
														padding: {
															top: 0,
															left: 0,
															right: 0,
															bottom: 20,
														},
													},
													tooltip: {
														mode: 'interpolate',
														intersect: false
													  },
													  crosshair: {
														line: {
														  color: '#F66',  // crosshair line color
														  width: 1        // crosshair line width
														},
														sync: {
														  enabled: true,            // enable trace line syncing with other charts
														  group: 1,                 // chart group
														  suppressTooltips: false   // suppress tooltips when showing a synced tracer
														},
														zoom: {
														  enabled: true,                                      // enable zooming
														  zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box 
														  zoomboxBorderColor: '#48F',                         // border color of zoom box
														  zoomButtonText: 'Reset Zoom',                       // reset zoom button text
														  zoomButtonClass: 'reset-zoom',                      // reset zoom button class
														},
														callbacks: {
														  beforeZoom: () => function(start, end) {                  // called before zoom, return false to prevent zoom
															return true;
														  },
														  afterZoom: () => function(start, end) {                   // called after zoom
														  }
														}
													  },
												},
												scales: {
													x: {
														display: true,
														title: {
															display: true,
															text: "Quarterly Report Date",
															font: {
																size: 15,
																lineHeight: 1.2,
															},
															padding: {
																top: 10,
																left: 0,
																right: 0,
																bottom: 0,
															},
														},
													},
													y: {
														display: true,
														title: {
															display: true,
															text: "Value",
															font: {
																size: 15,
																lineHeight: 1.2,
															},
															padding: {
																top: 0,
																left: 0,
																right: 0,
																bottom: 20,
															},
														},
													},
												},
											}}
										/>
									</div>							
								</section>
								</div>
								<div className={activeId === "tab3" ? "tab-pane active" : "tab-pane"}>
									<section className="main">
									<div className="graph-container">
										<Line
											data={totalrevenue_state_10yr}
											options={{
												responsive: true,
												plugins: {
													legend: {
														display: false,
													},
													title: {
														display: true,
														text: "Total Revenue",
														font: {
															size: 15,
														},
														padding: {
															top: 0,
															left: 0,
															right: 0,
															bottom: 20,
														},
													},
													tooltip: {
														mode: 'interpolate',
														intersect: false
													  },
													  crosshair: {
														line: {
														  color: '#F66',  // crosshair line color
														  width: 1        // crosshair line width
														},
														sync: {
														  enabled: true,            // enable trace line syncing with other charts
														  group: 1,                 // chart group
														  suppressTooltips: false   // suppress tooltips when showing a synced tracer
														},
														zoom: {
														  enabled: true,                                      // enable zooming
														  zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box 
														  zoomboxBorderColor: '#48F',                         // border color of zoom box
														  zoomButtonText: 'Reset Zoom',                       // reset zoom button text
														  zoomButtonClass: 'reset-zoom',                      // reset zoom button class
														},
														callbacks: {
														  beforeZoom: () => function(start, end) {                  // called before zoom, return false to prevent zoom
															return true;
														  },
														  afterZoom: () => function(start, end) {                   // called after zoom
														  }
														}
													  },
												},
												scales: {
													x: {
														display: true,
														title: {
															display: true,
															text: "Quarterly Report Date",
															font: {
																size: 15,
																lineHeight: 1.2,
															},
															padding: {
																top: 10,
																left: 0,
																right: 0,
																bottom: 0,
															},
														},
													},
													y: {
														display: true,
														title: {
															display: true,
															text: "Value ($)",
															font: {
																size: 15,
																lineHeight: 1.2,
															},
															padding: {
																top: 0,
																left: 0,
																right: 0,
																bottom: 20,
															},
														},
													},
												},
											}}
										/>
									</div>

									<div className="graph-container">
										<Line
											data={eps_state_10yr}
											options={{
												responsive: true,
												plugins: {
													legend: {
														display: false,
													},
													title: {
														display: true,
														text: "Earnings Per Share",
														font: {
															size: 15,
														},
														padding: {
															top: 0,
															left: 0,
															right: 0,
															bottom: 20,
														},
													},
													tooltip: {
														mode: 'interpolate',
														intersect: false
													  },
													  crosshair: {
														line: {
														  color: '#F66',  // crosshair line color
														  width: 1        // crosshair line width
														},
														sync: {
														  enabled: true,            // enable trace line syncing with other charts
														  group: 1,                 // chart group
														  suppressTooltips: false   // suppress tooltips when showing a synced tracer
														},
														zoom: {
														  enabled: true,                                      // enable zooming
														  zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box 
														  zoomboxBorderColor: '#48F',                         // border color of zoom box
														  zoomButtonText: 'Reset Zoom',                       // reset zoom button text
														  zoomButtonClass: 'reset-zoom',                      // reset zoom button class
														},
														callbacks: {
														  beforeZoom: () => function(start, end) {                  // called before zoom, return false to prevent zoom
															return true;
														  },
														  afterZoom: () => function(start, end) {                   // called after zoom
														  }
														}
													  },
												},
												scales: {
													x: {
														display: true,
														title: {
															display: true,
															text: "Quarterly Report Date",
															font: {
																size: 15,
																lineHeight: 1.2,
															},
															padding: {
																top: 10,
																left: 0,
																right: 0,
																bottom: 0,
															},
														},
													},
													y: {
														display: true,
														title: {
															display: true,
															text: "Value",
															font: {
																size: 15,
																lineHeight: 1.2,
															},
															padding: {
																top: 0,
																left: 0,
																right: 0,
																bottom: 20,
															},
														},
													},
												},
											}}
										/>
									</div>

									<div className="graph-container">
										<Line
											data={roe_state_10yr}
											options={{
												responsive: true,
												plugins: {
													legend: {
														display: false,
													},
													title: {
														display: true,
														text: "Return on Equity",
														font: {
															size: 15,
														},
														padding: {
															top: 0,
															left: 0,
															right: 0,
															bottom: 20,
														},
													},
													tooltip: {
														mode: 'interpolate',
														intersect: false
													  },
													  crosshair: {
														line: {
														  color: '#F66',  // crosshair line color
														  width: 1        // crosshair line width
														},
														sync: {
														  enabled: true,            // enable trace line syncing with other charts
														  group: 1,                 // chart group
														  suppressTooltips: false   // suppress tooltips when showing a synced tracer
														},
														zoom: {
														  enabled: true,                                      // enable zooming
														  zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box 
														  zoomboxBorderColor: '#48F',                         // border color of zoom box
														  zoomButtonText: 'Reset Zoom',                       // reset zoom button text
														  zoomButtonClass: 'reset-zoom',                      // reset zoom button class
														},
														callbacks: {
														  beforeZoom: () => function(start, end) {                  // called before zoom, return false to prevent zoom
															return true;
														  },
														  afterZoom: () => function(start, end) {                   // called after zoom
														  }
														}
													  },
												},
												scales: {
													x: {
														display: true,
														title: {
															display: true,
															text: "Quarterly Report Date",
															font: {
																size: 15,
																lineHeight: 1.2,
															},
															padding: {
																top: 10,
																left: 0,
																right: 0,
																bottom: 0,
															},
														},
													},
													y: {
														display: true,
														title: {
															display: true,
															text: "Value",
															font: {
																size: 15,
																lineHeight: 1.2,
															},
															padding: {
																top: 0,
																left: 0,
																right: 0,
																bottom: 20,
															},
														},
													},
												},
											}}
										/>
									</div>

									<div className="graph-container">
										<Line
											data={dtoe_state_10yr}
											options={{
												responsive: true,
												plugins: {
													legend: {
														display: false,
													},
													title: {
														display: true,
														text: "Debt to Equity",
														font: {
															size: 15,
														},
														padding: {
															top: 0,
															left: 0,
															right: 0,
															bottom: 20,
														},
													},
													tooltip: {
														mode: 'interpolate',
														intersect: false
													  },
													  crosshair: {
														line: {
														  color: '#F66',  // crosshair line color
														  width: 1        // crosshair line width
														},
														sync: {
														  enabled: true,            // enable trace line syncing with other charts
														  group: 1,                 // chart group
														  suppressTooltips: false   // suppress tooltips when showing a synced tracer
														},
														zoom: {
														  enabled: true,                                      // enable zooming
														  zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box 
														  zoomboxBorderColor: '#48F',                         // border color of zoom box
														  zoomButtonText: 'Reset Zoom',                       // reset zoom button text
														  zoomButtonClass: 'reset-zoom',                      // reset zoom button class
														},
														callbacks: {
														  beforeZoom: () => function(start, end) {                  // called before zoom, return false to prevent zoom
															return true;
														  },
														  afterZoom: () => function(start, end) {                   // called after zoom
														  }
														}
													  },
												},
												scales: {
													x: {
														display: true,
														title: {
															display: true,
															text: "Quarterly Report Date",
															font: {
																size: 15,
																lineHeight: 1.2,
															},
															padding: {
																top: 10,
																left: 0,
																right: 0,
																bottom: 0,
															},
														},
													},
													y: {
														display: true,
														title: {
															display: true,
															text: "Value",
															font: {
																size: 15,
																lineHeight: 1.2,
															},
															padding: {
																top: 0,
																left: 0,
																right: 0,
																bottom: 20,
															},
														},
													},
												},
											}}
										/>
									</div>

									<div className="graph-container">
										<Line
											data={roa_state_10yr}
											options={{
												responsive: true,
												plugins: {
													legend: {
														display: false,
													},
													title: {
														display: true,
														text: "Return on Assets",
														font: {
															size: 15,
														},
														padding: {
															top: 0,
															left: 0,
															right: 0,
															bottom: 20,
														},
													},
													tooltip: {
														mode: 'interpolate',
														intersect: false
													  },
													  crosshair: {
														line: {
														  color: '#F66',  // crosshair line color
														  width: 1        // crosshair line width
														},
														sync: {
														  enabled: true,            // enable trace line syncing with other charts
														  group: 1,                 // chart group
														  suppressTooltips: false   // suppress tooltips when showing a synced tracer
														},
														zoom: {
														  enabled: true,                                      // enable zooming
														  zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box 
														  zoomboxBorderColor: '#48F',                         // border color of zoom box
														  zoomButtonText: 'Reset Zoom',                       // reset zoom button text
														  zoomButtonClass: 'reset-zoom',                      // reset zoom button class
														},
														callbacks: {
														  beforeZoom: () => function(start, end) {                  // called before zoom, return false to prevent zoom
															return true;
														  },
														  afterZoom: () => function(start, end) {                   // called after zoom
														  }
														}
													  },
												},
												scales: {
													x: {
														display: true,
														title: {
															display: true,
															text: "Quarterly Report Date",
															font: {
																size: 15,
																lineHeight: 1.2,
															},
															padding: {
																top: 10,
																left: 0,
																right: 0,
																bottom: 0,
															},
														},
													},
													y: {
														display: true,
														title: {
															display: true,
															text: "Value",
															font: {
																size: 15,
																lineHeight: 1.2,
															},
															padding: {
																top: 0,
																left: 0,
																right: 0,
																bottom: 20,
															},
														},
													},
												},
											}}
										/>
									</div>	

									<div className="graph-container">
										<Line
											data={roic_state_10yr}
											options={{
												responsive: true,
												plugins: {
													legend: {
														display: false,
													},
													title: {
														display: true,
														text: "Return on Invested Capitol",
														font: {
															size: 15,
														},
														padding: {
															top: 0,
															left: 0,
															right: 0,
															bottom: 20,
														},
													},
													tooltip: {
														mode: 'interpolate',
														intersect: false
													  },
													  crosshair: {
														line: {
														  color: '#F66',  // crosshair line color
														  width: 1        // crosshair line width
														},
														sync: {
														  enabled: true,            // enable trace line syncing with other charts
														  group: 1,                 // chart group
														  suppressTooltips: false   // suppress tooltips when showing a synced tracer
														},
														zoom: {
														  enabled: true,                                      // enable zooming
														  zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box 
														  zoomboxBorderColor: '#48F',                         // border color of zoom box
														  zoomButtonText: 'Reset Zoom',                       // reset zoom button text
														  zoomButtonClass: 'reset-zoom',                      // reset zoom button class
														},
														callbacks: {
														  beforeZoom: () => function(start, end) {                  // called before zoom, return false to prevent zoom
															return true;
														  },
														  afterZoom: () => function(start, end) {                   // called after zoom
														  }
														}
													  },
												},
												scales: {
													x: {
														display: true,
														title: {
															display: true,
															text: "Quarterly Report Date",
															font: {
																size: 15,
																lineHeight: 1.2,
															},
															padding: {
																top: 10,
																left: 0,
																right: 0,
																bottom: 0,
															},
														},
													},
													y: {
														display: true,
														title: {
															display: true,
															text: "Value",
															font: {
																size: 15,
																lineHeight: 1.2,
															},
															padding: {
																top: 0,
																left: 0,
																right: 0,
																bottom: 20,
															},
														},
													},
												},
											}}
										/>
									</div>							
								</section>

								</div>
							</div>
						</div>
					</div>
			</section>
		</div>
	</div>
</div>
</Layout>
);
}
export default CompanyFundamentals;