import React, { useEffect } from "react";
import { Link} from "react-router-dom";


import "animate.css/animate.min.css";
import TwitterContainer from "./twittercontainer";

import Layout from "./layout";

import head_shot from "./assets/images/mm_small.jpg";

function Article() {


	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Layout>
			<div id="main">
				<div className="inner">
					<div id="content">
						<section className="main">
						<div className="u-body u-xl-mode" data-lang="en">
							<section
								className="u-align-center u-clearfix u-container-align-center-lg u-container-align-center-md u-container-align-center-sm u-container-align-center-xl u-section-4"
								id="carousel_76b5"
							>
								
									<div className="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
										<div className="u-layout">
											<div>
												<div className="u-container-align-center-sm u-container-style u-layout-cell u-layout-cell-1">
													<div className="u-container-layout u-container-layout-1">
															<h2
																className="u-align-center-sm u-align-center-xs u-align-left-lg u-align-left-md u-align-left-xl u-custom-font u-font-ubuntu u-text u-text-1"
																
															>
																{" "}
																"The WealthSeed
																Story"
															</h2>
															<p>
																{" "}
																My journey in
																investing began
																back in 2013
																when I moved to
																the Middle East
																to teach physics
																in an
																international
																school. As an
																expat employee,
																there were no
																options such as
																401(k)s or
																retirement
																plans. I knew
																that I needed to
																put money aside
																to pay for my
																children’s
																university
																education, as
																well as prepare
																for my eventual
																return to the
																U.S. Instead of
																just saving
																cash, investing
																in stocks
																provided a way
																for me to grow
																and protect my
																earnings.
																Knowing that all
																stocks are not
																permissible for
																Muslim
																investors, I
																began searching
																for an updated
																list of halal
																stocks.
																Certainly, there
																must be a list
																out there
																somewhere. To my
																surprise, it was
																very difficult
																finding this
																information. I
																did find a
																company that
																screened stocks
																for halal
																status, but
																their published
																list was very
																small, as their
																business focused
																on advising
																banks and other
																financial
																institutions.
																Therefore, I was
																forced to screen
																stocks myself.
																</p>
																<p>
																The next major
																hurdle that I
																encountered was
																how to find data
																for US companies
																so that I could
																screen them.
																This is when I
																realized how
																expensive it is
																to purchase
																data. Quality
																financial data
																can cost
																anywhere from 10
																to 30 thousand
																dollars per
																year. I found a
																data provider
																that gave a
																two-week trial
																period. So, I
																scrambled to
																download data
																for about 1000
																stocks before
																the trial period
																ended.
																Eventually the
																company closed
																the loophole to
																prevent exactly
																what I had done.
																Sorry guys! But,
																at least now I
																had some data to
																work with.
																</p> 

																<p>I
																began studying
																successful
																investors and
																decided that my
																style of
																investing was
																value investing.
																With Warren
																Buffet, a famous
																value investor,
																being one of the
																richest people
																in the world, he
																must know
																something, I
																reasoned. My
																research led me
																to a value
																investing
																formula that was
																an adapted
																version of the
																formula used by
																Benjamin Graham,
																the person who
																taught Warren
																Buffet. This
																formula was a
																bit complex and
																required me to
																find even more
																data. However,
																it worked well
																and I had
																excellent
																returns for the
																three years that
																I used it (35%
																on average,
																annually). 
																</p>

																<p>
																In
																2017, based on
																my personal
																success and
																knowing that
																other Muslims
																would appreciate
																access to this
																information, I
																decided to start
																WealthSeed. In
																2018 I made a
																voyage to
																Silicon Valley
																to pitch my idea
																to investors. I
																wasn’t looking
																for money, just
																validation. I
																was encouraged
																that everyone
																that I spoke
																with thought
																that the idea
																was very
																promising. With
																that, I returned
																to my job in the
																Middle East and
																began building
																the product. I
																decided that
																instead of
																purchasing data,
																I would download
																20 years of
																company
																financial data
																from the SEC,
																and write
																software that
																would automate
																the scraping and
																formatting of
																the data. Big
																mistake! I spent
																a frustrating
																year trying to
																accomplish this,
																until I gave up.
																I eventually
																understood why
																quality data is
																so expensive. I
																decided that I
																should purchase
																the data and
																focus on what my
																main business
																goal was:
																provide halal
																stock screening
																and value
																investment
																screening to
																interested
																investors.
																</p>


																<p> Then,
																in early 2020,
																Covid happened.
																Being a teacher
																put me on the
																front line and
																the stess along
																with immediate
																concerns forced
																me to push my
																idea to the
																background.
																However, during
																that time I
																discovered the
																works of
																investor Joel
																Greenblatt which
																showed me a much
																simpler and very
																successful
																method to value
																stocks. I
																tweaked his
																formula and was
																able to improve
																upon what was
																already a proven
																and successful
																valuation
																strategy. My
																result is the
																WealthSeed Halal
																Value Investing
																Formula. In
																March of 2023 I
																officially
																launched
																WealthSeed to
																the world with
																the goal of
																helping other
																investors who
																have the same
																problem that I
																had: how to
																invest and build
																wealth in a
																halal and safe
																manner.
															</p>
														
													</div>
												</div>
												<div className="u-container-style u-layout-cell u-size-30 u-layout-cell-2">
													<div className="u-container-layout u-valign-middle-lg u-valign-middle-md u-valign-middle-xl u-valign-top-sm u-container-layout-2">
														<div className="u-expanded-width u-list u-list-1">
															<div className="u-repeater u-repeater-1">
																<div className="u-align-center u-container-style u-custom-item u-list-item u-repeater-item u-shape-rectangle u-list-item-1">
																	<div className="u-container-layout u-similar-container u-valign-bottom-lg u-valign-bottom-md u-valign-bottom-sm u-valign-bottom-xl u-container-layout-3">
																																					<div
																				alt=""
																				class="u-image u-image-circle u-image-1"
																				style={{
																					backgroundImage: `url(${head_shot})`,
																				}}
																				data-image-width="2848"
																				data-image-height="4288"
																				data-animation-name="bounceIn"
																				data-animation-duration="1500"
																				data-animation-delay="250"
																				data-animation-direction="Right"
																			></div>
																		
																			<h5
																				className="u-custom-font u-custom-item u-font-ubuntu u-text u-text-default u-text-3"
																				data-animation-name="customAnimationIn"
																				data-animation-duration="1500"
																				data-animation-delay="500"
																			>
																				Muhammad
																				McIntee-Masud
																			</h5>
																			<h6
																				className="u-custom-font u-custom-item u-text u-text-default u-text-font u-text-4"
																				data-animation-name="customAnimationIn"
																				data-animation-duration="1000"
																			>
																				{" "}
																				Founder,
																				CEO
																			</h6>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
							
							</section>
						</div>
						</section>
					</div>


						<div id="sidebar">
							<section className="features">
								<Link to="/companies" className="accent1">
									<h2>Company Data</h2>
									<p>
										Explore data and charts of company
										fundamentals.
									</p>
								</Link>
								<Link to="/learningcenter" className="accent2">
									<h2>Learning Center</h2>
									<p>
										Enhance your understanding of halal and
										value investing.
									</p>
								</Link>
								<Link to="/portfolio" className="accent3">
									<h2>Virtual Portfolio</h2>
									<p>
										Track your virtual trades and test your
										investing strategy.
									</p>
								</Link>
							</section>

							
			           		<section className="mini-posts minimal main" style={{'marginTop':'60px', 'textAlign':'center'}}>
			                	<header>
				                  	<h2>Tweets</h2>
				                  	<h4>@WealthSeedLLC</h4>
				                </header>
				                <article style={{'height':'900px', 'overflowY':'auto'}}>
				                	<TwitterContainer/>
				                </article>
			            	</section>

						</div>
				</div>
			</div>
		</Layout>
	);
}

export default Article;
