import React, { useState, useEffect } from "react";
import Layout from "./layout";
import { Form } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import axiosService from "../utils/axios.ts";
import { useSelector } from "react-redux";
import { RootState } from "../store/index.ts";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { MDBDataTable } from "mdbreact";
import { config } from './constants';


function TradeSimulator() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	var REACT_APP_API_URL = config.url.REACT_APP_API_URL


	const auth = useSelector((state: RootState) => state.auth);
	const tickers = auth.tickers;

	//values in ticker dropdown selector
	const [singleSelections, setSingleSelections] = useState([]);
	const [tradeSingleSelections, setTradeSingleSelections] = useState([]);

	// price lookup results
	const [priceLookupResults, setPriceLookupResults] = useState([]);
	const [tradeMessage, setTradeMessage] = useState("");
	const [loading, setLoading] = useState(false);

	const [priceLookupMessage, setPriceLookupMessage] = useState("");
	const [loading2, setLoading2] = useState(false);

	// Price lookup function and form validation ==================================================================================

	const handlePriceLookup = (values) => {
		var price_date = values.price_date.replaceAll("-", "");
		var ticker = values.ticker;
		// console.log(price_date);

		axiosService
			.post(`${REACT_APP_API_URL}/price-lookup/`, {
				ticker,
				price_date,
			})
			.then((res) => {

				if (res.data.status === 204) {
					setPriceLookupMessage(res.data.message);

				}
				else {
				// convert date to yyyy-mm-dd format
				const date = res.data.data.date.toString().replace(
					/(\d{4})(\d{2})(\d{2})/,
					"$1-$2-$3"
				);
				res.data.data.date = date;
				const results = res.data.data;
				//set price lookup results with appended data and force a render on the table
				setPriceLookupResults((priceLookupResults) => [
					results,
					...priceLookupResults,
				]);
				priceLookupResults.push(results);
				// console.log(priceLookupResults);
				// console.log(res.data);
				}
			});

		setLoading(false);
	};

	const priceLookupFormik = useFormik({
		initialValues: {
			ticker: "",
			price_date: "",
		},
		onSubmit: (values) => {
			setLoading(true);
			handlePriceLookup(values);
			setSingleSelections([]);
			priceLookupFormik.resetForm({ values: priceLookupFormik.initialValues });
		},
		validationSchema: Yup.object({
			ticker: Yup.string().trim().required("Company is required"),
			price_date: Yup.date().required("Date is required"),
		}),
	});

	// Make a trade function and form validation ===================================================================================

	const handleTrade = (values) => {
		var ticker = values.ticker;
		var trade_date = values.trade_date.replaceAll("-", "");
		var num_shares = values.num_shares;
		var action = values.action;

		axiosService
			.post(`${REACT_APP_API_URL}/trade/`, {
				ticker,
				trade_date,
				num_shares,
				action,
			})
			.then((res) => {
				// console.log(res.data);
				setTradeMessage(res.data.message);
			});
		setLoading2(false);
	};

	const tradeFormik = useFormik({
		initialValues: {
			ticker: "",
			trade_date: "",
			num_shares: "",
			action: "",
		},
		onSubmit: (values) => {
			// console.log("submitting trade");
			setLoading2(true);
			handleTrade(values);
			setTradeSingleSelections([]);
			tradeFormik.resetForm({ values: tradeFormik.initialValues });
		},
		validationSchema: Yup.object({
			ticker: Yup.string().trim().required("Company is required"),
			trade_date: Yup.date().required("Date is required"),
			num_shares: Yup.string().trim().required("Number of shares is required"),
			action: Yup.string().trim().required("Buy or Sell is required"),
		}),
	});

	// set up table for returned price lookup values ====================================================================================================

	const data = {
		columns: [
			{
				label: "Date",
				field: "date",
				width: 150,
			},
			{
				label: "Ticker",
				field: "ticker",
				width: 150,
			},
			{
				label: "Open",
				field: "open",
				width: 150,
			},
			{
				label: "High",
				field: "high",
				sort: "disabled",
				width: 150,
			},
			{
				label: "Low",
				field: "low",
				sort: "disabled",
				width: 150,
			},
			{
				label: "Close",
				field: "close",
				sort: "disabled",
				width: 150,
			},
			{
				label: "Volume",
				field: "volume",
				sort: "disabled",
				width: 150,
			},
		],
		rows: priceLookupResults,
	};

	return (
		<Layout>
			<div id="main">
				<div className="inner">
					<div id="content">
						<header>
							<h1>Trade Simulator</h1>
						</header>
						<section className="main" style={{backgroundColor:"#F8F8F8", padding:"30px"}}>
							<h3>
								<strong>Price Lookup</strong>
							</h3>
							<br />
							<form method="post" onSubmit={priceLookupFormik.handleSubmit}>
								<div className="row gtr-uniform">
									<div className="col-6 col-12-xsmall">
										<Typeahead
											id="ticker"
											name="ticker"
											labelKey="ticker"
											options={tickers}
											placeholder="Company Ticker Symbol"
											selected={singleSelections}
											onChange={(selected) => {
												setSingleSelections(selected);
												const value = selected.length > 0 ? selected[0] : "";
												priceLookupFormik.setFieldValue("ticker", value);
											}}
										/>
										{priceLookupFormik.errors.ticker ? (
											<div>{priceLookupFormik.errors.ticker} </div>
										) : null}
									</div>
									<div className="col-6 col-12-xsmall">
										<Form.Control
											type="date"
											name="price_date"
											placeholder="Date"
											value={priceLookupFormik.values.price_date}
											onChange={priceLookupFormik.handleChange}
											onBlur={priceLookupFormik.handleBlur}
										/>
										{priceLookupFormik.errors.price_date ? (
											<div>{priceLookupFormik.errors.price_date} </div>
										) : null}
									</div>
									<div className="col-12">
										<ul className="actions fixed">
											<li>
												<input type="submit" disabled={loading} />
											</li>
											<li>
												<input
													type="reset"
													className="alt"
													onClick={(e) => {
														priceLookupFormik.resetForm();
														setSingleSelections([]);
														setPriceLookupMessage([]);
													}}
												/>
											</li>
										</ul>
									</div>
								</div>
							</form>
							<div
                            className="text-danger text-center my-2"
                            hidden={false}
                          >
                            {priceLookupMessage}
                          </div>
							<br></br>
							<br></br>
							<h4>
								<strong>Results</strong>
							</h4>
							<br />
							<div className="table-wrapper">
								<MDBDataTable
									bordered
									small
									searching={false}
									paging={false}
									noBottomColumns={true}
									data={data}
								/>
							</div>
							<input
								value="Reset Results Table"
								style={{ margin: 10 }}
								type="reset"
								onClick={(e) => {
									setPriceLookupResults([]);
									setPriceLookupMessage([]);
								}}
							/>
						</section>

						<section className="main">
							<h3>
								<strong>Initiate Trade</strong>
							</h3>
							<br />
							<form method="post" onSubmit={tradeFormik.handleSubmit}>
								<div className="row gtr-uniform">
									<div className="col-6 col-12-xsmall">
										<Typeahead
											id="tradeTicker"
											name="tradeTticker"
											labelKey="tradeTicker"
											class="ticker-select"
											options={tickers}
											placeholder="Company Ticker Symbol"
											selected={tradeSingleSelections}
											onChange={(selected) => {
												setTradeSingleSelections(selected);
												const value = selected.length > 0 ? selected[0] : "";
												tradeFormik.setFieldValue("ticker", value);
											}}
										/>
										{tradeFormik.errors.ticker ? (
											<div>{tradeFormik.errors.ticker} </div>
										) : null}
									</div>
									<div className="col-6 col-12-xsmall">
										<Form.Control
											type="date"
											name="trade_date"
											placeholder="Date"
											value={tradeFormik.values.trade_date}
											onChange={tradeFormik.handleChange}
											onBlur={tradeFormik.handleBlur}
										/>
										{tradeFormik.errors.trade_date ? (
											<div>{tradeFormik.errors.trade_date} </div>
										) : null}
									</div>
									<div className="col-6 col-12-xsmall">
										<input
											type="text"
											name="num_shares"
											id="num_shares"
											placeholder="Number of Shares"
											value={tradeFormik.values.num_shares}
											onChange={tradeFormik.handleChange}
											onBlur={tradeFormik.handleBlur}
										/>
										{tradeFormik.errors.num_shares ? (
											<div>{tradeFormik.errors.num_shares} </div>
										) : null}
									</div>
									<div className="col-6 col-12-xsmall">
										<select
											name="action"
											id="action"
											value={tradeFormik.values.action}
											onChange={tradeFormik.handleChange}
											onBlur={tradeFormik.handleBlur}
										>
											<option value="">- Action -</option>
											<option value="buy">Buy</option>
											<option value="sell">Sell</option>
										</select>
										{tradeFormik.errors.action ? (
											<div>{tradeFormik.errors.action} </div>
										) : null}
									</div>

									<div className="col-12">
										<ul className="actions fixed">
											<li>
												<input type="submit" disabled={loading2} />
											</li>
											<li>
												<input
													type="reset"
													value="Reset"
													className="alt"
													onClick={(e) => {
														tradeFormik.resetForm();
														setTradeSingleSelections([]);
														setTradeMessage([]);
													}}
												/>
											</li>
										</ul>
									</div>
								</div>
							</form>
							<div
                            className="text-danger text-center my-2"
                            hidden={false}
                          >
                            {tradeMessage}
                          </div>
						</section>
					</div>
				</div>
			</div>
		</Layout>
	);
}
export default TradeSimulator;
