
import React from "react";
import { Redirect, Route, Routes, RouteProps, useLocation } from "react-router";
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from "react-redux";
import { RootState } from "../store/index.ts";

const ProtectedRoute = (props: RouteProps) => {
  const auth = useSelector((state: RootState) => state.auth);
  return auth.account ? props.children : <Navigate to="/login"/>;
};

export default ProtectedRoute;