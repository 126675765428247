import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import YoutubeEmbed from "./YoutubeEmbed";

import Layout from "./layout";
import axiosService from "../utils/axios.ts";
import { config } from './constants';
import TwitterContainer from "./twittercontainer";



function VideoList() {

	var REACT_APP_API_URL = config.url.REACT_APP_API_URL
	var REACT_API_IMAGE_URL = config.url.REACT_API_IMAGE_URL


	const [videoData, setVideoData] = useState([]);
	const [last4ArticleData, setLast4ArticleData] = useState([]);
	var parse = require("html-react-parser");

  	const dataFetchedRef = useRef(false);

	useEffect(() => {

	// Avoid hitting API twice
  	if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
	axiosService
		.get(`${REACT_APP_API_URL}/video-blogs/`, {
			withCredentials: true,
		})
		.then((res) => {
			setVideoData(res.data["video_blogs"]);
			setLast4ArticleData(res.data["last_4_articles"]);
		});
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Layout>
			<div id="main">
				<div className="inner">
					<div id="content">
						<section className="main">
							<header>
								<h1>Videos</h1>
							</header>
							<section className="posts">
								{Object.keys(videoData).map((key) => {
									return (
										<article
											key={key}
											style={{
												borderBottom: "1px solid",
												borderColor: "#e0e0e0",
											}}
										>
											<YoutubeEmbed
												embedId={
													videoData[key].embed_id
												}
											/>

											<div className="content">
												<h3>{videoData[key].title}</h3>
												{parse(
													videoData[key].teaser_text
												)}
											</div>
										</article>
									);
								})}
							</section>
						</section>
					</div>
					<div id="sidebar">
						<section className="features">
							<Link to="/companies" className="accent1">
								<h2>Company Data</h2>
								<p>
									Explore data and charts of company
									fundamentals.
								</p>
							</Link>
							<Link to="/learningcenter" className="accent2">
								<h2>Learning Center</h2>
								<p>
									Enhance your understanding of halal and
									value investing.
								</p>
							</Link>
							<Link to="/portfolio" className="accent3">
								<h2>Virtual Portfolio</h2>
								<p>
									Track your virtual trades and test your
									investing strategy.
								</p>
							</Link>
						</section>

						<section className="main">
							<header>
								<h2>Latest Articles</h2>
							</header>
							<section className="mini-posts small">
								{Object.keys(last4ArticleData).map((key) => {
									return (
										<article key={key}>
											<div className="image">
												<Link
													to={`/article?title=${last4ArticleData[key].slug}`}
												>
													<img
														src={`${REACT_API_IMAGE_URL}${last4ArticleData[key].thumbnail_image}`}
														alt=""
														style={{
															width: "105px",
															height: "105px",
														}}
													/>
												</Link>
											</div>
											<div className="content">
												<Link
													to={`/article?title=${last4ArticleData[key].slug}`}
												>
													{parse(
														last4ArticleData[key]
															.teaser_text
													)}
												</Link>
												<div className="timestamp"></div>
											</div>
										</article>
									);
								})}

								<ul className="actions fixed">
									<li>
										<Link
											to="/article-list"
											className="button"
										>
											More Posts
										</Link>
									</li>
								</ul>
							</section>
						</section>
			           	<section className="mini-posts minimal main" style={{'marginTop':'60px', 'textAlign':'center'}}>
			                <header>
			                  <h2>Tweets</h2>
			                  <h4>@WealthSeedLLC</h4>
			                </header>
			                <article style={{'height':'900px', 'overflowY':'auto'}}>
			                <TwitterContainer/>
			                </article>
			            </section>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export default VideoList;
