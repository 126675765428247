import React, { Component } from "react";
import Header from "./header";
import Footer from "./footer";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";


class Layout extends Component {


   render() {
      return (
         <div>
            <Header />
            {this.props.children}
            <CookieConsent
               // debug={true}
               location="bottom"
               buttonText="Got it!"
               buttonClasses="cookie-btn"
               cookieName="myAwesomeCookieName2"
               style={{ background: "#2B373B" }}
               buttonStyle={{
                  color: "#007c9e",
                  background: "#007c9e",
                  fontSize: "15px",
                  fontWeight: "bold",
               }}
               expires={150}
            >
               This website uses cookies to enhance the user experience.{" "}
               <Link to="/cookie-policy">
               <span
                  style={{
                     color: '#ffffff',
                     marginLeft: "10px",
                     textDecoration: "underline",
                  }}
               >
                  {" "}
                  Learn more
               </span>
               </Link>
            </CookieConsent>

            <Footer />
         </div>
      );
   }
}

export default Layout;
