import React, { useState, useEffect } from "react";
import FontAwesome from "react-fontawesome";
import Layout from "./layout";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../store/index.ts";

function Pricing() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const navigate = useNavigate();
	const [isYearly, setisYearly] = useState(false);
	const toggleClass = () => {
		setisYearly(isYearly === false ? true : false);
	};
	const auth = useSelector((state: RootState) => state.auth);
	return (
		<Layout>
			<div id="main">
				<div className="inner">
					<div id="content">
						<header>
							<h1>Pricing</h1>
							<p>Get the data, build your wealth.</p>
						</header>
						<div className="price-slider">
								<div>Annually</div>
								<label className="switch">
									<input
										onClick={toggleClass}
										type="checkbox"
									/>
									<span className="slider round"></span>
								</label>
								<div>Monthly</div>
							</div>
						<section className="main pricing">
							{isYearly === false ? (
								<div className="card-deck mb-3 text-center">
									<div className="card mb-4 box-shadow">
										<div className="card-header">
											<h4 className="my-0 font-weight-normal">
												Basic
											</h4>
										</div>
										<div className="card-body">
											<h1 className="card-title pricing-card-title">
												$0{" "}
												<small className="text-muted">
													/ yr
												</small>
											</h1>
											<h4
												style={{ visibility: "hidden" }}
											>
												{" "}
												n
											</h4>
											<ul className="list-unstyled mt-3 mb-4">
												<br />
												<li>
													<FontAwesome
														className="fa-solid fa-check"
														name="check"
														style={{
															display:
																"inline-block",
															textAlign: "center",
															marginRight: ".5em",
															color: "#007bff",
														}}
													/>
													Halal Stock Screener (Top 3000
													companies)
												</li>
												<br />
												<li>
													<FontAwesome
														className="fa-solid fa-check"
														name="check"
														style={{
															display:
																"inline-block",
															textAlign: "center",
															marginRight: ".5em",
															color: "#007bff",
														}}
													/>
													10 Year Financial Data (Top
													3000 companies)
												</li>
												<br />
												<li><li>
													<FontAwesome
														className="fa-solid fa-check"
														name="check"
														style={{
															display:
																"inline-block",
															textAlign: "center",
															marginRight: ".5em",
															color: "#007bff",
														}}
													/>
													Trade Simulator
												</li>
												<br />
													<FontAwesome
														className="fa-solid fa-check"
														name="check"
														style={{
															display:
																"inline-block",
															textAlign: "center",
															marginRight: ".5em",
															color: "#007bff",
														}}
													/>
													Educational Resources
													(Limited)
												</li>
												<br />
												<br />
												<br />
												<br />
												<br />
												<br />
												<br />
												<br />
												<br />
											</ul>
											{auth.account ? (
												<button
													onClick={() =>
														navigate("/account")
													}
													type="button"
													className="btn btn-lg btn-block btn-outline-primary"
												>
													Sign up for free
												</button>
											) : (
												<button
													onClick={() => 
														navigate(
															"/register", {state: {plan:"B"}})
													}
													type="button"
													className="btn btn-lg btn-block btn-outline-primary"
												>
													Sign up for free
												</button>
											)}
										</div>
									</div>
									<div className="card mb-4 box-shadow">
										<div className="card-header">
											<h4 className="my-0 font-weight-normal">
												Advanced
											</h4>
										</div>
										<div className="card-body">
											<h1 className="card-title pricing-card-title">
												$11.99{" "}
												<small className="text-muted">
													/ mo
												</small>
											</h1>
											<h4>
												<strong>Billed Annually</strong>
											</h4>
											<ul className="list-unstyled mt-3 mb-4">
												<br />
												<li>
													<FontAwesome
														className="fa-solid fa-check"
														name="check"
														style={{
															display:
																"inline-block",
															textAlign: "center",
															marginRight: ".5em",
															color: "#007bff",
														}}
													/>
													Halal Stock Screener (Top
													3000 US companies)
												</li>
												<br />
												<li>
													<FontAwesome
														className="fa-solid fa-check"
														name="check"
														style={{
															display:
																"inline-block",
															textAlign: "center",
															marginRight: ".5em",
															color: "#007bff",
														}}
													/>
													Historical Top 30 Companies screened
													for value based on our market beating
													valuation formula. (Halal
													List and Standard Stocks
													List)
												</li>
												<br/>
												<li>
													<FontAwesome
														className="fa-solid fa-check"
														name="check"
														style={{
															display:
																"inline-block",
															textAlign: "center",
															marginRight: ".5em",
															color: "#007bff",
														}}
													/>
													10 Year Financial Data (Top
													3000 US companies)
												</li>
												<br />
												<li>
													<FontAwesome
														className="fa-solid fa-check"
														name="check"
														style={{
															display:
																"inline-block",
															textAlign: "center",
															marginRight: ".5em",
															color: "#007bff",
														}}
													/>
													Trade Simulator
												</li>
												<br />
												<li>
													<FontAwesome
														className="fa-solid fa-check"
														name="check"
														style={{
															display:
																"inline-block",
															textAlign: "center",
															marginRight: ".5em",
															color: "#007bff",
														}}
													/>
													Educational Resources
													(complete archives)
												</li>
												<br />
												<br />
												<br />
												<br />
												<br />
											</ul>

											{auth.account ? (
												<button
													onClick={() =>
														navigate("/secure-payment", {state: { plan: "AY", uid: auth.account.id }})
													}
													type="button"
													className="btn btn-lg btn-block btn-primary"
												>
													Sign up
												</button>
											) : (
												<button
													onClick={() =>
														navigate(
															"/register", {state: {plan:"AY"}}
														)
													}
													type="button"
													className="btn btn-lg btn-block btn-primary"
												>
													Sign up
												</button>
											)}
										</div>
									</div>
									<div className="card mb-4 box-shadow">
										<div className="card-header">
											<h4 className="my-0 font-weight-normal">
												Pro
											</h4>
										</div>
										<div className="card-body">
											<h1 className="card-title pricing-card-title">
												$14.99{" "}
												<small className="text-muted">
													/ mo
												</small>
											</h1>
											<h4>
												<strong>Billed Annually</strong>
											</h4>
											<ul className="list-unstyled mt-3 mb-4">
												<br />
												<li>
													<FontAwesome
														className="fa-solid fa-check"
														name="check"
														style={{
															display:
																"inline-block",
															textAlign: "center",
															marginRight: ".5em",
															color: "#007bff",
														}}
													/>
													Halal Stock Screener (Top
													3000 US companies)
												</li>
												<br />
												<li>
													<FontAwesome
														className="fa-solid fa-check"
														name="check"
														style={{
															display:
																"inline-block",
															textAlign: "center",
															marginRight: ".5em",
															color: "#007bff",
														}}
													/>
													Historical Top 30 Companies screened
													for value based on our market beating
													valuation formula. (Halal
													List and Standard Stocks
													List)
												</li>
												<br />
												<li>
													<FontAwesome
														className="fa-solid fa-check"
														name="check"
														style={{
															display:
																"inline-block",
															textAlign: "center",
															marginRight: ".5em",
															color: "#007bff",
														}}
													/>
													Current Top 30 Companies screened
													for value based on our market beating
													valuation formula. (Halal
													List and Standard Stocks
													List)
												</li>
												<br />
												<li>
													<FontAwesome
														className="fa-solid fa-check"
														name="check"
														style={{
															display:
																"inline-block",
															textAlign: "center",
															marginRight: ".5em",
															color: "#007bff",
														}}
													/>
													10 Year Financial Data (Top
													3000 US companies)
												</li>
												<br />
												<li>
													<FontAwesome
														className="fa-solid fa-check"
														name="check"
														style={{
															display:
																"inline-block",
															textAlign: "center",
															marginRight: ".5em",
															color: "#007bff",
														}}
													/>
													Trade Simulator
												</li>
												<br />
												<li>
													<FontAwesome
														className="fa-solid fa-check"
														name="check"
														style={{
															display:
																"inline-block",
															textAlign: "center",
															marginRight: ".5em",
															color: "#007bff",
														}}
													/>
													Educational Resources
													(complete archives)
												</li>
												<br />
											</ul>
											{auth.account ? (
												<button
													onClick={() =>
														navigate("/secure-payment", {state: { plan: "PY", uid: auth.account.id }})
													}
													type="button"
													className="btn btn-lg btn-block btn-primary"
												>
													Sign up
												</button>
											) : (
												<button
													onClick={() =>
														navigate(
															"/register", {state: {plan:"PY"}}
														)
													}
													type="button"
													className="btn btn-lg btn-block btn-primary"
												>
													Sign up
												</button>
											)}
										</div>
									</div>
								</div>
							) : (
								<div className="card-deck mb-3 text-center">
									<div className="card mb-4 box-shadow">
										<div className="card-header">
											<h4 className="my-0 font-weight-normal">
												Basic
											</h4>
										</div>
										<div className="card-body">
											<h1 className="card-title pricing-card-title">
												$0{" "}
												<small className="text-muted">
													/ mo
												</small>
											</h1>
											<h4
												style={{ visibility: "hidden" }}
											>
												{" "}
												n
											</h4>
											<ul className="list-unstyled mt-3 mb-4">
												<br />
												<li>
													<FontAwesome
														className="fa-solid fa-check"
														name="check"
														style={{
															display:
																"inline-block",
															textAlign: "center",
															marginRight: ".5em",
															color: "#007bff",
														}}
													/>
													Halal Stock Screener (Top 3000
													companies)
												</li>
												<br />
												<li>
													<FontAwesome
														className="fa-solid fa-check"
														name="check"
														style={{
															display:
																"inline-block",
															textAlign: "center",
															marginRight: ".5em",
															color: "#007bff",
														}}
													/>
													10 Year Financial Data (Top
													3000 companies)
												</li>
												<br /><li>
													<FontAwesome
														className="fa-solid fa-check"
														name="check"
														style={{
															display:
																"inline-block",
															textAlign: "center",
															marginRight: ".5em",
															color: "#007bff",
														}}
													/>
													Trade Simulator
												</li>
												<br />
												<li>
													<FontAwesome
														className="fa-solid fa-check"
														name="check"
														style={{
															display:
																"inline-block",
															textAlign: "center",
															marginRight: ".5em",
															color: "#007bff",
														}}
													/>
													Educational Resources
													(Limited)
												</li>
												<br />
												<br />
												<br />
												<br />
												<br />
												<br />
												<br />
												<br />
												<br />
											</ul>

											{auth.account ? (
												<button
													onClick={() =>
														navigate("/account")
													}
													type="button"
													className="btn btn-lg btn-block btn-outline-primary"
												>
													Sign up for free
												</button>
											) : (
												<button
													onClick={() =>
														navigate(
															"/register", {state: {plan:"B"}}
														)
													}
													type="button"
													className="btn btn-lg btn-block btn-outline-primary"
												>
													Sign up for free
												</button>
											)}
										</div>
									</div>
									<div className="card mb-4 box-shadow">
										<div className="card-header">
											<h4 className="my-0 font-weight-normal">
												Advanced
											</h4>
										</div>
										<div className="card-body">
											<h1 className="card-title pricing-card-title">
												$13.99{" "}
												<small className="text-muted">
													/ mo
												</small>
											</h1>
											<h4>
												<strong>
													Billed Quarterly
												</strong>
											</h4>
											<ul className="list-unstyled mt-3 mb-4">
											<br/>
												<li>
													<FontAwesome
														className="fa-solid fa-check"
														name="check"
														style={{
															display:
																"inline-block",
															textAlign: "center",
															marginRight: ".5em",
															color: "#007bff",
														}}
													/>
													Halal Stock Screener (Top
													3000 US companies)
												</li>
												<br />
												<li>
													<FontAwesome
														className="fa-solid fa-check"
														name="check"
														style={{
															display:
																"inline-block",
															textAlign: "center",
															marginRight: ".5em",
															color: "#007bff",
														}}
													/>
													Historical Top 30 Companies screened
													for value based on our market beating
													valuation formula. (Halal
													List and Standard Stocks
													List)
												</li>
												<br/>
												<li>
													<FontAwesome
														className="fa-solid fa-check"
														name="check"
														style={{
															display:
																"inline-block",
															textAlign: "center",
															marginRight: ".5em",
															color: "#007bff",
														}}
													/>
													10 Year Financial Data (Top
													3000 US companies)
												</li>
												<br />
												<li>
													<FontAwesome
														className="fa-solid fa-check"
														name="check"
														style={{
															display:
																"inline-block",
															textAlign: "center",
															marginRight: ".5em",
															color: "#007bff",
														}}
													/>
													Trade Simulator
												</li>
												<br />
												<li>
													<FontAwesome
														className="fa-solid fa-check"
														name="check"
														style={{
															display:
																"inline-block",
															textAlign: "center",
															marginRight: ".5em",
															color: "#007bff",
														}}
													/>
													Educational Resources
													(complete archives)
												</li>
												<br />
												<br />
												<br />
												<br />
												<br />
											</ul>
											{auth.account ? (
												<button
													onClick={() =>
														navigate("/secure-payment", {state: { plan: "AM", uid: auth.account.id }})
													}
													type="button"
													className="btn btn-lg btn-block btn-primary"
												>
													Sign up
												</button>
											) : (
												<button
													onClick={() =>
														navigate(
															"/register", {state: {plan:"AM"}}
														)
													}
													type="button"
													className="btn btn-lg btn-block btn-primary"
												>
													Sign up
												</button>
											)}
										</div>
									</div>
									<div className="card mb-4 box-shadow">
										<div className="card-header">
											<h4 className="my-0 font-weight-normal">
												Pro
											</h4>
										</div>
										<div className="card-body">
											<h1 className="card-title pricing-card-title">
												$16.99{" "}
												<small className="text-muted">
													/ mo
												</small>
											</h1>
											<h4>
												<strong>
													Billed Quarterly
												</strong>
											</h4>
											<ul className="list-unstyled mt-3 mb-4">
												<br />
												<li>
													<FontAwesome
														className="fa-solid fa-check"
														name="check"
														style={{
															display:
																"inline-block",
															textAlign: "center",
															marginRight: ".5em",
															color: "#007bff",
														}}
													/>
													Halal Stock Screener (Top
													3000 US companies)
												</li>
												<br />
												<li>
													<FontAwesome
														className="fa-solid fa-check"
														name="check"
														style={{
															display:
																"inline-block",
															textAlign: "center",
															marginRight: ".5em",
															color: "#007bff",
														}}
													/>
													Historical Top 30 Companies screened
													for value based on our market beating
													valuation formula.  (Halal
													List and Standard Stocks
													List)
												</li>
												<br />
												<li>
													<FontAwesome
														className="fa-solid fa-check"
														name="check"
														style={{
															display:
																"inline-block",
															textAlign: "center",
															marginRight: ".5em",
															color: "#007bff",
														}}
													/>
													Current Top 30 Companies screened
													for value based on our market beating
													valuation formula. (Halal
													List and Standard Stocks
													List)
												</li>
												<br />
												<li>
													<FontAwesome
														className="fa-solid fa-check"
														name="check"
														style={{
															display:
																"inline-block",
															textAlign: "center",
															marginRight: ".5em",
															color: "#007bff",
														}}
													/>
													10 Year Financial Data (Top
													3000 US companies)
												</li>
												<br />
												<li>
													<FontAwesome
														className="fa-solid fa-check"
														name="check"
														style={{
															display:
																"inline-block",
															textAlign: "center",
															marginRight: ".5em",
															color: "#007bff",
														}}
													/>
													Trade Simulator
												</li>
												<br />
												<li>
													<FontAwesome
														className="fa-solid fa-check"
														name="check"
														style={{
															display:
																"inline-block",
															textAlign: "center",
															marginRight: ".5em",
															color: "#007bff",
														}}
													/>
													Educational Resources
													(complete archives)
												</li>
												<br />
											</ul>
											{auth.account ? (
												<button
													onClick={() =>
														navigate("/secure-payment", {state: { plan: "PM", uid: auth.account.id }})
													}
													type="button"
													className="btn btn-lg btn-block btn-primary"
												>
													Sign up
												</button>
											) : (
												<button
													onClick={() =>
														navigate(
															"/register", {state: {plan:"PM"}}
														)
													}
													type="button"
													className="btn btn-lg btn-block btn-primary"
												>
													Sign up
												</button>
											)}
										</div>
									</div>
								</div>
							)}
						</section>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export default Pricing;
