import React, { Component } from "react";
import { Link } from "react-router-dom";
import transparent_color_full_logo from "../images/transparent_color_full_logo.svg";

class Footer extends Component {
   getYear() {
    return new Date().getFullYear();
}
   render() {
      return (
         <div>
            <footer id="footer">
               <div className="inner">
                  <div className="content">
                  <div className ="footer-content">
                     <div id="footer-div" style={{textAlign:'center'}}>                        
                           <img src={transparent_color_full_logo} />
                        
                        </div>

                        <div id="footer-div">
                        <header>
                           <h2>Useful Links</h2>
                        </header>
                        <ul className="list-unstyled alt">
                           <li>
                              <Link to="/">Home</Link>
                           </li>
                           <li>
                              <Link to="/wealthseed-story">About</Link>
                           </li>
                           <li>
                              <Link to="/learningcenter">Learning Center</Link>
                           </li>
                           <li>
                              <Link to="/pricing">Pricing</Link>
                           </li>
                           <li>
                              <Link to="/companies">Company Data</Link>
                           </li>
                           <li>
                              <Link to="/portfolio">Portfolio</Link>
                           </li>
                           <li>
                              <Link to="/trade-simulator">Trade Simulator</Link>
                           </li>
                           <li>
                              <Link to="/top30">Top 30 List</Link>
                           </li>
                        </ul>
                     </div>

                     <div id="footer-div">
                        <header>
                           <h2>Contact</h2>
                        </header>
                        <ul className="contact-icons">
                           <li className="icon solid fa-envelope">
                                 <a href="mailto:info@halalstockscreener"> info@halalstockscreener.com</a>
                           </li>
                           <li className="icon brands fa-twitter">
                              <a href="https://twitter.com/WealthSeedLLC">@WealthSeedLLC</a>
                           </li>
                           <li className="icon solid fa-map-marker-alt">
                              <address style={{textAlign:"left"}}>
                                 WealthSeed, L.L.C.
                                 <br />
                                 750 N Saint Paul St Ste 250 PMB
                                 <br />
                                 Dallas, Texas 75201-3206
                              </address>
                           </li>
                        </ul>
                     </div>
                     </div>
                  </div>
               </div>

                     
                     <div className="copyright">
                        &copy; {this.getYear()} WealthSeed, L.L.C. All rights reserved.
                     </div>
            </footer>
         </div>
      );
   }
}

export default Footer;

