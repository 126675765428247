import React, { useState, useEffect, useReducer, useRef } from "react";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import Layout from "./layout";
import FontAwesome from "react-fontawesome";
import axiosService from "../utils/axios.ts";
import { config } from './constants';
import TwitterContainer from "./twittercontainer";

function PortfolioData() {


	var REACT_APP_API_URL = config.url.REACT_APP_API_URL
	var REACT_API_IMAGE_URL = config.url.REACT_API_IMAGE_URL

	const [portfolioData, setPortfolioData] = useState([]);
	const [buyData, setBuyData] = useState([]);
	const [sellData, setSellData] = useState([]);
	const [last4ArticleData, setLast4ArticleData] = useState([]);
	const dataFetchedRef = useRef(false);

	var parse = require("html-react-parser");
	var buy_elements = [];
	var sell_elements = [];

	
	function handleDelete(obj_id) {
			// getCompanyData().then((data) => setCompaniesData(data));
			// for some reason the getCompanyData() needs a screen refresh to show correct data; resorting to calling the function in this context
			axiosService
				.post(`${REACT_APP_API_URL}/portfolio/`, {id:obj_id})
				.then((res) => {
					setLast4ArticleData(res.data["last_4_articles"]);
					res.data["portfolio"]?.map((item) => {
						item.delete = (
							<div id={item.id} onClick={(e) => handleDelete(item.id)}>
								<FontAwesome
									className="fa fa-trash-can"
									name="trash"
									style={{
										cursor: "pointer",
										display: "block",
										textAlign: "center",
										color: "#007bff",
									}}
								/>
							</div>
						);
						item["total_value"] = item.total_value.toFixed(2);
						item["current_value"] = (
							item.current_price * item.num_shares
						).toFixed(2);
						item["gains_losses"] = (
							(item.current_price - item.purchase_price) *
							item.num_shares
						).toFixed(2);
						buyData.push(item.trade_history?.buys?.[0]);
						sellData.push(item.trade_history?.sells?.[0]);
					});
					setPortfolioData(res.data.portfolio);

				});

}


	useEffect(() => {
			// Avoid hitting API twice
		    if (dataFetchedRef.current) return;
		    dataFetchedRef.current = true;
			// getCompanyData().then((data) => setCompaniesData(data));
			// for some reason the getCompanyData() needs a screen refresh to show correct data; resorting to calling the function in this context
			axiosService
				.get(`${REACT_APP_API_URL}/portfolio/`)
				.then((res) => {
					// console.log(res.data.portfolio);
					setLast4ArticleData(res.data["last_4_articles"]);
					res.data["portfolio"].map((item) => {
						item.delete = (
							<div onClick={(e) => handleDelete(item.id)}>
								<FontAwesome
									className="fa fa-trash-can"
									name="trash"
									style={{
										cursor: "pointer",
										display: "block",
										textAlign: "center",
										color: "#007bff",
									}}
								/>
							</div>
						);
						item["total_value"] = item.total_value.toFixed(2);
						item["current_value"] = (
							item.current_price * item.num_shares
						).toFixed(2);
						item["gains_losses"] = (
							(item.current_price - item.purchase_price) *
							item.num_shares
						).toFixed(2);
						item["gains_losses(%)"] = (
							((item.current_price/item.purchase_price)  - 1)*100
						).toFixed(2);
						buyData.push(item.trade_history?.buys?.[0]);
						sellData.push(item.trade_history?.sells?.[0]);
					});
					setPortfolioData(res.data.portfolio);
				});
			// setBuyData(buy_elements);
			// 	setSellData(sell_elements);
			// console.log(buyData);
			// console.log(sellData);
		// } else {
		// 	getData = "x";
		// }
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const portfolio_data = {
		columns: [
			{
				label: "Ticker",
				field: "ticker",
				width: 150,
			},
			{
				label: "Number of Shares",
				field: "num_shares",
				width: 150,
			},
			{
				label: "Purchase Price/Share (Avg)",
				field: "purchase_price",
				width: 150,
			},
			{
				label: "Total Purchase Price",
				field: "total_value",
				width: 150,
			},
			{
				label: "Current Price",
				field: "current_price",
				width: 150,
			},
			{
				label: "Current Value",
				field: "current_value",
				width: 150,
			},
			{
				label: "Gains/Losses",
				field: "gains_losses",
				width: 150,
			},
			{
				label: "Gains/Losses(%)",
				field: "gains_losses(%)",
				width: 150,
			},
			{
				label: "Delete",
				field: "delete",
				width: 150,
			},
		],
		rows: portfolioData,
	};

	const buy_data = {
		columns: [
			{
				label: "Ticker",
				field: "ticker",
				width: 150,
			},
			{
				label: "Purchase Date",
				field: "buy_date",
				width: 150,
			},
			{
				label: "Number of Shares",
				field: "num_shares",
				width: 150,
			},
			{
				label: "Purchase Price",
				field: "buy_price",
				width: 150,
			},
			{
				label: "Total Value",
				field: "total",
				width: 150,
			},
		],
		rows: buyData,
	};

	const sell_data = {
		columns: [
			{
				label: "Ticker",
				field: "ticker",
				width: 150,
			},
			{
				label: "Sell Date",
				field: "sell_date",
				width: 150,
			},
			{
				label: "Number of Shares",
				field: "num_shares",
				width: 150,
			},
			{
				label: "Purchase Price",
				field: "buy_price",
				width: 150,
			},
			{
				label: "Sell Price",
				field: "sell_price",
				width: 150,
			},
			{
				label: "Gains/Loss",
				field: "gains/loss",
				width: 150,
			},
			{
				label: "Gains/Loss (%)",
				field: "gains/loss (%)",
				width: 150,
			},
		],
		rows: sellData,
	};

	/* =================================== toggle tabs on click =================================== */

	const values = [
		{ id: "tab1", text: "Current Holdings" },
		{ id: "tab2", text: "Buys" },
		{ id: "tab3", text: "Sells" },
	];
	const [activeId, setActiveId] = useState("tab1");

	return (
		<Layout>
			<div id="main">
				<div className="inner">
					<div id="content">
						<section className="main">
							<header>
								<h1>Current Portfolio</h1>
							</header>
							<div className="col-md-14">
								<div className="tab-block">
									<ul className="nav nav-tabs">
										{values?.map((val) => (
											<li
												key={val.id}
												onClick={() =>
													setActiveId(val.id)
												}
												className={
													activeId === val.id
														? "nav-item active"
														: "nav-item"
												}
											>
												<a
													className="nav-link"
													href="#{val.id}"
												>
													{val.text}
												</a>
											</li>
										))}
									</ul>
									<div className="tab-content p30">
										<div
											className={
												activeId === "tab1"
													? "tab-pane active"
													: "tab-pane"
											}
										>
											<section className="main">
												<br />
												<header>
													<h2>Current Holdings</h2>
												</header>
												<div className="table-wrapper">
													<MDBDataTable
														bordered
														small
														data={portfolio_data}
													/>
												</div>
												<div onClick={(e) => handleDelete("all")} className="button">
													Clear Portfolio
												</div>
											</section>
										</div>
										<div
											className={
												activeId === "tab2"
													? "tab-pane active"
													: "tab-pane"
											}
										>
											<section className="main">
												<br />
												<header>
													<h2>Buys</h2>
												</header>
												<div className="table-wrapper">
													<MDBDataTable
														bordered
														small
														data={buy_data}
													/>
												</div>
											</section>
										</div>
										<div
											className={
												activeId === "tab3"
													? "tab-pane active"
													: "tab-pane"
											}
										>
											<section className="main">
												<br />
												<header>
													<h2>Sells</h2>
												</header>

												<div className="table-wrapper">
													<MDBDataTable
														bordered
														small
														data={sell_data}
													/>
												</div>
											</section>
										</div>
									</div>
								</div>
							</div>
						</section>
						<br></br>
						<br></br>
					</div>

					<div id="sidebar">
						<section className="features">
							<Link to="/companies" className="accent1">
								<h2>Company Data</h2>
								<p>
									Explore data and charts of company
									fundamentals.
								</p>
							</Link>
							<Link to="/learningcenter" className="accent2">
								<h2>Learning Center</h2>
								<p>
									Enhance your understanding of halal and
									value investing.
								</p>
							</Link>
							<Link to="/portfolio" className="accent3">
								<h2>Virtual Portfolio</h2>
								<p>
									Track your virtual trades and test your
									investing strategy.
								</p>
							</Link>
						</section>

						<section className="main">
							<header>
								<h2>Latest Articles</h2>
							</header>
							<section className="mini-posts small">
								{Object.keys(last4ArticleData).map((key) => {
									return (
										<article key={key}>
											<div className="image">
												<Link
													to={`/article?title=${last4ArticleData[key].slug}`}
												>
													<img
														src={`${REACT_API_IMAGE_URL}${last4ArticleData[key].thumbnail_image}`}
														alt=""
														style={{
															width: "105px",
															height: "105px",
														}}
													/>
												</Link>
											</div>
											<div className="content">
												<Link
													to={`/article?title=${last4ArticleData[key].slug}`}
												>
													{parse(
														last4ArticleData[key]
															.teaser_text
													)}
												</Link>
												<div className="timestamp"></div>
											</div>
										</article>
									);
								})}

								<ul className="actions fixed">
									<li>
										<Link
											to="/article-list"
											className="button"
										>
											More Posts
										</Link>
									</li>
								</ul>
							</section>
						</section>

			           	<section className="mini-posts minimal main" style={{'marginTop':'60px', 'textAlign':'center'}}>
			                <header>
			                  <h2>Tweets</h2>
			                  <h4>@WealthSeedLLC</h4>
			                </header>
			                <article style={{'height':'900px', 'overflowY':'auto'}}>
			                <TwitterContainer/>
			                </article>
			            </section>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export default PortfolioData;
