import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import YoutubeEmbed from "./YoutubeEmbed";

import Layout from "./layout";
import axiosService from "../utils/axios.ts";
import { config } from './constants';
import TwitterContainer from "./twittercontainer";


function LearningCenter(){
	var REACT_APP_API_URL = config.url.REACT_APP_API_URL
	var REACT_API_IMAGE_URL = config.url.REACT_API_IMAGE_URL

	const [articleData, setArticleData] = useState([]);
	const [last4ArticleData, setLast4ArticleData] = useState([]);
	const [videoData, setVideoData] = useState([]);
  	var parse = require('html-react-parser');
 	const dataFetchedRef = useRef(false);


 	useEffect(() => {
	
	// Avoid hitting API twice
	if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
 			
	axiosService.get(`${REACT_APP_API_URL}/learning-page-media/`, {withCredentials: true})
		.then((res) => {
			setArticleData(res.data['articles']);
			setLast4ArticleData(res.data['last_4_articles']);
			setVideoData(res.data['video_blogs']);
		});
	}, []);

	useEffect(() =>{
		window.scrollTo(0, 0);
	},[]);

	return (
			<Layout>
				<div id="main">
					<div className="inner">
						<div id="content">
							<section className="main">
								<header>
									<h1>Halal Investing Learning Center</h1>
									<p>
										Halal investing is based on the principles of Islamic finance.
									</p>
								</header>
								<p>
									The most important trait for successful investors is patience.  When an investor understands
									their investment, they will be able to sit through the inevitable times when the market is down.  By following simple priciples, any investor can be successful.
								</p>
								<section className="posts">
								{Object.keys(articleData).map((key) => {
         							return (
									<article key={key}>
									<div className="image fit">
											<Link to={`/article?title=${articleData[key].slug}`}>
												<img src={`${REACT_API_IMAGE_URL}${articleData[key].thumbnail_image}`} alt="" />
											</Link>
										</div>
										<h2>
											{articleData[key].title}
										</h2>
										{parse(articleData[key].teaser_text)}
										
										<ul className="actions fixed">
											<li>
												<Link to={`/article?title=${articleData[key].slug}`} className="button">
													Learn More
												</Link>
											</li>
										</ul>

									</article>
									)})}
									<div className="bottom-post" style={{width:'100%', textAlign:'center', fontSize:'large'}}>
									<div className="u-active-white u-align-left u-border-none u-btn u-btn-round u-button-style u-hover-feature u-hover-white u-palette-2-base u-radius-15 u-text-active-palette-3-base u-text-hover-palette-3-base u-btn-1">
								
											<Link to="/article-list">
												More Articles ...
											</Link>
										
									</div>
									</div>
								</section>
							</section>

							<section className="main">
								<header>
									<h2>Videos</h2>
								</header>
								<section className="posts">
								{Object.keys(videoData).map((key) => {
         							return (
									<article key={key} style={{borderBottom:"1px solid", borderColor:'#e0e0e0'}}>
											<YoutubeEmbed embedId={videoData[key].embed_id} />
										
										<div className="content">
											<h3>{videoData[key].title}</h3>
											{parse(videoData[key].teaser_text)}
											
										</div>
									</article>
									)})}
								<div className="bottom-post" style={{width:'100%', textAlign:'center', fontSize:'large'}}>
									<div className="u-active-white u-align-left u-border-none u-btn u-btn-round u-button-style u-hover-feature u-hover-white u-palette-2-base u-radius-15 u-text-active-palette-3-base u-text-hover-palette-3-base u-btn-1">
								
											<Link to="/video-list">
												More Videos ...
											</Link>
										
									</div>
									</div>
								</section>
							</section>
						</div>

						<div id="sidebar">
							<section className="features">
								<Link to="/companies" className="accent1">
									<h2>Company Data</h2>
									<p>
										Explore data and charts of company
										fundamentals.
									</p>
								</Link>
								<Link to="/learningcenter" className="accent2">
									<h2>Learning Center</h2>
									<p>
										Enhance your understanding of halal and
										value investing.
									</p>
								</Link>
								<Link to="/portfolio" className="accent3">
									<h2>Virtual Portfolio</h2>
									<p>
										Track your virtual trades and test your
										investing strategy.
									</p>
								</Link>
							</section>

							<section className="main">
								<header>
									<h2>Latest Articles</h2>
								</header>
								<section className="mini-posts small">
								{Object.keys(last4ArticleData).map((key) => {
         							return (
									<article key={key}>
									<div className="image">
											<Link to={`/article?title=${last4ArticleData[key].slug}`} >
												<img src={`${REACT_API_IMAGE_URL}${last4ArticleData[key].thumbnail_image}`} alt="" style={{width:'105px', height:'105px'}}/>
											</Link>
										</div>
										<div className="content">
											<Link to={`/article?title=${last4ArticleData[key].slug}`} >
												{parse(last4ArticleData[key].teaser_text)}
											</Link>
											<div className="timestamp">
											</div>
										</div>

									</article>
									)})}
									
									
									<ul className="actions fixed">
										<li>
											<Link to="/article-list" className="button">
												More Posts
											</Link>
										</li>
									</ul>
								</section>
							</section>

			           	<section className="mini-posts minimal main" style={{'marginTop':'60px', 'textAlign':'center'}}>
			                <header>
			                  <h2>Tweets</h2>
			                  <h4>@WealthSeedLLC</h4>
			                </header>
			                <article style={{'height':'900px', 'overflowY':'auto'}}>
			                <TwitterContainer/>
			                </article>
			            </section>
						</div>
					</div>
				</div>
			</Layout>
		);
}

export default LearningCenter;
