import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AccountResponse } from "../types.ts";
import { useNavigate, useLocation } from "react-router";
import authSlice from "../store/slices/auth.ts";
import useSWR from "swr";
import { fetcher } from "../utils/axios.ts";
import Layout from "./layout";

import { RootState } from "../store/index.ts";

interface LocationState {
  userId: string;
}

function renderSwitch(param) {
  switch (param) {
    case "PM":
      return "Pro (Billed Qarterly)";
    case "PY":
      return "Pro (Billed Annually)";
    case "AM":
      return "Advanced (Billed Quarterly)";
    case "AY":
      return "Advanced (Billed Annually)";
    default:
      return "Basic (Free)";
  }
}

const Account = () => {
  const account = useSelector((state: RootState) => state.auth.account);
  const member = account.member;
  const auth = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  window.scrollTo(0, 0);

  const handleLogout = () => {
    dispatch(authSlice.actions.logout());
    navigate("/login");
  };
  return (
    <Layout>
      <div id="main">
        <div className="inner">
          <div id="content">
            <section className="main">
              <header>
                <h1>Account Details</h1>
                <p className="self-center my-auto">
                  Welcome, {auth.account.first_name}
                </p>
              </header>

              {auth.account ? (
                <>
                  <div className="panel">
                    <div className="panel-body bio-graph-info">
                      <div className="row">
                        <div className="bio-row">
                          <p>First Name: {account.first_name}</p>
                        </div>
                        <div className="bio-row">
                          <p>Last Name: {account.last_name}</p>
                        </div>
                        <div className="bio-row">
                          <p>Email: {account.email}</p>
                        </div>
                        <div className="bio-row">
                          <p>
                            Membership Level:{" "}
                            {renderSwitch(member.membership_plan)}
                          </p>
                        </div>
                        <div className="bio-row">
                          <p>
                            Current membership start date:{" "}
                            {member.current_membership_start_date}
                          </p>
                        </div>
                        <div className="bio-row">
                          <p>
                            Membership ends: {member.current_payment_due_date}
                          </p>
                        </div>
                        <div className="bio-row">
                          <p>
                            Renew Membership:{" "}
                            {member.membership_plan === "B" ? (
                              "N/A"
                            ) : (
                              <a href="/membership-renewal">Renew Now</a>
                            )}
                          </p>
                        </div>
                        <div className="bio-row">
                          <p>
                            Password:{" "}
                            <a href="/request-password-reset">Reset Password</a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <p className="text-center items-center">Loading ...</p>
              )}
            </section>
            <section className="main">
              <button
                onClick={handleLogout}
                className="btn btn-primary fa-lg gradient-custom-3 mb-3"
              >
                Logout
              </button>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Account;
