import React, { useEffect } from "react";
import { Link} from "react-router-dom";

import YoutubeEmbed from "./YoutubeEmbed";
import "animate.css/animate.min.css";
import TwitterContainer from "./twittercontainer";

import Layout from "./layout";

function ScreenerMethodology() {


	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
    
    
	return (
		<Layout>
			<div id="main">
				<div className="inner">
					<div id="content">
						<section className="main">
						<header>	
							<h1>How We Screen Stocks for Halal Status:</h1>
							<p>At WealthSeed, we use a two step process when screening stocks.</p>
						</header>
                        <div className="row">
							<div>
								<h4><strong>1. We screen the company’s main business activity to ensure that operations are halal.</strong></h4>
								<ul>
									<li>We screen out companies that are involved in the sale of alcohol, tobacco, pork, pornography, gambling, media and entertainment, financial services, and advertising.</li>
                                    <li>We screen out traditional banks, traditional loan companies, insurance companies, traditional investment funds, and real estate investment trusts because they either deal in interest or may be invested in companies whose business is charging interest.</li>
                                </ul>
							</div>
						</div>
						<div className="row">
							<div>
								<h4><strong>2. Next, we screen the company’s financial activity using the <a href="https://aaoifi.com/shariaa-standards/?lang=en" target="blank" style={{'textDecoration':'underline'}}>AAOIFI’s</a> shariah compliant screening methodology.</strong></h4>
								<ul>
									<li>Total interest bearing debt to market cap ratio does not exceed 30%. (Trailing 12 months average)</li>
									<li>Total interest earning deposits to market cap ratio does not exceed 30%. (Trailing 12 months average)</li>
									<li>Total income from interest to total income should not exceed 5%. (Trailing 12 months average)</li>
                                </ul>
							</div>
						</div>
						<header>	
							<h1>What Makes a Stock Halal?</h1>
						</header>
						<div style={{'marginTop':'40px'}}>
							<YoutubeEmbed embedId="FPvuPh-8-Fo" />
						</div>

						</section>
					</div>

						<div id="sidebar">
							<section className="features">
								<Link to="/companies" className="accent1">
									<h2>Company Data</h2>
									<p>
										Explore data and charts of company
										fundamentals.
									</p>
								</Link>
								<Link to="/learningcenter" className="accent2">
									<h2>Learning Center</h2>
									<p>
										Enhance your understanding of halal and
										value investing.
									</p>
								</Link>
								<Link to="/portfolio" className="accent3">
									<h2>Virtual Portfolio</h2>
									<p>
										Track your virtual trades and test your
										investing strategy.
									</p>
								</Link>
							</section>

							
			           		<section className="mini-posts minimal main" style={{'marginTop':'60px', 'textAlign':'center'}}>
			                	<header>
				                  	<h2>Tweets</h2>
				                  	<h4>@WealthSeedLLC</h4>
				                </header>
				                <article style={{'height':'900px', 'overflowY':'auto'}}>
				                	<TwitterContainer/>
				                </article>
			            	</section>

						</div>
				</div>
			</div>
		</Layout>
	);
}

export default ScreenerMethodology;
