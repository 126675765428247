import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Layout from "./layout";
import { useSelector } from "react-redux";
import { RootState } from "../store/index.ts";
// import getCompanyData  from "./getCompanyData_";
import axiosService from "../utils/axios.ts";
import { config } from './constants';
import TwitterContainer from "./twittercontainer";
import FontAwesome from "react-fontawesome";



function Top30ListData() {

	
	var REACT_API_IMAGE_URL = config.url.REACT_API_IMAGE_URL
	const [top30Data, setTop30Data] = useState([]);
	const [activeId, setActiveId] = useState();
	const [last4ArticleData, setLast4ArticleData] = useState([]);
  	const dataFetchedRef = useRef(false);

	
  
	var parse = require('html-react-parser');

	const auth = useSelector((state: RootState) => state.auth);
	useEffect(() => {
		var REACT_APP_API_URL = config.url.REACT_APP_API_URL
		// Avoid hitting API twice
	  	if (dataFetchedRef.current) return;
	    dataFetchedRef.current = true;
		axiosService
			.get(`${REACT_APP_API_URL}/top30/`)
			.then((res) => {
				setLast4ArticleData(res.data['last_4_articles']);
				const groupByDate = res.data.data.reduce((group, product) => {
					(group[product.year] = group[product.year] || []).push(product);
					return group;
				}, []);
				setTop30Data(groupByDate.reverse());
				setActiveId(groupByDate[0][0].year);
			});
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
					
		},[]);


	return (
		<Layout>
			<div id="main">
					<div className="inner">
						<div id="content">
							<header>
								<h1>Historical Top 30 Stocks</h1>
							</header>
						{auth.account.member.membership_plan === "B" ? (<h4>Please upgrade to Advanced or Pro membership level to access this feature</h4>) : (
							<section className="main">
							<div className="col-md-14">
						<div className="tab-block">
							<ul className="nav nav-tabs">
						        {Object.keys(top30Data).map((key, i) => (
						        	
						          <li key={key} onClick={() => setActiveId(top30Data[key][0].year)} className={activeId === top30Data[key][0].year ? "nav-item active" : "nav-item"}>
						            <a className="nav-link" href="#">{top30Data[key][0].year}</a>
						          </li>
										))}
							</ul>
							<div className="tab-content p30">
							{Object.keys(top30Data).map((key, i) => (
								<div key={top30Data[key][0].year} className={activeId === top30Data[key][0].year ? "tab-pane active" : "tab-pane"}>
									{top30Data[key].map((item) => (
									<section key={item.year + item.stocks_type}>
												<h4>Year: {item.year}</h4>
												<h5>Stocks Type: {item.stocks_type}</h5>
												<h5>Gains/Loss: {item.gains_loss}</h5>
												<h5>S&P 500 Gains/Loss: {item.sp500_gains_loss}</h5>
												<br></br>
												<div className="table-wrapper">
													<table className="alt">
														<thead>
															<tr>
																<th>Ranking</th>
																<th>Ticker</th>
																<th>Company Name</th>
																<th>Details</th>
															</tr>
														</thead>
														<tbody>
														{Object.keys(item.tickers).map((key, i) => (
													<tr key={key}>
														<td>{key}</td>
														<td>{item.tickers[key]}</td>
														<td>{item.names[key]}</td>
														<td>
															<Link to="/fundamentals" state={item.tickers[key]}>
																<FontAwesome
																	className="fa fa-info-circle"
																	name="check"
																	style={{
																		display: "block",
																		textAlign: "center",
																		color: "#007bff",
																	}}
																/>
															</Link>
															</td>
													</tr>
												))}
														</tbody>
													</table>
												</div>
											</section>
										))}	
								</div>
								
))} 
					</div>			
					</div>
								</div>
						</section>
							)}

						</div>

					<div id="sidebar">
						<section className="features">
							<Link to="/companies" className="accent1">
								<h2>Company Data</h2>
								<p>
									Explore data and charts of company
									fundamentals.
								</p>
							</Link>
							<Link to="/learningcenter" className="accent2">
								<h2>Learning Center</h2>
								<p>
									Enhance your understanding of halal and
									value investing.
								</p>
							</Link>
							<Link to="/portfolio" className="accent3">
								<h2>Virtual Portfolio</h2>
								<p>
									Track your virtual trades and test your
									investing strategy.
								</p>
							</Link>
						</section>

						<section className="main">
							<header>
								<h2>Latest Articles</h2>
							</header>
							<section className="mini-posts small">
								{Object.keys(last4ArticleData).map((key) => {
									return (
										<article key={key}>
											<div className="image">
												<Link
													to={`/article?title=${last4ArticleData[key].slug}`}
												>
													<img
														src={`${REACT_API_IMAGE_URL}${last4ArticleData[key].thumbnail_image}`}
														alt=""
														style={{
															width: "105px",
															height: "105px",
														}}
													/>
												</Link>
											</div>
											<div className="content">
												<Link
													to={`/article?title=${last4ArticleData[key].slug}`}
												>
													{parse(
														last4ArticleData[key]
															.teaser_text
													)}
												</Link>
												<div className="timestamp"></div>
											</div>
										</article>
									);
								})}

								<ul className="actions fixed">
									<li>
										<Link
											to="/article-list"
											className="button"
										>
											More Posts
										</Link>
									</li>
								</ul>
							</section>
						</section>

			           	<section className="mini-posts minimal main" style={{'marginTop':'60px', 'textAlign':'center'}}>
			                <header>
			                  <h2>Tweets</h2>
			                  <h4>@WealthSeedLLC</h4>
			                </header>
			                <article style={{'height':'900px', 'overflowY':'auto'}}>
			                <TwitterContainer/>
			                </article>
			            </section>
					</div>




				</div>
			</div>
		</Layout>
	);
}

export default Top30ListData;

