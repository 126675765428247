import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { useNavigate } from "react-router";
// import { createStore, combineReducers } from "react-redux";
import { useDispatch } from "react-redux";
import Layout from "../screener/layout";
import authSlice from "../store/slices/auth.ts";
import transparent_color_full_logo from "../images/transparent_color_full_logo.svg";
import { config } from '../screener/constants'




function Login() {

  var REACT_APP_API_URL = config.url.REACT_APP_API_URL



  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  const handleLogin = (email: string, password: string) => {
    axios
      .post(
        `${REACT_APP_API_URL}/auth/login/`,
        { email, password },
        { withCredentials: true }
      )
      .then((res) => {
        dispatch(
          authSlice.actions.setAuthTokens({
            token: res.data.access,
          })
        );
        dispatch(authSlice.actions.setAccount(res.data.user));
        dispatch(authSlice.actions.setTickers(res.data.tickers));
        setLoading(false);
        navigate("/");
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            setMessage("A user with that email and password does not exist.");
            setLoading(false);
          }
        }
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      setLoading(true);
      handleLogin(values.email, values.password);
    },
    validationSchema: Yup.object({
      email: Yup.string().email().trim().required("Email is required"),
      password: Yup.string().trim().required("Password is required"),
    }),
  });

  return (
    <Layout>
      <div id="main">
        <div className="inner">
          <div id="content">
            <div className="col-xl-10">
              <div className="card rounded-3 text-black">
                <div className="row g-0">
                  <div className="col-lg-6">
                    <div className="card-body p-md-5 mx-md-4">
                      <div className="text-center">
                        <img
                          src={transparent_color_full_logo}
                          style={{ width: "80%" }}
                          alt="logo"
                        />
                        <br />
                        <br />
                      </div>

                      <form method="post" onSubmit={formik.handleSubmit}>
                        <p>Please login to your account</p>

                        <div className="form-outline mb-4">
                          <input
                            className="border-b border-gray-300 w-full px-2 h-8 rounded focus:border-blue-500"
                            id="email"
                            type="email"
                            placeholder="Email"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.errors.email ? (
                            <div>{formik.errors.email} </div>
                          ) : null}
                        </div>

                        <div className="form-outline mb-4">
                          <input
                            className="border-b border-gray-300 w-full px-2 h-8 rounded focus:border-blue-500"
                            id="password"
                            type="password"
                            placeholder="Password"
                            name="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.errors.password ? (
                            <div>{formik.errors.password} </div>
                          ) : null}
                        </div>
                        <div className="flex items-center justify-between">
                          <div
                            className="text-danger text-center my-2"
                            hidden={false}
                          >
                            {message}
                          </div>
                        </div>
                        <div className="text-center pt-1 mb-5 pb-1">
                          <button
                            type="submit"
                            disabled={loading}
                            className="btn btn-primary btn-block fa-lg gradient-custom-3 mb-3"
                          >
                            Login
                          </button>
                          <a
                            className="text-muted"
                            href="/request-password-reset"
                          >
                            Forgot password?
                          </a>
                        </div>

                        <div className="d-flex align-items-center justify-content-center pb-4">
                          <p className="mb-0 me-2">Don't have an account?</p>
                          <button
                            type="button"
                            className="btn btn-outline-danger"
                            onClick={() => navigate("/pricing")}
                          >
                            Register
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-lg-6 d-flex align-items-center gradient-custom-2">
                    <div className="text-white px-3 py-4 p-md-5 mx-md-4">
                      <h4 className="mb-4">
                        Where halal investing meets value investing
                      </h4>
                      <p className="small mb-0">
                        Investing in stocks is a way for Muslim families to prepare for life essentials such as educating children, home ownership, haaj, or retirement.  The historical growth of the stock market provides a risk-controlled method to build and protect wealth.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Login;
