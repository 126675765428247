import React, { Suspense, lazy } from "react";
const CompanyListData = lazy(() => import("./companydata"));




export default function CompanyListMain() {
	return (
		<Suspense fallback={"loading..."}>
			<CompanyListData />
		</Suspense>
	);
}
