import React, { Fragment } from "react";
import { BrowserRouter, Routes, Route} from "react-router-dom";
import store, { persistor } from "./store/index.ts";
import authSlice from "./store/slices/auth.ts";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import ProtectedRoute from "./routes/ProtectedRoute";
import { useState } from 'react';

import Home from "./screener/home";
import LearningCenter from "./screener/learningcenter";
import ArticleList from "./screener/articlelist";
import Article from "./screener/article";
import HomeArticle from "./screener/homearticle";
import WealthSeedStory from "./screener/wealthseedstory";
import VideoList from "./screener/videolist";
import CompanyListMain from "./screener/screener";
import CompanyFundamentals from "./screener/companyfundamentals";
import ScreenerMethodology from "./screener/screenermethodology";
import Top30ListData from "./screener/top30";
import CurrentTop30Data from "./screener/currenttop30";
import Top30Calculator from "./screener/top30Calculator";
import Login from "./auth/login";
import Logout from "./auth/logout";
import Register from "./auth/registration";
import TermsAndConditions from "./screener/termsAndConditions";
import CookiePolicy from "./screener/cookiepolicy";
import Account from './screener/account';
import Pricing from './screener/pricing';
import MembershipBenefits from './screener/membershipBenefits';
import SecurePayment from './screener/securepayment';
import PaymentSuccess from './screener/paymentsuccess';
import MembershipRenewal from './screener/membershipRenewal';
import TradeSimulator from './screener/tradesimulator';
import PortfolioData from './screener/portfolio';
import ResetPassword from './screener/resetpassword';
import ForgotPassword from './screener/forgotpassword';
import "./App.css";

function App() {

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
      <BrowserRouter>
        <Routes>
          <Fragment>
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/logout" element={<Logout />} />
            <Route exact path="/" element={<Home />} />
            <Route exact path="/main-article" element={<HomeArticle />} />
            <Route exact path="/wealthseed-story" element={<WealthSeedStory />} />
            <Route exact path="/screener-methodology" element={<ScreenerMethodology />} />
            <Route exact path="/pricing" element={<Pricing />} />
            <Route exact path="/membership-benefits" element={<MembershipBenefits />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route exact path="/cookie-policy" element={<CookiePolicy />} />
            <Route exact path="/secure-payment" element={<SecurePayment />} />
            <Route exact path="/payment-success" element={<PaymentSuccess />} />
            <Route exact path="/request-password-reset" element={<ForgotPassword />} />
            <Route exact path="/reset-password" element={<ResetPassword />} />
            <Route exact path="/membership-renewal" element={<MembershipRenewal />} />
            
            <Route exact path="/learningcenter" element={<ProtectedRoute><LearningCenter /></ProtectedRoute>} />
            <Route exact path="/article-list" element={<ProtectedRoute><ArticleList /></ProtectedRoute>} />
            <Route exact path="/video-list" element={<ProtectedRoute><VideoList /></ProtectedRoute>} />
            <Route exact path="/article" element={<ProtectedRoute><Article /></ProtectedRoute>} />
            <Route exact path="/trade-simulator" element={<ProtectedRoute><TradeSimulator /></ProtectedRoute>} />
            <Route exact path="/portfolio" element={<ProtectedRoute><PortfolioData /></ProtectedRoute>} />
            <Route exact path='/companies' element={<ProtectedRoute><CompanyListMain/></ProtectedRoute>} />
            <Route exact path='/account' element={<ProtectedRoute><Account/></ProtectedRoute>} />
            <Route path="/fundamentals" element={<ProtectedRoute><CompanyFundamentals /></ProtectedRoute>} />
            <Route exact path="/top30" element={<ProtectedRoute><Top30ListData /></ProtectedRoute>} />
            <Route exact path="/top30-calculator" element={<ProtectedRoute><Top30Calculator /></ProtectedRoute>} />
            <Route exact path="/current-top30" element={<ProtectedRoute><CurrentTop30Data /></ProtectedRoute>} />
          </Fragment>
        </Routes>
      </BrowserRouter>
            </PersistGate>
    </Provider>
  );
}

export default App;
