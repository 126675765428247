import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import store from '../store/index.ts';
import authSlice from '../store/slices/auth.ts';
import Cookies from 'js-cookie';
import { config } from '../screener/constants'


var REACT_APP_API_URL = config.url.REACT_APP_API_URL

axios.defaults.withCredentials = true;

const axiosService = axios.create({
    baseURL: REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken'),
    },
});

axiosService.interceptors.request.use(async (config) => {

    const { token } = store.getState().auth;

    if (token !== null) {
        config.headers.Authorization = 'Bearer ' + token;
        // @ts-ignore
        // console.debug('[Request]', config.baseURL + config.url, JSON.stringify(token));
    }
    return config;
});

axiosService.interceptors.response.use(
    (res) => {
        // @ts-ignore      
        // console.debug('[Response]', res.config.baseURL + res.config.url, res.status, res.data);
        return Promise.resolve(res);
    },
    (err) => {

        console.debug(
            '[Response]',
            err.config.baseURL + err.config.url,
            err.response.status,
            err.response.data
        );
        if(err.response.data.detail === "Token contained no recognizable user identification"){
            store.dispatch(authSlice.actions.logout());
            
        }
        return Promise.reject(err);
    }
);

// @ts-ignore
const refreshAuthLogic = async (failedRequest) => {
    const access  = store.getState().auth.token;
    if (access !== null) {
        return axios
            .post(`${REACT_APP_API_URL}/auth/refresh/`)
            .then((resp) => {
                const { access} = resp.data;
                failedRequest.response.config.headers.Authorization = 'Bearer ' + access;
                store.dispatch(
                    authSlice.actions.setAuthTokens({ token: access })
                );
            })
            .catch((err) => {
                if (err.response && err.response.status === 500) {
                    store.dispatch(authSlice.actions.logout());
                }
            });
    }
};

createAuthRefreshInterceptor(axiosService, refreshAuthLogic);

export function fetcher<T = any>(url: string) {
    return axiosService.get<T>(url).then((res) => res.data);
}

export default axiosService;