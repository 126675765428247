
import memoize from "lodash.memoize";
import axiosService from "../utils/axios.ts";
import { config } from './constants'



const getArticleData = (slug) =>
  new Promise((resolve) => {
    var REACT_APP_API_URL = config.url.REACT_APP_API_URL;
     axiosService.post(
        `${REACT_APP_API_URL}/article-detail/`,
        {
          slug: slug,
        }      )
     .then((res) => {
       resolve(res.data);
     });
  });

export default memoize(getArticleData);

