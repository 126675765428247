import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Layout from "./layout";
import { useSelector } from "react-redux";
import { RootState } from "../store/index.ts";
// import getCompanyData  from "./getCompanyData_";
import axiosService from "../utils/axios.ts";
import { config } from './constants';
import TwitterContainer from "./twittercontainer";
import FontAwesome from "react-fontawesome";
import { Form } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Typeahead } from "react-bootstrap-typeahead";

export default function Top30Calculator() {

  var REACT_APP_API_URL = config.url.REACT_APP_API_URL;
  var REACT_API_IMAGE_URL = config.url.REACT_API_IMAGE_URL;
  const [top30Data, setTop30Data] = useState([]);
  const [calculatedData, setCalculatedData] = useState([]);
  const [activeId, setActiveId] = useState();
  const [last4ArticleData, setLast4ArticleData] = useState([]);
  const [tradeMessage, setTradeMessage] = useState("");
  const [loading2, setLoading2] = useState(false);
  const [tradeSingleSelections, setTradeSingleSelections] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedState, setCheckedState] = useState([]);
  const dataFetchedRef = useRef(false);
  var parse = require('html-react-parser');
  const [purchaseList, setPurchaseList] = useState([]);

  const auth = useSelector((state: RootState) => state.auth);
  useEffect(() => {
// Avoid hitting API twice
  if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    
  axiosService
    .get(`${REACT_APP_API_URL}/top30-calculator/`)
    .then((res) => {
      setLast4ArticleData(res.data['last_4_articles']);
      setTop30Data(res.data.data);
      var num_keys = Object.keys(res.data.data[0].tickers).length;
      setCheckedState(new Array(num_keys).fill(false));
    });
  }, []);



 useEffect(() => {
    window.scrollTo(0, 0);
          
    },[]);





const handleAllChecked = (event) => {
  setCheckAll(!checkAll);
  const isChecked_ = !checkAll;  

  if (isChecked_) {
    checkedState.fill(true);
  const purchase_list = checkedState.map((item, index) =>
    ([...[], top30Data[0].tickers[index+1]])
    );

 
  setPurchaseList(Object.keys(purchase_list).map((key)=> purchase_list[key][0]));

}
  else{

    checkedState.fill(false);
    setPurchaseList([]);
  }

};
  const handleOnChange = (event, position) => {
    const value = event.target.value;
    const isChecked = event.target.checked;
 const updatedCheckedState = checkedState.map((item, index) =>
    index === position ? !item : item
  );

  setCheckedState(updatedCheckedState);

    if (isChecked) {
      //Add checked item into checkList
      setPurchaseList([...purchaseList, value]);
    } else {
      //Remove unchecked item from checkList
      const filteredList = purchaseList.filter((item) => item !== value);
      setPurchaseList(filteredList);
    }
      };

  

  // Make a Calculate function and form validation ===================================================================================

  const handleCalculate = (values) => {
    var amount_to_spend = values.amount_to_spend;
    var ticker_list = purchaseList;

    axiosService
      .post(`${REACT_APP_API_URL}/top30-calculator/`, {
        amount_to_spend,
        ticker_list,
      })
      .then((res) => {
        console.log(res.data);
        setCalculatedData(res.data['shares_to_buy']);
        setTradeMessage(res.data.message);
        setPurchaseList([]);
      });
    setLoading2(false);
  };

  const tradeFormik = useFormik({
    initialValues: {
      amount_to_spend: "",
    },
    onSubmit: (values) => {
      // console.log("submitting trade");
      setCalculatedData([]);
      setLoading2(true);
      handleCalculate(values);
      tradeFormik.resetForm({ values: tradeFormik.initialValues });
      setCheckAll(false);
      checkedState.fill(false);
    },
    validationSchema: Yup.object({
      amount_to_spend: Yup.string().trim().required("Dollar amount required"),
    }),
  });



  return (
    <Layout>
      <div id="main">
          <div className="inner">
            <div id="content">
              <header>
                <h1>Stock Purchase Calculator (Most Recent Top 30)</h1>
                <p>Use the calculator to determine how of many each stock to purchase based on stocks selected and the total amount of money
                that you wish to spend.  The calculator will atomatically calculate equal monetary amounts of each stock.</p>
              </header>

        {auth.account.member.membership_plan === "PM" ||  auth.account.member.membership_plan === "PY"  ?   (
        <section className="main">
        <h2 style={{'marginBottom':'40px'}}>Select stocks to purchase:</h2>
        {Object.keys(top30Data).map((key, i) => (
        <ul className="list-group" key={key}>
        <li className="list-group-item" key="All">
          <input
          type="checkbox"
          id="custom-checkbox-All"
          name="All"
          value="All"
          checked={checkAll}
          onChange={(event) => handleAllChecked(event)}
        />  
        <label htmlFor='custom-checkbox-All'>All</label>
        </li>
        


          {Object.keys(top30Data[key]?.tickers).map((key_, index) => (
            <li className="list-group-item" key={key_}>
                <div className="left-section" key={key_} style={{'display': 'inline-block', 'width': '7rem'}}>
                  <input
                    type="checkbox"
                    id={`custom-checkbox-${index}`}
                    name={top30Data[key]?.tickers[key_]}
                    value={top30Data[key]?.tickers[key_]}
                    checked={checkedState[index]}
                    onChange={(event) => handleOnChange(event, index)}
                  />
                  <label htmlFor={`custom-checkbox-${index}`}>{top30Data[key]?.tickers[key_]}</label>
                </div>
                <div className="right-section" style={{'display': 'inline', 'paddingLeft':'20px'}}>{top30Data[key]?.names[key_]}</div>
            </li>
        ))}
      </ul>
        ))} 

          <section style={{'marginTop':'120px'}}>
              <h3>
                <strong>Add amount to spend:</strong>
              </h3>
              <br />
              <form method="post" onSubmit={tradeFormik.handleSubmit}>
                <div className="row gtr-uniform">
                  
                  <div className="col-6 col-12-xsmall">
                    <input
                      type="text"
                      name="amount_to_spend"
                      id="amount_to_spend"
                      placeholder="Amount to Spend ($)"
                      value={tradeFormik.values.amount_to_spend}
                      onChange={tradeFormik.handleChange}
                      onBlur={tradeFormik.handleBlur}
                    />
                    {tradeFormik.errors.amount_to_spend ? (
                      <div>{tradeFormik.errors.amount_to_spend} </div>
                    ) : null}
                  </div>

                  <div className="col-12">
                    <ul className="actions fixed">
                      <li>
                        <input type="submit" disabled={loading2} />
                      </li>
                      <li>
                        <input
                          type="reset"
                          value="Reset"
                          className="alt"
                          onClick={(e) => {
                            tradeFormik.resetForm();
                            setPurchaseList([]);
                            setTradeMessage([]);
                            setCalculatedData([]);
                            setCheckAll(false);
                            checkedState.fill(false);
                          }}
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </form>
              <div
                            className="text-danger text-center my-2"
                            hidden={false}
                          >
                            {tradeMessage}
                          </div>
            </section>
            {calculatedData.length ? (<div id="content"><header>
                <h1>Calculator Results</h1>
                <p>Based on your input data, these are the names and amount of each stock you can purchase.  The amounts are based on the previous day's closing prices for each stock in question.  Actual amounts may vary slightly due to today's stock price fluctation.</p>
              </header><section className="main">
            <div className="table-wrapper">
                          <table className="alt">
                            <thead>
                              <tr>
                                <th>Ticker</th>
                                <th>Purchase Date</th>
                                <th>Price per Share</th>
                                <th>Shares to Buy</th>
                                <th>Total Price</th>
                              </tr>
                            </thead>
                            <tbody>
                            {Object.keys(calculatedData).map((key, i) => (
                          <tr key={key}>
                            <td>{calculatedData[key].ticker}</td>
                            <td>{calculatedData[key].buy_date}</td>
                            <td>{calculatedData[key].share_price}</td>
                            <td>{calculatedData[key].shares_bought}</td>
                            <td>{calculatedData[key].total_price}</td>
                          </tr>
                        ))}
                            </tbody>
                          </table>
                        </div>

            </section></div>) : (<></>)}
            
      </section>
     ) : (<><br/><h4>Please upgrade to Pro membership level to access this feature</h4></>)}


            </div>

          <div id="sidebar">
            <section className="features">
              <Link to="/companies" className="accent1">
                <h2>Company Data</h2>
                <p>
                  Explore data and charts of company
                  fundamentals.
                </p>
              </Link>
              <Link to="/learningcenter" className="accent2">
                <h2>Learning Center</h2>
                <p>
                  Enhance your understanding of halal and
                  value investing.
                </p>
              </Link>
              <Link to="/portfolio" className="accent3">
                <h2>Virtual Portfolio</h2>
                <p>
                  Track your virtual trades and test your
                  investing strategy.
                </p>
              </Link>
            </section>

            <section className="main">
              <header>
                <h2>Latest Articles</h2>
              </header>
              <section className="mini-posts small">
                {Object.keys(last4ArticleData).map((key) => {
                  return (
                    <article key={key}>
                      <div className="image">
                        <Link
                          to={`/article?title=${last4ArticleData[key].slug}`}
                        >
                          <img
                            src={`${REACT_API_IMAGE_URL}${last4ArticleData[key].thumbnail_image}`}
                            alt=""
                            style={{
                              width: "105px",
                              height: "105px",
                            }}
                          />
                        </Link>
                      </div>
                      <div className="content">
                        <Link
                          to={`/article?title=${last4ArticleData[key].slug}`}
                        >
                          {parse(
                            last4ArticleData[key]
                              .teaser_text
                          )}
                        </Link>
                        <div className="timestamp"></div>
                      </div>
                    </article>
                  );
                })}

                <ul className="actions fixed">
                  <li>
                    <Link
                      to="/article-list"
                      className="button"
                    >
                      More Posts
                    </Link>
                  </li>
                </ul>
              </section>
            </section>

           <section className="mini-posts minimal main" style={{'marginTop':'60px', 'textAlign':'center'}}>
                <header>
                  <h2>Tweets</h2>
                  <h4>@WealthSeedLLC</h4>
                </header>
                <article style={{'height':'900px', 'overflowY':'auto'}}>
                <TwitterContainer/>
                </article>
              </section>
          </div>




        </div>
      </div>
    </Layout>

  );
}