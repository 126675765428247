import React from "react";
import { useSelector } from "react-redux";
import Layout from "./layout";
import { Link } from "react-router-dom";

import { RootState } from "../store/index.ts";

function renderSwitch(param) {
  switch (param) {
    case "PM":
      return "Pro (Billed Qarterly)";
    case "PY":
      return "Pro (Billed Annually)";
    case "AM":
      return "Advanced (Billed Quarterly)";
    case "AY":
      return "Advanced (Billed Annually)";
    default:
      return "Basic (Free)";
  }
}

function MembershipRenewal() {
  const account = useSelector((state: RootState) => state.auth.account);
  const member = account.member;
  const auth = useSelector((state: RootState) => state.auth);

  window.scrollTo(0, 0);

  return (
    <Layout>
      <div id="main">
        <div className="inner">
          <div id="content">
            <section className="main">
              <header>
                <h1>Renew Membership Plan</h1>
                <p className="self-center my-auto">
                  Welcome, {auth.account.first_name}
                </p>
              </header>
              <p>
                Your current membership plan is:{" "}
                {renderSwitch(member.membership_plan)}
              </p>
              <p>
                To continue with the same plan, click{" "}
                <Link
                  to="/secure-payment"
                  state={{ plan: member.membership_plan, uid: account.id }}
                >
                  here.
                </Link>
              </p>
              <p>
                To choose a new plan, check out the <a href="/pricing">pricing.</a>
              </p>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default MembershipRenewal;
