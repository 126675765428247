import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import YoutubeEmbed from "./YoutubeEmbed";
import FontAwesome from "react-fontawesome";
import format from "date-fns/format";
import { config } from "./constants";
import Layout from "./layout";
import TwitterContainer from "./twittercontainer";
import getArticleData from "./getArticleData";






function Article() {
	var REACT_API_IMAGE_URL = config.url.REACT_API_IMAGE_URL;
  	const query = new URLSearchParams(useLocation().search);
  	const slug = query.get("title");
	const [articleData, setArticleData] = useState([]);
	const [slugData, setSlugData] = useState([]);
	const [last4ArticleData, setLast4ArticleData] = useState([]);
	const [bodyElements, setBodyElements] = useState([]);
	var parse = require("html-react-parser");

	// useEffect(() => {
	// 	const { slug } = location.state ?? {};
	// 	setSlugData(slug);
	// }, []);


	// console.log(slug);
	useEffect(() => {
		var body_elements = [];

		getArticleData(slug)
			.then((data) => {
				setArticleData(data["article"]);
				setLast4ArticleData(data["last_4_articles"]);
				data["article"][0]?.image.map((item) => {
					body_elements.push(item);
				});
				data["article"][0]?.paragraph.map((item) => {
					body_elements.push(item);
				});
				data["article"][0]?.video.map((item) => {
					body_elements.push(item);
				});
				//sort body elements by order
				body_elements = body_elements.sort(function (a, b) {
					return a.order - b.order;
				});
			});
		setBodyElements(body_elements);
	}, [slug]);


	useEffect(() => {
		window.scrollTo(0, 0);
	}, [slug]);

	
	return (
		<Layout>
			<div id="main">
				<div className="inner">
					<div id="content">
						<section className="main">
							<section>
								<article>
									{" "}
									{Object.keys(articleData).map((key) => {
										return (
											<>
												<head>
													<meta charSet="UTF-8" />
													<meta
														name="description"
														content={
															articleData[key]
																.meta_description
														}
													/>{" "}
													<meta
														name="keywords"
														content={
															articleData[key]
																.meta_keywords
														}
													/>{" "}
													<meta
														name="author"
														content="WealthSeed, L.L.C."
													/>
													<meta
														name="viewport"
														content="width=device-width, initial-scale=1.0"
													/>
												</head>{" "}
												<div className="image fit">
													<img
														src={`${REACT_API_IMAGE_URL}${articleData[key].lead_image}`}
														alt=""
													/>
													<div
														style={{
															marginTop: "10px",
														}}
													>
														<FontAwesome
															className="fal fa-calendar-alt"
															name="calendar"
															style={{
																color: "#aeaeae",
																marginRight:
																	"1rem",
															}}
														/>{" "}
														{format(
															new Date(
																articleData[
																	key
																].publish_date
															),
															"dd MMMM yyyy"
														)}{" "}
													</div>{" "}
												</div>{" "}
												<header>
													<h1>
														{" "}
														{
															articleData[key]
																.title
														}{" "}
													</h1>{" "}
												</header>{" "}
												<div
													className="objective-div"
													style={{
														width: "100%",
														marginBottom: "50px",
														borderBottom:
															"1px solid",
														borderColor: "#e0e0e0",
													}}
												>
													<h3
														style={{
															color: "#aeaeae",
														}}
													>
														Objectives{" "}
													</h3>{" "}
													{parse(
														articleData[key]
															.objectives
													)}{" "}
												</div>{" "}
											</>
										);
									})}{" "}
									{Object.keys(bodyElements).map((key) => {
										return bodyElements[key]
											.content_type === "image" ? (
											<div className="image fit">
												<img
													src={`${REACT_API_IMAGE_URL}${bodyElements[key].image}`}
													alt=""
												/>
											</div>
										) : bodyElements[key].content_type ===
										  "body-text" ? (
											parse(bodyElements[key].content)
										) : bodyElements[key].content_type ===
										  "video" ? (
											parse(
												<YoutubeEmbed
													embedId={
														bodyElements[key]
															.embed_id
													}
												/>
											)
										) : (
											<> </>
										);
									})}{" "}
								</article>{" "}
							</section>{" "}
						</section>{" "}
					</div>{" "}
					<div id="sidebar">
						<section className="features">
							<Link to="/companies" className="accent1">
								<h2> Company Data </h2>{" "}
								<p>
									Explore data and charts of company
									fundamentals.{" "}
								</p>{" "}
							</Link>{" "}
							<Link to="/learningcenter" className="accent2">
								<h2> Learning Center </h2>{" "}
								<p>
									Enhance your understanding of halal and
									value investing.{" "}
								</p>{" "}
							</Link>{" "}
							<Link to="/portfolio" className="accent3">
								<h2> Virtual Portfolio </h2>{" "}
								<p>
									Track your virtual trades and test your
									investing strategy.{" "}
								</p>{" "}
							</Link>{" "}
						</section>{" "}
						<section className="main">
							<header>
								<h2> Latest Articles </h2>{" "}
							</header>{" "}
							<section className="mini-posts small">
								{" "}
								{Object.keys(last4ArticleData).map((key) => {
									return (
										<article key={key}>

									<div className="image">
											<Link to={`/article?title=${last4ArticleData[key].slug}`} ><img
														src={`${REACT_API_IMAGE_URL}${last4ArticleData[key].thumbnail_image}`}
														alt=""
														style={{
															width: "105px",
															height: "105px",
														}}
													/>{" "}
											</Link>{" "}
											</div>
											<div className="content">
												<Link to={`/article?title=${last4ArticleData[key].slug}`} >{parse(
														last4ArticleData[key]
															.teaser_text
													)}{" "}
												</Link>{" "}
												<div className="timestamp">
													{" "}
												</div>{" "}
											</div>{" "}
										</article>
									);
								})}{" "}
								<ul className="actions fixed">
									<li>
										<Link
											to="/article-list"
											className="button"
										>
											More Posts{" "}
										</Link>{" "}
									</li>{" "}
								</ul>{" "}
							</section>{" "}
						</section>{" "}
			           	<section className="mini-posts minimal main" style={{'marginTop':'60px', 'textAlign':'center'}}>
			                <header>
			                  <h2>Tweets</h2>
			                  <h4>@WealthSeedLLC</h4>
			                </header>
			                <article style={{'height':'900px', 'overflowY':'auto'}}>
			                <TwitterContainer/>
			                </article>
			            </section>{" "}
					</div>{" "}
				</div>{" "}
			</div>{" "}
		</Layout>
	);
}
export default Article;
