import React, { useEffect } from "react";
import Layout from "./layout";

function CookiePolicy() {

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Layout>
			<div id="main">
				<div className="inner">
					<div id="content">
						<section className="main">
							<p>Effective on March 18, 2023</p>
							<p>
								At Wealthseed, we believe in being clear and
								open about how we collect and use data related
								to you. In the spirit of transparency, this
								policy provides detailed information about how
								and when we use cookies. This cookie policy
								applies to any WealthSeed product or service
								that links to this policy or incorporates it by
								reference.
							</p>
							<div>
								<h1>Does WealthSeed use cookies?</h1>

								<p>
									Yes, we use cookies to ensure that everyone
									who uses WealthSeed has the best possible
									experience. Cookies also help us to keep
									your account safe. By continuing to visit or
									use our services, you are agreeing to the
									use of cookies and similar technologies for
									the purposes described above.
								</p>
							</div>
							<div>
								<h1>What is a cookie?</h1>

								<p>
									A cookie is a small file placed onto your
									device that enables WealthSeed features and
									functionality. For example, cookies enable
									use to identify your device, secure your
									access to our website and even helps us to
									know if someone attempts to access your
									account from a different device.
								</p>
							</div>
							<div>
								<h1>What are the cookies used for?</h1>

								<p>
									A description of the cookies that we use is
									listed in the table below:
								</p>

								<table class="table table-bordered">
									<thead>
										<tr>
											<th>Categories of Use</th>
											<th>Description</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>Authentication</td>
											<td>
												If you're signed in to our site,
												cookies help us to show you the
												right information and
												personalize your experience.
											</td>
										</tr>
										<tr>
											<td>Security</td>
											<td>
												We use cookies to enable and
												support our security features
												and to help us detect malicious
												activity and violations of our
												user agreement.
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</section>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export default CookiePolicy;
