import React, { useState, useEffect, useRef } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import store from "../store/index.ts";
import axios from "axios";
import CheckoutForm from "./checkoutform";
import { useLocation } from "react-router-dom";
import { config } from './constants';
// import { useNavigate } from "react-router";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe(
  config.url.STRIPE_PUBLIC_KEY
);

export default function App() {
  var REACT_APP_API_URL = config.url.REACT_APP_API_URL

  const [clientSecret, setClientSecret] = useState("");
  const [chargeAmount, setChargeAmount] = useState("");
  const { state } = useLocation();

  const uid = state.uid || {};
  const plan = state.plan || {};
  const dataFetchedRef = useRef(false);



  const { token } = store.getState().auth;

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    
  // Avoid hitting API twice
  if (dataFetchedRef.current) return;
  dataFetchedRef.current = true;
    //prevent hitting API twice when page loads
    axios({
      method: "post",
      url: `${REACT_APP_API_URL}/create-payment-intent/`,
      data: { uid, plan },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer" + { token },
      },
    }).then((res) => {
      setClientSecret(res.data.clientSecret);
      setChargeAmount(res.data.chargeAmount);
    });
  }, []);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="App">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm
            chargeAmount={chargeAmount}
            user_id={uid}
            membership_plan={plan}
          />
        </Elements>
      )}
    </div>
  );
}
