import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
// import { Formik, Form, useField, ErrorMessage } from "formik";
import { ref } from "yup";
import axios from "axios";
import Layout from "../screener/layout";
import * as Yup from "yup";
import { useFormik } from "formik";
import authSlice from "../store/slices/auth.ts";
import { Link } from "react-router-dom";
import transparent_color_full_logo from "../images/transparent_color_full_logo.svg";
import { config } from './constants';


function ResetPassword() {
  var REACT_APP_API_URL = config.url.REACT_APP_API_URL


  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const urlString = window.location.href;
  const url = new URL(urlString);
  let token = url.searchParams.get("token");

  const handleReset = (password: string) => {
    setLoading(false);
    axios
      .post(
        `${REACT_APP_API_URL}/api/password_reset/confirm/`,
        { token, password },
        { withCredentials: true }
      )
      .then((res) => {
        if (res.data.status === "OK") {
          setShow(false);
          dispatch(authSlice.actions.logout());
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    onSubmit: (values) => {
      setLoading(true);
      handleReset(values.password);
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .trim()
        .required("Required")
        .min(8, "Must be 8 characters or more")
        .matches(/[a-z]+/, "One lowercase character")
        .matches(/[A-Z]+/, "One uppercase character")
        .matches(/[@$!%*#?&]+/, "One special character")
        .matches(/\d+/, "One number"),
      confirmPassword: Yup.string()
        .trim()
        .required("Please confirm your password")
        .oneOf([ref("password")], "Passwords do not match"),
    }),
  });

  return (
    <Layout>
      <div id="main">
        <div className="inner">
          <div id="content">
            <div className="col-xl-10">
              <div className="card rounded-3 text-black">
                <div className="row g-0">
                  <div className="col-lg-6">
                    <div className="card-body p-md-5 mx-md-4">
                      <div className="text-center">
                        <img
                          src={transparent_color_full_logo}
                          style={{ width: "80%" }}
                          alt="logo"
                        />
                        <br />
                        <br />
                      </div>

                      {show ? (
                        <form method="post" onSubmit={formik.handleSubmit}>
                          <br />
                          <h4>Reset Password</h4>
                          <br />
                          <div className="form-outline mb-4">
                            <input
                              name="password"
                              placeholder="Password"
                              type="password"
                              value={formik.values.password}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.errors.password ? (
                              <div>{formik.errors.password} </div>
                            ) : null}
                          </div>

                          <div className="form-outline mb-4">
                            <input
                              name="confirmPassword"
                              placeholder="Confirm Password"
                              type="password"
                              value={formik.values.confirmPassword}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.errors.confirmPassword ? (
                              <div>{formik.errors.confirmPassword} </div>
                            ) : null}
                          </div>
                          <div className="flex items-center justify-between">
                            <div
                              className="text-danger text-center my-2"
                              hidden={false}
                            >
                              {message}
                            </div>
                          </div>
                          <div className="text-center pt-1 mb-5 pb-1">
                            <button
                              type="submit"
                              disabled={loading}
                              className="btn btn-primary btn-block fa-lg gradient-custom-3 mb-3"
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      ) : (
                        <div className="flex items-center justify-between">
                          <h5>Your password has been successfully reset.</h5>
                          <br />
                          <Link to="/login">
                            <button
                              type="submit"
                              className="btn btn-primary btn-block fa-lg gradient-custom-3 mb-3"
                            >
                              Return to Login
                            </button>
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 d-flex align-items-center gradient-custom-2">
                    <div className="text-white px-3 py-4 p-md-5 mx-md-4">
                      <h4 className="mb-4">
                        Where halal investing meets value investing
                      </h4>
                      <p className="small mb-0">
                        Investing in stocks is a way for Muslim families to
                        prepare for life essentials such as educating children,
                        home ownership, haaj, or retirement. The historical
                        growth of the stock market provides a risk-controlled
                        method to build and protect wealth.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ResetPassword;
