import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import FontAwesome from "react-fontawesome";
import { useSelector } from "react-redux";
import { RootState } from "../store/index.ts";
import Layout from "./layout";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";
import YoutubeEmbed from "./YoutubeEmbed";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';


import p622669 from "./assets/images/622669.png";
import p961f10e2 from "./assets/images/4221516-961f10e2.png";
import p5b3b713b from "./assets/images/114806-5b3b713b.png";
import pe50eb5d8 from "./assets/images/2737446-e50eb5d8.png";
import pa9cd2465 from "./assets/images/943329-a9cd2465.png";
import p2961948 from "./assets/images/2961948.png";
import pf3498cb9 from "./assets/images/471664-f3498cb9.png";
import peea0f8a0 from "./assets/images/664866-eea0f8a0.png";
import p4f1aa8a8 from "./assets/images/4221532-4f1aa8a8.png";
import p59868b49 from "./assets/images/9632708-59868b49.png";
import three_business_people from "./assets/images/three-business-people-pointing-at-paper-with-marketing-analysis-SBI-300739589_720x480_flattened.png";
import businessman_using_laptop from "./assets/images/businessman-using-laptop-with-graph-statistical-analysis-business-success-concept-840x497.png";
import calculator_documents from "./assets/images/calculator-documents-and-laptop-on-the-table-of-business-person-840x560.png";
import smartphone_and_european_currency from "./assets/images/smartphone-and-european-currency-banknotes-scattered-on-the-wooden-table-background-840x561.png";
import finance_background from "./assets/images/Finance-Background-PNG-Image.png";
import head_shot from "./assets/images/mm_small.jpg";
import halal_demo from "./assets/images/halal_demo.png";
import valuation_demo from "./assets/images/valuation_demo.png";
import data_demo from "./assets/images/data_demo.png";
import learning_center_demo from "./assets/images/learning_center_demo.png";
import trade_simulator_demo from "./assets/images/trade_simulator_demo.png";
import portfolio_demo from "./assets/images/portfolio_demo.png";



function Home() {
	const mediaMatch = window.matchMedia('(max-width: 913px)');

	const [halalModalOpen, setHalalModalOpen] = React.useState(false);
	const handleHalalModalOpen = () => setHalalModalOpen(true);
	const handleHalalModalClose = () => setHalalModalOpen(false);

	const [valueModalOpen, setValueModalOpen] = React.useState(false);
	const handleValueModalOpen = () => setValueModalOpen(true);
	const handleValueModalClose = () => setValueModalOpen(false);

	const [learningModalOpen, setLearningModalOpen] = React.useState(false);
	const handleLearningModalOpen = () => setLearningModalOpen(true);
	const handleLearningModalClose = () => setLearningModalOpen(false);

	const [tradeModalOpen, setTradeModalOpen] = React.useState(false);
	const handleTradeModalOpen = () => setTradeModalOpen(true);
	const handleTradeModalClose = () => setTradeModalOpen(false);

	const [portfolioModalOpen, setPortfolioModalOpen] = React.useState(false);
	const handlePortfolioModalOpen = () => setPortfolioModalOpen(true);
	const handlePortfolioModalClose = () => setPortfolioModalOpen(false);

	const [dataModalOpen, setDataModalOpen] = React.useState(false);
	const handleDataModalOpen = () => setDataModalOpen(true);
	const handleDataModalClose = () => setDataModalOpen(false);


	useEffect(() => {
		window.scrollTo(0, 0);
		const mediaMatch = window.matchMedia('(max-width: 913px)');
	}, []);


// modal style
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: mediaMatch.matches ? '100%' : '75%',
  bgcolor: '#dddddd',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
	const navigate = useNavigate();
	const [isYearly, setisYearly] = useState(false);

	const toggleClass = () => {
		setisYearly(isYearly === false ? true : false);
	};
	const auth = useSelector((state: RootState) => state.auth);

	return (
		<Layout>
			<div id="content">
				<div className="u-body u-xl-mode" data-lang="en">
					<section
						className="u-clearfix u-gradient u-section-1"
						id="carousel_3389"
					>
						<div
							className="fdb-block"
							data-block-type="call_to_action"
							data-id="8"
						>
							<div className="u-clearfix u-sheet">
								<div className="row align-items-center text-left">
									<div
										className="col-12 col-lg-5 ml-0 pt-5 pt-lg-0 fr-box fr-inline"
										
									>
										<div className="fr-wrapper" dir="auto">
											<div
												className="fr-element fr-view u-custom-font u-font-ubuntu u-text u-text-body-alt-color"
												
											>
												<h2 className="u-align-left u-custom-font u-font-ubuntu u-text u-text-body-alt-color"
												style={{fontWeight: '700'}}
												>Are you ready?</h2>
												<p className="lead">
													Get the data, build the
													wealth.
												</p>
												<p className="mt-4">
													<Link to="/pricing"
														className="u-btn u-btn-round u-button-style u-hover-feature u-hover-white u-palette-2-base u-radius-5"
														style={{fontWeight: '600'}}
													>
														Join Today!
													</Link>
												</p>
											</div>
										</div>
									</div>
									<div
										className="col-12 col-sm-6 fr-box"
										
									>
										<div className="fr-wrapper" dir="auto">
											<div
												className="fr-element fr-view"
												
											>
												<p>
													{/*<img
														alt="image"
														className="img-fluid"
														src={finance_background}
													/>*/}
												<YoutubeEmbed embedId="7PDe_RB2eNo" />

													
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="u-clearfix u-sheet u-sheet-1">
							<h2 className="u-align-left u-custom-font u-font-ubuntu u-text u-text-body-alt-color u-text-1"
							style={{'margin':'33px 0 0 0' }}>
								{" "}
								Building Wealth Through <span style={{'color':'#f48406','-webkit-text-stroke': '2px #404040'}}>Halal</span> Value Investing
							</h2>
							<p
								className="u-align-left u-text u-text-body-alt-color u-text-2"
								style={{'margin':'40px 0 0 0' }}
							>
								Investing in the stock market is one of the best
								ways for individuals to build wealth.&nbsp; Over
								the past 50 years, the stock market has returned
								an average of 10% per year.&nbsp; For the ten
								years between 2012 and 2021, the average stock
								market return was 14.8% annually for the
								S&amp;P500 index, a collection of the 500
								largest US companies.&nbsp; These impressive
								gains are the reason that the stock market has
								historically been an important avenue for
								Americans to generate wealth.&nbsp;&nbsp;
								<br />
								<br />
								Muslim families are faced with the extra burden
								of making sure that they can gain exposure to
								the stock market in a halal manner.&nbsp;
								&nbsp;Employer provided 401(k) plans and
								standard mutual funds contain stocks that are
								not permissible for Muslim investors.&nbsp;
								Thus, if Muslim families are to benefit from the
								growth of the economy, they must have a way to
								participate in the stock market in a safe and
								halal manner.&nbsp; This is the reason that we
								started WealthSeed.
							</p>
							<Link
								to="/main-article?title=the-case-for-investing-in-stocks"
								
								className="u-active-white u-align-left u-border-none u-btn u-btn-round 
							u-button-style u-hover-feature u-hover-white u-palette-2-base u-radius-15 u-text-active-palette-3-base u-text-hover-palette-3-base u-btn-1"
							>
								learn more
							</Link>
						</div>
					</section>
					<section
						className="u-clearfix u-container-align-center u-section-2"
						id="carousel_71e0"
					>
						<div className="u-clearfix u-sheet u-sheet-1">
							<h1 className="u-text u-text-default u-text-1">
								What you get ...
							</h1>

							<div className="u-expanded-width u-list u-list-1">
								<div className="u-repeater u-repeater-1">
									<AnimationOnScroll
										animateIn="animate__zoomIn"
										initiallyVisible="true"
									>
										<div
											className="u-container-style u-custom-color-2 u-custom-item u-list-item u-radius-30 u-repeater-item u-shape-round u-list-item-1"
											style={{'cursor':'pointer'}}
											onClick={handleHalalModalOpen}
										>
											<div className="u-container-layout u-similar-container u-valign-bottom u-container-layout-1">
												<span className="u-align-left u-custom-item u-file-icon u-icon u-icon-circle u-white u-icon-1">
													<img src={p622669} alt="" />
												</span>
												<h6 className="u-align-left u-custom-font u-font-ubuntu u-text u-text-2">
													Halal Stock Screening
												</h6>
												<p className="u-align-left u-text u-text-3">
													Access up-to-date halal
													screening of the top 3000 US
													companies.
													<br />
													<br />
												</p>
											</div>
											      

										</div>
										<Modal
								        open={halalModalOpen}
								        onClose={handleHalalModalClose}
								        aria-labelledby="modal-modal-title"
								        aria-describedby="modal-modal-description"
								      >
								        <Box sx={style}>
								        <div style={{'width': '100%', 'textAlign':'center'}}>
								          		<img className="modal-image" style={{'width': mediaMatch.matches ? '100%' : '80%'}}src={halal_demo} alt=""/>
										 	</div>
										 	<div style={{'width':'100%', 'marginTop':'10px', 'background':'#ffffff', 'padding':'40px'}}>
								          <Typography id="modal-modal-title" variant="h6" component="h2">
								            Halal Stock Screening
								          </Typography>
								          	
								          <Typography id="modal-modal-description" style={{'fontSize': mediaMatch.matches ? 'inherit' : '2rem'}} sx={{ mt: 8 }}>
								             You will gain access to current halal status of US companies.  This list is constanly updated when new financial filings become available each quarter.          
								             </Typography>
								             </div>
								        </Box>
								      </Modal>
									</AnimationOnScroll>
									<AnimationOnScroll
										animateIn="animate__zoomIn"
										initiallyVisible="true"
									>
										<div
											className="u-container-style u-custom-item u-list-item u-radius-30 u-repeater-item u-shape-round u-white u-list-item-2"
											style={{'cursor':'pointer'}}
											onClick={handleValueModalOpen}
										>
											<div className="u-container-layout u-similar-container u-valign-bottom u-container-layout-2">
												<span className="u-align-left u-custom-color-3 u-custom-item u-file-icon u-icon u-icon-circle u-text-white u-icon-2">
													<img
														src={p961f10e2}
														alt=""
													/>
												</span>
												<h6 className="u-align-left u-custom-font u-font-ubuntu u-text u-text-4">
													{" "}
													Value Investing
												</h6>
												<p className="u-align-left u-text u-text-5">
													We provide a list of good
													companies selling at bargain
													prices using a formula that
													has proven to consistently
													beat the market.
												</p>
											</div>
										</div>
										<Modal
								        open={valueModalOpen}
								        onClose={handleValueModalClose}
								        aria-labelledby="modal-modal-title"
								        aria-describedby="modal-modal-description"
								      >
								        <Box sx={style}>
								        <div style={{'width': '100%', 'textAlign':'center'}}>
								          		<img className="modal-image" style={{'width': mediaMatch.matches ? '100%' : '80%'}}src={valuation_demo} alt=""/>
										 	</div>
										 	<div style={{'width':'100%', 'marginTop':'10px', 'background':'#ffffff', 'padding':'40px'}}>
								          <Typography id="modal-modal-title" variant="h6" component="h2">
								            WealthSeed Top 30
								          </Typography>
								          	
								          <Typography id="modal-modal-description" style={{'fontSize': mediaMatch.matches ? 'inherit' : '2rem'}} sx={{ mt: 8 }}>
								             You will gain access to a list of the top 30 halal value stocks screened by our market-beating WealthSeed Halal Value Investment Formula.        
								             </Typography>
								             </div>
								        </Box>
								      </Modal>
									</AnimationOnScroll>
									<AnimationOnScroll
										animateIn="animate__zoomIn"
										initiallyVisible="true"
									>
										<div
											className="u-container-align-left u-container-style u-custom-item u-list-item u-radius-30 u-repeater-item u-shape-round u-white u-list-item-3"
											style={{'cursor':'pointer'}}
											onClick={handleLearningModalOpen}
										>
											<div className="u-container-layout u-similar-container u-valign-bottom u-container-layout-3">
												<span className="u-align-left u-custom-color-3 u-custom-item u-file-icon u-icon u-icon-circle u-text-white u-icon-3">
													<img
														src={p5b3b713b}
														alt=""
													/>
												</span>
												<h6 className="u-align-left u-custom-font u-font-ubuntu u-text u-text-6">
													{" "}
													Education
												</h6>
												<p className="u-align-left u-text u-text-7">
													Our learning center will
													give you the knowledge you
													need to understand your
													investments.
												</p>
											</div>
										</div>
										<Modal
								        open={learningModalOpen}
								        onClose={handleLearningModalClose}
								        aria-labelledby="modal-modal-title"
								        aria-describedby="modal-modal-description"
								      >
								        <Box sx={style}>
								        <div style={{'width': '100%', 'textAlign':'center'}}>
								          		<img className="modal-image" style={{'width': mediaMatch.matches ? '100%' : '80%'}}src={learning_center_demo} alt=""/>
										 	</div>
										 	<div style={{'width':'100%', 'marginTop':'10px', 'background':'#ffffff', 'padding':'40px'}}>
								          <Typography id="modal-modal-title" variant="h6" component="h2">
								            Education
								          </Typography>
								          	
								          <Typography id="modal-modal-description" style={{'fontSize': mediaMatch.matches ? 'inherit' : '2rem'}} sx={{ mt: 8 }}>
								             Level-up your investment knowledge in our Learning Center.  Articles and videos give you the confidence to understand and trust your investments.      
								             </Typography>
								             </div>
								        </Box>
								      </Modal>
									</AnimationOnScroll>
									<AnimationOnScroll animateIn="animate__zoomIn">
										<div
											className="my_tag u-container-align-left u-container-style u-custom-item u-list-item u-radius-30 u-repeater-item u-shape-round u-white u-list-item-4"
											style={{'cursor':'pointer'}}
											onClick={handleTradeModalOpen}
										>
											<div className="u-container-layout u-similar-container u-valign-bottom u-container-layout-4">
												<span className="u-align-left u-custom-color-3 u-custom-item u-file-icon u-icon u-icon-circle u-text-white u-icon-4">
													<img
														src={pe50eb5d8}
														alt=""
													/>
												</span>
												<h6 className="u-align-left u-custom-font u-font-ubuntu u-text u-text-8">
													Trade Simulator
												</h6>
												<p className="u-align-left u-text u-text-9">
													Test your strategy using
													over 20 years of historical
													daily stock price data that
													is kept up-to-date daily.
												</p>
											</div>
										</div>
										<Modal
								        open={tradeModalOpen}
								        onClose={handleTradeModalClose}
								        aria-labelledby="modal-modal-title"
								        aria-describedby="modal-modal-description"
								      >
								        <Box sx={style}>
								        <div style={{'width': '100%', 'textAlign':'center'}}>
								          		<img className="modal-image" style={{'width': mediaMatch.matches ? '100%' : '80%'}}src={trade_simulator_demo} alt=""/>
										 	</div>
										 	<div style={{'width':'100%', 'marginTop':'10px', 'background':'#ffffff', 'padding':'40px'}}>
								          <Typography id="modal-modal-title" variant="h6" component="h2">
								            Trade Simulator
								          </Typography>
								          	
								          <Typography id="modal-modal-description" style={{'fontSize': mediaMatch.matches ? 'inherit' : '2rem'}} sx={{ mt: 8 }}>
								             With access to over 20 years of daily stock prices, you can test your investment strategy.  Prices are updated daily.       
								             </Typography>
								             </div>
								        </Box>
								      </Modal>
									</AnimationOnScroll>
									<AnimationOnScroll animateIn="animate__zoomIn">
										<div
											className="u-container-style u-custom-item u-list-item u-radius-30 u-repeater-item u-shape-round u-white u-list-item-5"
											style={{'cursor':'pointer'}}
											onClick={handlePortfolioModalOpen}
										>
											<div className="u-container-layout u-similar-container u-valign-bottom u-container-layout-5">
												<span className="u-align-left u-custom-color-3 u-custom-item u-file-icon u-icon u-icon-circle u-text-white u-icon-5">
													<img
														src={pa9cd2465}
														alt=""
													/>
												</span>
												<h6 className="u-align-left u-custom-font u-font-ubuntu u-text u-text-10">
													{" "}
													Virtual Portfolio
												</h6>
												<p className="u-align-left u-text u-text-11">
													Keep track of your
													investments using the
													virtual portfolio.
													<br />
													<br />
												</p>
											</div>
										</div>
										<Modal
								        open={portfolioModalOpen}
								        onClose={handlePortfolioModalClose}
								        aria-labelledby="modal-modal-title"
								        aria-describedby="modal-modal-description"
								      >
								        <Box sx={style}>
								        <div style={{'width': '100%', 'textAlign':'center'}}>
								          		<img className="modal-image" style={{'width': mediaMatch.matches ? '100%' : '80%'}}src={portfolio_demo} alt=""/>
										 	</div>
										 	<div style={{'width':'100%', 'marginTop':'10px', 'background':'#ffffff', 'padding':'40px'}}>
								          <Typography id="modal-modal-title" variant="h6" component="h2">
								            Virtual Portfolio
								          </Typography>
								          	
								          <Typography id="modal-modal-description" style={{'fontSize': mediaMatch.matches ? 'inherit' : '2rem'}} sx={{ mt: 8 }}>
								             Keep track of your investments with the virtual portfolio.  When you use the trade simulator, all of your trade activity is stored in your portfolio.       
								             </Typography>
								             </div>
								        </Box>
								      </Modal>
									</AnimationOnScroll>
									<AnimationOnScroll animateIn="animate__zoomIn">
										<div
											className="u-container-style u-custom-color-2 u-custom-item u-list-item u-radius-30 u-repeater-item u-shape-round u-list-item-6"
											style={{'cursor':'pointer'}}
											onClick={handleDataModalOpen}
										>
											<div className="u-container-layout u-similar-container u-valign-bottom u-container-layout-6">
												<span className="u-align-left u-custom-item u-file-icon u-icon u-icon-circle u-white u-icon-6">
													<img
														src={p2961948}
														alt=""
													/>
												</span>
												<h6 className="u-align-left u-custom-font u-font-ubuntu u-text u-text-12">
													{" "}
													Powerful Screener Tool
												</h6>
												<p className="u-align-left u-text u-text-13">
													Sort and filter stocks based on financial data
													to find opportunities with the most potential for return.
													<br />
												</p>
											</div>
										</div>
										<Modal
								        open={dataModalOpen}
								        onClose={handleDataModalClose}
								        aria-labelledby="modal-modal-title"
								        aria-describedby="modal-modal-description"
								      >
								        <Box sx={style}>
								        <div style={{'width': '100%', 'textAlign':'center'}}>
								          		<img className="modal-image" style={{'width': mediaMatch.matches ? '100%' : '80%'}}src={data_demo} alt=""/>
										 	</div>
										 	<div style={{'width':'100%', 'marginTop':'10px', 'background':'#ffffff', 'padding':'40px'}}>
								          <Typography id="modal-modal-title" variant="h6" component="h2">
								            Powerful Screener Tool
								          </Typography>
								          	
								          <Typography id="modal-modal-description" style={{'fontSize': mediaMatch.matches ? 'inherit' : '2rem'}} sx={{ mt: 8 }}>
								             Easily create complex sort and filter sequences to find great stocks.     
								             </Typography>
								             </div>
								        </Box>
								      </Modal>
									</AnimationOnScroll>
								</div>
							</div>
						</div>
					</section>
					<section
						className="u-clearfix u-custom-color-1 u-section-3"
						id="carousel_99a0"
					>
						<div className="u-clearfix u-sheet u-valign-middle u-sheet-1">
							<div className="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
								<div className="u-layout">
									<div className="u-layout-row">
										<div className="u-container-align-center-sm u-container-align-center-xs u-container-style u-layout-cell u-shape-rectangle u-size-40 u-layout-cell-1">
											<div className="u-container-layout u-container-layout-1">
												<h1 className="u-align-center-sm u-align-center-xs u-custom-font u-font-ubuntu u-text u-text-default u-text-1">
													{" "}
													Is halal investing only for Muslims?
												</h1>
												<p className="u-text u-text-default u-text-2">
													{" "}
													Absolutely NOT! Halal investing is for anyone
													that wants to build wealth through 
													investing in ethical companies 
													that don't make their money by 
													charging people interest or 
													selling them products considered 
													by Muslims to be unhealthy or 
													immoral, such as alcohol, tobacco,
													or pornography. At WealthSeed, we 
													truly believe that EVERYONE SHOULD BE
													A HALAL INVESTOR! In fact, our research
													shows that halal stocks significantly outperformed
													non-halal stocks for the 11 years between 
													2012 - 2022.											
												</p>
											</div>
										</div>
										<div className="u-container-align-center u-container-style u-layout-cell u-shape-rectangle u-size-20 u-layout-cell-2">
											<div className="u-container-layout u-valign-middle u-container-layout-2">
												<Link
													to="/main-article?title=the-wealthseed-halal-value-investing-formula"
													
													className="u-align-center u-border-none u-btn u-btn-round u-button-style u-custom-color-2 u-radius-10 u-btn-1"
												>
													read more
												</Link>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						className="u-align-center u-border-1 u-border-grey-50 u-border-no-bottom u-border-no-left u-border-no-right u-clearfix u-container-align-center-lg u-container-align-center-md u-container-align-center-sm u-container-align-center-xl u-section-4"
						id="carousel_76b5"
					>
						<div className="u-clearfix u-sheet u-sheet-1">
							<div className="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
								<div className="u-layout">
									<div className="u-layout-row">
										<div className="u-container-align-center-sm u-container-style u-layout-cell u-size-30 u-layout-cell-1">
											<div className="u-container-layout u-container-layout-1">
												<AnimationOnScroll animateIn="animate__bounceInLeft">
													<h2
														className="u-align-center-sm u-align-center-xs u-align-left-lg u-align-left-md u-align-left-xl u-custom-font u-font-ubuntu u-text u-text-1"
														data-animation-name="customAnimationIn"
														data-animation-duration="1500"
														data-animation-delay="500"
													>
														{" "}
														"There must be a way for
														Muslim families to build
														and protect their wealth
														in a halal way."
													</h2>
													<p
														className="u-align-center-sm u-align-center-xs u-align-left-lg u-align-left-md u-align-left-xl u-text u-text-default u-text-2"
														data-animation-name="customAnimationIn"
														data-animation-duration="1500"
														data-animation-delay="500"
													>
														{" "}
														In 2019, Islamic finance
														assets totaled US$2.88
														trillion globally, the
														highest recorded growth
														for the industry since
														the 2008 global
														financial crisis.&nbsp;
														By 2024, this total is
														set to surpass US$3.69
														trillion.&nbsp; In the
														coming years, Islamic
														financial assets are
														expected to keep
														growing, driven by
														rising interest from
														Muslims and non-Muslims
														alike.&nbsp; Despite
														these impressive
														numbers, Muslims in
														western countries don't
														often have options for
														the common financial
														instruments that will
														allow them to
														participate in the
														economy in a halal and
														safe manner.
													</p>
												</AnimationOnScroll>
												<AnimationOnScroll
													animateIn="animate__bounceInLeft"
													delay="250"
												>
													<Link
														to="/wealthseed-story"
														className="u-active-white u-align-center-sm u-align-center-xs u-align-left-lg u-align-left-md u-align-left-xl u-border-active-white u-border-hover-white u-border-none u-btn u-btn-round u-button-style u-custom-color-2 u-hover-custom-color-3 u-radius-10 u-text-active-black u-text-body-alt-color u-text-hover-black u-btn-1"
														
													>
														learn more{" "}
													</Link>
												</AnimationOnScroll>
											</div>
										</div>
										<div className="u-container-style u-layout-cell u-size-30 u-layout-cell-2">
											<div className="u-container-layout u-valign-middle-lg u-valign-middle-md u-valign-middle-xl u-valign-top-sm u-container-layout-2">
												<div className="u-expanded-width u-list u-list-1">
													<div className="u-repeater u-repeater-1">
														<div className="u-align-center u-container-style u-custom-item u-list-item u-repeater-item u-shape-rectangle u-list-item-1">
															<div className="u-container-layout u-similar-container u-valign-bottom-lg u-valign-bottom-md u-valign-bottom-sm u-valign-bottom-xl u-container-layout-3">
																<AnimationOnScroll animateIn="animate__bounceInRight">
																	<div
																		alt=""
																		className="u-image u-image-circle u-image-1"
																		style={{
																			backgroundImage: `url(${head_shot})`,
																		}}
																		data-image-width="2848"
																		data-image-height="4288"
																		data-animation-name="bounceIn"
																		data-animation-duration="1500"
																		data-animation-delay="250"
																		data-animation-direction="Right"
																	></div>
																</AnimationOnScroll>
																<AnimationOnScroll
																	animateIn="animate__bounceInUp"
																	delay="150"
																>
																	<h5
																		className="u-custom-font u-custom-item u-font-ubuntu u-text u-text-default u-text-3"
																		data-animation-name="customAnimationIn"
																		data-animation-duration="1500"
																		data-animation-delay="500"
																	>
																		Muhammad
																		McIntee-Masud
																	</h5>
																</AnimationOnScroll>
																<AnimationOnScroll animateIn="animate__fadeIn">
																	<h6
																		className="u-custom-font u-custom-item u-text u-text-default u-text-font u-text-4"
																		data-animation-name="customAnimationIn"
																		data-animation-duration="1000"
																	>
																		{" "}
																		Founder,
																		CEO
																	</h6>
																</AnimationOnScroll>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						className="u-border-1 u-border-grey-10 u-border-no-bottom u-border-no-left u-border-no-right u-clearfix u-container-align-center u-section-5"
						id="carousel_7745"
					>
						<div className="u-clearfix u-sheet u-sheet-1">
							<div className="u-clearfix u-expanded-width u-gutter-20 u-layout-wrap u-layout-wrap-1">
								<div className="u-layout">
									<div className="u-layout-col">
										<div className="u-size-30">
											<div className="u-layout-row">
												<div className="u-container-align-center u-container-style u-hover-feature u-layout-cell u-shape-rectangle u-size-20 u-white u-layout-cell-1">
													<div className="u-container-layout u-valign-middle u-container-layout-1">
														<h6 className="u-align-center u-custom-font u-font-ubuntu u-text u-text-default u-text-1">
															{" "}
															Halal Stock
															Screening
														</h6>
														<p className="u-align-center u-text u-text-default u-text-grey-40 u-text-2">
															We have screened the
															top 3000 U.S.
															equities to
															determine their
															halal status based
															on financial data
															and their business
															dealings.
														</p>
														<Link
															to="/screener-methodology"
															
															className="u-align-center u-border-hover-palette-3-base u-border-none u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-2 u-radius-10 u-btn-1"
														>
															read more
														</Link>
													</div>
												</div>
												<div className="u-container-style u-layout-cell u-size-20 u-layout-cell-2">
													<div className="u-container-layout u-valign-middle-md u-valign-middle-sm u-valign-middle-xs u-valign-top-lg u-valign-top-xl u-container-layout-2">
														<div
															className="u-image u-image-circle u-image-1"
															data-image-width="720"
															data-image-height="480"
															style={{
																backgroundImage: `url(${three_business_people})`,
															}}
														></div>
													</div>
												</div>
												<div className="u-container-style u-layout-cell u-size-20 u-layout-cell-3">
													<div className="u-container-layout u-valign-middle-md u-valign-middle-sm u-valign-middle-xs u-valign-top-lg u-valign-top-xl u-container-layout-3">
														<div
															className="u-image u-image-circle u-image-2"
															data-image-width="840"
															data-image-height="497"
															style={{
																backgroundImage: `url(${businessman_using_laptop})`,
															}}
														></div>
													</div>
												</div>
											</div>
										</div>
										<div className="u-size-30">
											<div className="u-layout-row">
												<div className="u-container-style u-layout-cell u-size-20 u-layout-cell-4">
													<div className="u-container-layout u-valign-bottom-lg u-valign-bottom-xl u-valign-middle-md u-container-layout-4">
														<div
															className="u-expanded-width-md u-image u-image-circle u-preserve-proportions u-image-3"
															data-image-width="840"
															data-image-height="560"
															style={{
																backgroundImage: `url(${calculator_documents})`,
															}}
														></div>
													</div>
												</div>
												<div className="u-container-style u-layout-cell u-size-20 u-layout-cell-5">
													<div className="u-container-layout u-valign-middle u-container-layout-5">
														<div
															className="u-image u-image-circle u-image-4"
															data-image-width="840"
															data-image-height="561"
															style={{
																backgroundImage: `url(${smartphone_and_european_currency})`,
															}}
														></div>
													</div>
												</div>
												<div className="u-container-align-center u-container-style u-hover-feature u-layout-cell u-palette-2-base u-shape-rectangle u-size-20 u-layout-cell-6">
													<div className="u-container-layout u-valign-middle u-container-layout-6">
														<h6 className="u-align-center u-custom-font u-font-ubuntu u-text u-text-default u-text-3">
															{" "}
															Value Investing
														</h6>
														<p className="u-align-center u-text u-text-default u-text-4">
															The goal of value
															investing is to find
															good companies at
															bargain
															prices.&nbsp; That's
															how you beat the
															market.
														</p>
														<Link
															to="/main-article?title=value-investing-101"
															
															className="u-align-center u-border-hover-palette-3-base u-border-none u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-2 u-radius-10 u-btn-2"
														>
															read more
														</Link>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						className="u-align-left u-clearfix u-gradient u-section-6"
						id="carousel_5153"
					>
						<div className="inner">
							<h2 className="u-align-center u-custom-font u-font-ubuntu u-text u-text-body-alt-color u-text-1">
								{" "}
								Pricing
							</h2>
							<div
								className="price-slider"
								style={{ color: "#ffffff", fontSize: "1.5rem" }}
							>
								<div>Annually</div>
								<label className="switch">
									<input
										onClick={toggleClass}
										type="checkbox"
									/>
									<span className="slider round"></span>
								</label>
								<div>Monthly</div>
							</div>
							<div className="u-list u-list-1">
								<div className="u-repeater u-repeater-1"></div>
							</div>
							<section
								className="main pricing"
								style={{ paddingBottom: "3rem" }}
							>
								{isYearly === false ? (
									<div className="card-deck mb-3 text-center">
										<div className="card mb-4 box-shadow">
											<div className="card-header">
												<h4 className="my-0 font-weight-normal">
													Basic
												</h4>
											</div>
											<div className="card-body">
												<h1 className="card-title pricing-card-title">
													$0{" "}
													<small className="text-muted">
														/ yr
													</small>
												</h1>
												<h4
													style={{
														visibility: "hidden",
													}}
												>
													{" "}
													n
												</h4>
												<ul className="list-unstyled mt-3 mb-4">
													<br />
													<li>
														<FontAwesome
															className="fa-solid fa-check"
															name="check"
															style={{
																display:
																	"inline-block",
																textAlign:
																	"center",
																marginRight:
																	".5em",
																color: "#007bff",
															}}
														/>
														Halal Stock Screener (Top
														3000 companies)
													</li>
													<br />
													<li>
														<FontAwesome
															className="fa-solid fa-check"
															name="check"
															style={{
																display:
																	"inline-block",
																textAlign:
																	"center",
																marginRight:
																	".5em",
																color: "#007bff",
															}}
														/>
														10 Year Financial Data
														(Top 3000 companies)
													</li>
													<br />
													<li>
														<li>
															<FontAwesome
																className="fa-solid fa-check"
																name="check"
																style={{
																	display:
																		"inline-block",
																	textAlign:
																		"center",
																	marginRight:
																		".5em",
																	color: "#007bff",
																}}
															/>
															Trade Simulator
														</li>
														<br />
														<FontAwesome
															className="fa-solid fa-check"
															name="check"
															style={{
																display:
																	"inline-block",
																textAlign:
																	"center",
																marginRight:
																	".5em",
																color: "#007bff",
															}}
														/>
														Educational Resources
														(Limited)
													</li>
													<br />
													<br />
													<br />
													<br />
													<br />
													<br />
													<br />
													<br />
													<br />
												</ul>
												{auth.account ? (
													<button
														onClick={() =>
															navigate("/account")
														}
														type="button"
														className="btn btn-lg btn-block btn-outline-primary"
													>
														Sign up for free
													</button>
												) : (
													<button
														onClick={() =>
															navigate(
																"/register",
																{
																	state: {
																		plan: "B",
																	},
																}
															)
														}
														type="button"
														className="btn btn-lg btn-block btn-outline-primary"
													>
														Sign up for free
													</button>
												)}
											</div>
										</div>
										<div className="card mb-4 box-shadow">
											<div className="card-header">
												<h4 className="my-0 font-weight-normal">
													Advanced
												</h4>
											</div>
											<div className="card-body">
												<h1 className="card-title pricing-card-title">
													$11.99{" "}
													<small className="text-muted">
														/ mo
													</small>
												</h1>
												<h4>
													<strong>
														Billed Annually
													</strong>
												</h4>
												<ul className="list-unstyled mt-3 mb-4">
													<br />
													<li>
														<FontAwesome
															className="fa-solid fa-check"
															name="check"
															style={{
																display:
																	"inline-block",
																textAlign:
																	"center",
																marginRight:
																	".5em",
																color: "#007bff",
															}}
														/>
														Halal Stock Screener
														(Top 3000 US companies)
													</li>
													<br />
													<li>
														<FontAwesome
															className="fa-solid fa-check"
															name="check"
															style={{
																display:
																	"inline-block",
																textAlign:
																	"center",
																marginRight:
																	".5em",
																color: "#007bff",
															}}
														/>
														Historical Top 30
														Companies screened for
														value based on our
														market beating valuation
														formula. (Halal List and
														Standard Stocks List)
													</li>
													<br />
													<li>
														<FontAwesome
															className="fa-solid fa-check"
															name="check"
															style={{
																display:
																	"inline-block",
																textAlign:
																	"center",
																marginRight:
																	".5em",
																color: "#007bff",
															}}
														/>
														10 Year Financial Data
														(Top 3000 US companies)
													</li>
													<br />
													<li>
														<FontAwesome
															className="fa-solid fa-check"
															name="check"
															style={{
																display:
																	"inline-block",
																textAlign:
																	"center",
																marginRight:
																	".5em",
																color: "#007bff",
															}}
														/>
														Trade Simulator
													</li>
													<br />
													<li>
														<FontAwesome
															className="fa-solid fa-check"
															name="check"
															style={{
																display:
																	"inline-block",
																textAlign:
																	"center",
																marginRight:
																	".5em",
																color: "#007bff",
															}}
														/>
														Educational Resources
														(complete archives)
													</li>
													<br />
													<br />
													<br />
													<br />
													<br />
												</ul>

												{auth.account ? (
													<button
														onClick={() =>
															navigate(
																"/secure-payment",
																{
																	state: {
																		plan: "AY",
																		uid: auth
																			.account
																			.id,
																	},
																}
															)
														}
														type="button"
														className="btn btn-lg btn-block btn-primary"
													>
														Sign up
													</button>
												) : (
													<button
														onClick={() =>
															navigate(
																"/register",
																{
																	state: {
																		plan: "AY",
																	},
																}
															)
														}
														type="button"
														className="btn btn-lg btn-block btn-primary"
													>
														Sign up
													</button>
												)}
											</div>
										</div>
										<div className="card mb-4 box-shadow">
											<div className="card-header">
												<h4 className="my-0 font-weight-normal">
													Pro
												</h4>
											</div>
											<div className="card-body">
												<h1 className="card-title pricing-card-title">
													$14.99{" "}
													<small className="text-muted">
														/ mo
													</small>
												</h1>
												<h4>
													<strong>
														Billed Annually
													</strong>
												</h4>
												<ul className="list-unstyled mt-3 mb-4">
													<br />
													<li>
														<FontAwesome
															className="fa-solid fa-check"
															name="check"
															style={{
																display:
																	"inline-block",
																textAlign:
																	"center",
																marginRight:
																	".5em",
																color: "#007bff",
															}}
														/>
														Halal Stock Screener
														(Top 3000 US companies)
													</li>
													<br />
													<li>
														<FontAwesome
															className="fa-solid fa-check"
															name="check"
															style={{
																display:
																	"inline-block",
																textAlign:
																	"center",
																marginRight:
																	".5em",
																color: "#007bff",
															}}
														/>
														Historical Top 30
														Companies screened for
														value based on our
														market beating valuation
														formula. (Halal List and
														Standard Stocks List)
													</li>
													<br />
													<li>
														<FontAwesome
															className="fa-solid fa-check"
															name="check"
															style={{
																display:
																	"inline-block",
																textAlign:
																	"center",
																marginRight:
																	".5em",
																color: "#007bff",
															}}
														/>
														Current Top 30 Companies
														screened for value based
														on our market beating
														valuation formula.
														(Halal List and Standard
														Stocks List)
													</li>
													<br />
													<li>
														<FontAwesome
															className="fa-solid fa-check"
															name="check"
															style={{
																display:
																	"inline-block",
																textAlign:
																	"center",
																marginRight:
																	".5em",
																color: "#007bff",
															}}
														/>
														10 Year Financial Data
														(Top 3000 US companies)
													</li>
													<br />
													<li>
														<FontAwesome
															className="fa-solid fa-check"
															name="check"
															style={{
																display:
																	"inline-block",
																textAlign:
																	"center",
																marginRight:
																	".5em",
																color: "#007bff",
															}}
														/>
														Trade Simulator
													</li>
													<br />
													<li>
														<FontAwesome
															className="fa-solid fa-check"
															name="check"
															style={{
																display:
																	"inline-block",
																textAlign:
																	"center",
																marginRight:
																	".5em",
																color: "#007bff",
															}}
														/>
														Educational Resources
														(complete archives)
													</li>
													<br />
												</ul>
												{auth.account ? (
													<button
														onClick={() =>
															navigate(
																"/secure-payment",
																{
																	state: {
																		plan: "PY",
																		uid: auth
																			.account
																			.id,
																	},
																}
															)
														}
														type="button"
														className="btn btn-lg btn-block btn-primary"
													>
														Sign up
													</button>
												) : (
													<button
														onClick={() =>
															navigate(
																"/register",
																{
																	state: {
																		plan: "PY",
																	},
																}
															)
														}
														type="button"
														className="btn btn-lg btn-block btn-primary"
													>
														Sign up
													</button>
												)}
											</div>
										</div>
									</div>
								) : (
									<div className="card-deck mb-3 text-center">
										<div className="card mb-4 box-shadow">
											<div className="card-header">
												<h4 className="my-0 font-weight-normal">
													Basic
												</h4>
											</div>
											<div className="card-body">
												<h1 className="card-title pricing-card-title">
													$0{" "}
													<small className="text-muted">
														/ mo
													</small>
												</h1>
												<h4
													style={{
														visibility: "hidden",
													}}
												>
													{" "}
													n
												</h4>
												<ul className="list-unstyled mt-3 mb-4">
													<br />
													<li>
														<FontAwesome
															className="fa-solid fa-check"
															name="check"
															style={{
																display:
																	"inline-block",
																textAlign:
																	"center",
																marginRight:
																	".5em",
																color: "#007bff",
															}}
														/>
														Halal Stock Screener (Top
														3000 companies)
													</li>
													<br />
													<li>
														<FontAwesome
															className="fa-solid fa-check"
															name="check"
															style={{
																display:
																	"inline-block",
																textAlign:
																	"center",
																marginRight:
																	".5em",
																color: "#007bff",
															}}
														/>
														10 Year Financial Data
														(Top 3000 companies)
													</li>
													<br />
													<li>
														<FontAwesome
															className="fa-solid fa-check"
															name="check"
															style={{
																display:
																	"inline-block",
																textAlign:
																	"center",
																marginRight:
																	".5em",
																color: "#007bff",
															}}
														/>
														Trade Simulator
													</li>
													<br />
													<li>
														<FontAwesome
															className="fa-solid fa-check"
															name="check"
															style={{
																display:
																	"inline-block",
																textAlign:
																	"center",
																marginRight:
																	".5em",
																color: "#007bff",
															}}
														/>
														Educational Resources
														(Limited)
													</li>
													<br />
													<br />
													<br />
													<br />
													<br />
													<br />
													<br />
													<br />
													<br />
												</ul>

												{auth.account ? (
													<button
														onClick={() =>
															navigate("/account")
														}
														type="button"
														className="btn btn-lg btn-block btn-outline-primary"
													>
														Sign up for free
													</button>
												) : (
													<button
														onClick={() =>
															navigate(
																"/register",
																{
																	state: {
																		plan: "B",
																	},
																}
															)
														}
														type="button"
														className="btn btn-lg btn-block btn-outline-primary"
													>
														Sign up for free
													</button>
												)}
											</div>
										</div>
										<div className="card mb-4 box-shadow">
											<div className="card-header">
												<h4 className="my-0 font-weight-normal">
													Advanced
												</h4>
											</div>
											<div className="card-body">
												<h1 className="card-title pricing-card-title">
													$13.99{" "}
													<small className="text-muted">
														/ mo
													</small>
												</h1>
												<h4>
													<strong>
														Billed Quarterly
													</strong>
												</h4>
												<ul className="list-unstyled mt-3 mb-4">
													<br />
													<li>
														<FontAwesome
															className="fa-solid fa-check"
															name="check"
															style={{
																display:
																	"inline-block",
																textAlign:
																	"center",
																marginRight:
																	".5em",
																color: "#007bff",
															}}
														/>
														Halal Stock Screener
														(Top 3000 US companies)
													</li>
													<br />
													<li>
														<FontAwesome
															className="fa-solid fa-check"
															name="check"
															style={{
																display:
																	"inline-block",
																textAlign:
																	"center",
																marginRight:
																	".5em",
																color: "#007bff",
															}}
														/>
														Historical Top 30
														Companies screened for
														value based on our
														market beating valuation
														formula. (Halal List and
														Standard Stocks List)
													</li>
													<br />
													<li>
														<FontAwesome
															className="fa-solid fa-check"
															name="check"
															style={{
																display:
																	"inline-block",
																textAlign:
																	"center",
																marginRight:
																	".5em",
																color: "#007bff",
															}}
														/>
														10 Year Financial Data
														(Top 3000 US companies)
													</li>
													<br />
													<li>
														<FontAwesome
															className="fa-solid fa-check"
															name="check"
															style={{
																display:
																	"inline-block",
																textAlign:
																	"center",
																marginRight:
																	".5em",
																color: "#007bff",
															}}
														/>
														Trade Simulator
													</li>
													<br />
													<li>
														<FontAwesome
															className="fa-solid fa-check"
															name="check"
															style={{
																display:
																	"inline-block",
																textAlign:
																	"center",
																marginRight:
																	".5em",
																color: "#007bff",
															}}
														/>
														Educational Resources
														(complete archives)
													</li>
													<br />
													<br />
													<br />
													<br />
													<br />
												</ul>
												{auth.account ? (
													<button
														onClick={() =>
															navigate(
																"/secure-payment",
																{
																	state: {
																		plan: "AM",
																		uid: auth
																			.account
																			.id,
																	},
																}
															)
														}
														type="button"
														className="btn btn-lg btn-block btn-primary"
													>
														Sign up
													</button>
												) : (
													<button
														onClick={() =>
															navigate(
																"/register",
																{
																	state: {
																		plan: "AM",
																	},
																}
															)
														}
														type="button"
														className="btn btn-lg btn-block btn-primary"
													>
														Sign up
													</button>
												)}
											</div>
										</div>
										<div className="card mb-4 box-shadow">
											<div className="card-header">
												<h4 className="my-0 font-weight-normal">
													Pro
												</h4>
											</div>
											<div className="card-body">
												<h1 className="card-title pricing-card-title">
													$16.99{" "}
													<small className="text-muted">
														/ mo
													</small>
												</h1>
												<h4>
													<strong>
														Billed Quarterly
													</strong>
												</h4>
												<ul className="list-unstyled mt-3 mb-4">
													<br />
													<li>
														<FontAwesome
															className="fa-solid fa-check"
															name="check"
															style={{
																display:
																	"inline-block",
																textAlign:
																	"center",
																marginRight:
																	".5em",
																color: "#007bff",
															}}
														/>
														Halal Stock Screener
														(Top 3000 US companies)
													</li>
													<br />
													<li>
														<FontAwesome
															className="fa-solid fa-check"
															name="check"
															style={{
																display:
																	"inline-block",
																textAlign:
																	"center",
																marginRight:
																	".5em",
																color: "#007bff",
															}}
														/>
														Historical Top 30
														Companies screened for
														value based on our
														market beating valuation
														formula. (Halal List and
														Standard Stocks List)
													</li>
													<br />
													<li>
														<FontAwesome
															className="fa-solid fa-check"
															name="check"
															style={{
																display:
																	"inline-block",
																textAlign:
																	"center",
																marginRight:
																	".5em",
																color: "#007bff",
															}}
														/>
														Current Top 30 Companies
														screened for value based
														on our market beating
														valuation formula.
														(Halal List and Standard
														Stocks List)
													</li>
													<br />
													<li>
														<FontAwesome
															className="fa-solid fa-check"
															name="check"
															style={{
																display:
																	"inline-block",
																textAlign:
																	"center",
																marginRight:
																	".5em",
																color: "#007bff",
															}}
														/>
														10 Year Financial Data
														(Top 3000 US companies)
													</li>
													<br />
													<li>
														<FontAwesome
															className="fa-solid fa-check"
															name="check"
															style={{
																display:
																	"inline-block",
																textAlign:
																	"center",
																marginRight:
																	".5em",
																color: "#007bff",
															}}
														/>
														Trade Simulator
													</li>
													<br />
													<li>
														<FontAwesome
															className="fa-solid fa-check"
															name="check"
															style={{
																display:
																	"inline-block",
																textAlign:
																	"center",
																marginRight:
																	".5em",
																color: "#007bff",
															}}
														/>
														Educational Resources
														(complete archives)
													</li>
													<br />
												</ul>
												{auth.account ? (
													<button
														onClick={() =>
															navigate(
																"/secure-payment",
																{
																	state: {
																		plan: "PM",
																		uid: auth
																			.account
																			.id,
																	},
																}
															)
														}
														type="button"
														className="btn btn-lg btn-block btn-primary"
													>
														Sign up
													</button>
												) : (
													<button
														onClick={() =>
															navigate(
																"/register",
																{
																	state: {
																		plan: "PM",
																	},
																}
															)
														}
														type="button"
														className="btn btn-lg btn-block btn-primary"
													>
														Sign up
													</button>
												)}
											</div>
										</div>
									</div>
								)}
							</section>
						</div>
					</section>
					<section
						className="u-align-center u-clearfix u-section-7"
						id="carousel_5247"
					>
						<div className="u-clearfix u-sheet u-sheet-1">
							<div className="u-expanded-width u-list u-list-1">
								<div className="u-repeater u-repeater-1">
									<AnimationOnScroll animateIn="animate__pulse">
										<div
											className="u-align-center u-container-style u-list-item u-radius-30 u-repeater-item u-shape-round u-white u-list-item-1"
											data-animation-name="pulse"
											data-animation-duration="1000"
											data-animation-direction=""
										>
											<div className="u-container-layout u-similar-container u-valign-top u-container-layout-1">
												<span className="u-border-5 u-border-custom-color-3 u-custom-color-3 u-file-icon u-icon u-icon-circle u-text-white u-icon-1">
													<img
														src={pf3498cb9}
														alt=""
													/>
												</span>
												<h5 className="u-align-left u-custom-font u-font-ubuntu u-text u-text-1">
													What is Islamic Finance?
												</h5>
												<p className="u-align-left u-text u-text-2">
													{" "}
													Islamic finance is
													equity-based, asset-backed,
													ethical, sustainable,
													environmentally- and
													socially-responsible
													finance. It promotes risk
													sharing, connects the
													financial sector with the
													real economy, and emphasizes
													financial inclusion and
													social welfare.
													<br />
													<br />
												</p>
												<span className="u-file-icon u-icon u-text-black u-icon-2">
													<Link
														to="/main-article?title=understanding-halal-investing"
														
													>
														<img
															src={peea0f8a0}
															alt=""
														/>
													</Link>
												</span>
											</div>
										</div>
									</AnimationOnScroll>
									<AnimationOnScroll animateIn="animate__pulse">
										<div
											className="u-align-center u-container-style u-list-item u-radius-30 u-repeater-item u-shape-round u-white u-list-item-2"
											data-animation-name="pulse"
											data-animation-duration="1000"
											data-animation-direction=""
										>
											<div className="u-container-layout u-similar-container u-valign-top u-container-layout-2">
												<span className="u-border-5 u-border-custom-color-3 u-custom-color-3 u-file-icon u-icon u-icon-circle u-text-white u-icon-3">
													<img
														src={p4f1aa8a8}
														alt=""
													/>
												</span>
												<h5 className="u-align-left u-custom-font u-font-ubuntu u-text u-text-3">
													Why should I invest in
													stocks?
												</h5>
												<p className="u-align-left u-text u-text-4">
													{" "}
													Investing in stocks is a way
													for Muslim families to
													prepare for life essentials
													such as educating children,
													home ownership, haaj, or
													retirement.&nbsp; The
													historical growth of the
													stock market provides a
													risk-controlled method to
													build and protect wealth.
												</p>
												<span className="u-file-icon u-icon u-text-black u-icon-4">
													<Link
														to="/main-article?title=the-case-for-investing-in-stocks"
													>
														<img
															src={peea0f8a0}
															alt=""
														/>
													</Link>
												</span>
											</div>
										</div>
									</AnimationOnScroll>
									<AnimationOnScroll animateIn="animate__pulse">
										<div
											className="u-align-center u-container-align-center u-container-style u-list-item u-radius-30 u-repeater-item u-shape-round u-white u-list-item-3"
											data-animation-name="pulse"
											data-animation-duration="1000"
											data-animation-direction=""
										>
											<div className="u-container-layout u-similar-container u-valign-top u-container-layout-3">
												<span className="u-border-5 u-border-custom-color-3 u-custom-color-3 u-file-icon u-icon u-icon-circle u-text-white u-icon-5">
													<img
														src={p59868b49}
														alt=""
													/>
												</span>
												<h5 className="u-align-left u-custom-font u-font-ubuntu u-text u-text-5">
													How does WealthSeed find
													value stocks?
												</h5>
												<p className="u-align-left u-text u-text-6">
													The goal of value investing
													is to find good companies at
													bargain prices.&nbsp; The
													WealthSeed Halal Investment
													Formula is simple and easy
													to understand and allows
													anyone to outperform the
													market.
													<br />
													<br />
													<br />
												</p>
												<span className="u-file-icon u-icon u-text-black u-icon-6">
													<Link
														to="/main-article?title=the-wealthseed-halal-value-investing-formula"
													>
														<img
															src={peea0f8a0}
															alt=""
														/>
													</Link>
												</span>
											</div>
										</div>
									</AnimationOnScroll>
								</div>
							</div>
						</div>
					</section>

					<section
						className="u-clearfix u-grey-10 u-section-8"
						id="sec-8f3b"
					>
						<div className="u-clearfix u-sheet u-sheet-1">
							<div className="u-clearfix u-gutter-30 u-layout-wrap u-layout-wrap-1">
								<div className="u-layout">
									<div className="u-layout-row">
										<div className="u-align-left u-container-style u-layout-cell u-left-cell u-size-30 u-layout-cell-1">
											<div className="u-container-layout u-container-layout-1">
												<div className="u-align-left u-expanded u-video">
													<YoutubeEmbed embedId="FPvuPh-8-Fo" />
												</div>
											</div>
										</div>
										<div className="u-align-left u-container-style u-layout-cell u-size-30 u-layout-cell-2">
											<div className="u-container-layout u-container-layout-2">
												<div className="u-align-left u-expanded u-video">
													<YoutubeEmbed embedId="CWZlLt0mYuQ" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
		</Layout>
	);
}

export default Home;
