import React, { Fragment, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../store/index.ts";
import FontAwesome from "react-fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import authSlice from "../store/slices/auth.ts";
import transparent_main_logo_white from "../images/transparent_main_logo_white.png";

function Header() {
   const [isMenu, setisMenu] = useState(false);

   const [isTop30Responsiveclose, setTop30Responsiveclose] = useState(false);
   const [isTop30Menu, setisTop30Menu] = useState(false);

   const [isMembershipResponsiveclose, setMembershipResponsiveclose] = useState(false);
   const [isMembershipMenu, setisMembershipMenu] = useState(false);

   const [isResponsiveclose, setResponsiveclose] = useState(false);
   const toggleClass = () => {
      setisMenu(isMenu === false ? true : false);
      setResponsiveclose(isResponsiveclose === false ? true : false);
   };
   const toggleTop30Class = () => {
      setisTop30Menu(isMenu === false ? true : false);
      setTop30Responsiveclose(isResponsiveclose === false ? true : false);
   };

      const toggleMembershipClass = () => {
      setisMembershipMenu(isMenu === false ? true : false);
      setMembershipResponsiveclose(isResponsiveclose === false ? true : false);
   };

   let boxClass = ["main-menu menu-right menuq1"];
   if (isMenu) {
      boxClass.push("menuq2");
   } else {
      boxClass.push("");
   }

   const [isMenuSubMenu, setMenuSubMenu] = useState(false);
   const [isTop30MenuSubMenu, setTop30MenuSubMenu] = useState(false);
   const [isMembershipMenuSubMenu, setMembershipMenuSubMenu] = useState(false);

   const toggleSubmenu = () => {
      setMenuSubMenu(isMenuSubMenu === false ? true : false);
   };
   const toggleTop30Submenu = () => {
      setTop30MenuSubMenu(isTop30MenuSubMenu === false ? true : false);
   };
   const toggleMembershipSubmenu = () => {
      setMembershipMenuSubMenu(isMembershipMenuSubMenu === false ? true : false);
   };


   let boxClassSubMenu = [""];
   if (isMenuSubMenu) {
      boxClassSubMenu.push("sub_menu_active");
   } else {
      boxClassSubMenu.push("sub_menu");
   }


   let boxClassSubMenuTop30 = [""];
   if (isTop30MenuSubMenu) {
      boxClassSubMenuTop30.push("sub_menu_active");
   } else {
      boxClassSubMenuTop30.push("sub_menu");
   }
  let boxClassSubMenuMembership = [""];
   if (isMembershipMenuSubMenu) {
      boxClassSubMenuMembership.push("sub_menu_active");
   } else {
      boxClassSubMenuMembership.push("sub_menu");
   }


   let responsiveMenuClass = [""];
   if (isResponsiveclose) {
      responsiveMenuClass.push("");
   } else {
      responsiveMenuClass.push("navPanelToggle");
   }

   const dispatch = useDispatch();
   const navigate = useNavigate();

   const handleLogout = () => {
      dispatch(authSlice.actions.logout());
      navigate("/");
   };
   const auth = useSelector((state: RootState) => state.auth);

   return (
      <div>

         <header id="header" className="clearfix">
            <div className="inner">
            <div className="logo-div">
               <Link className="logo_a" to="/">
                  <div className="logo">
                     <img src={transparent_main_logo_white} />
                  </div>
                  
               </Link>
               </div>
               <nav id="nav">
                  <ul>
                     <li>
                        <Link to="/">Home</Link>
                     </li>
                           <li className="here_now" onClick={toggleMembershipSubmenu}>
                              <a className={boxClassSubMenuMembership.join("_")}>
                                 Membership
                              </a>

                              <ul className={boxClassSubMenuMembership.join("")}>
                                 <li>
                                    <NavLink
                                       id="membership_link"
                                       className={({ isActive }) =>
                                          isActive ? "active-style" : "none"
                                       }
                                       to={`/membership-benefits`}
                                    >
                                       Why Join?
                                    </NavLink>
                                 </li>
                                 <li>
                                    <NavLink
                                       id="screener_method_link"
                                       className={({ isActive }) =>
                                          isActive ? "active-style" : "none"
                                       }
                                       to={`/screener-methodology`}
                                    >
                                       Screener
                                    </NavLink>
                                 </li>
                                 <li>
                                    <NavLink
                                       id="membership_pricing_link"
                                       onClick={toggleMembershipClass}
                                       className={({ isActive }) =>
                                          isActive ? "active-style" : "none"
                                       }
                                       to={`/pricing`}
                                    >
                                       Pricing
                                    </NavLink>
                                 </li>
                              </ul>
                           </li>
                     <li>
                        <Link to="/learningcenter">Learning Center</Link>
                     </li>
                     <li>
                        <Link to="/companies">Company Data</Link>
                     </li>
                           <li className="here_now" onClick={toggleTop30Submenu}>
                              <a className={boxClassSubMenuTop30.join("_")}>
                                 Top 30
                              </a>

                              <ul className={boxClassSubMenuTop30.join("")}>
                                 <li>
                                    <NavLink
                                       id="current_top30_link"
                                       className={({ isActive }) =>
                                          isActive ? "active-style" : "none"
                                       }
                                       to={`/current-top30`}
                                    >
                                       Current
                                    </NavLink>
                                 </li>
                                 <li>
                                    <NavLink
                                       id="historical_top30_link"
                                       onClick={toggleTop30Class}
                                       className={({ isActive }) =>
                                          isActive ? "active-style" : "none"
                                       }
                                       to={`/top30`}
                                    >
                                       Historical
                                    </NavLink>
                                 </li>
                                 <li>
                                    <NavLink
                                       id="top30_calculator_link"
                                       onClick={toggleTop30Class}
                                       className={({ isActive }) =>
                                          isActive ? "active-style" : "none"
                                       }
                                       to={`/top30-calculator`}
                                    >
                                       Calculator
                                    </NavLink>
                                 </li>
                              </ul>
                           </li>
                     {auth.account ? (
                        <>
                           <li>
                              <Link to="/trade-simulator">Trade Simulator</Link>
                           </li>
                           <li>
                              <Link to="/portfolio">Portfolio</Link>
                           </li>
                           <li className="here_now" onClick={toggleSubmenu}>
                              <a className={boxClassSubMenu.join("_")}>
                                 Account
                              </a>

                              <ul className={boxClassSubMenu.join("")}>
                                 <li>
                                    <NavLink
                                       id="account_link"
                                       onClick={toggleClass}
                                       className={({ isActive }) =>
                                          isActive ? "active-style" : "none"
                                       }
                                       to={`/account`}
                                    >
                                       Account
                                    </NavLink>
                                 </li>
                                 <li onClick={handleLogout}>
                                    <NavLink
                                       id="logout_link"
                                       onClick={toggleClass}
                                       className={({ isActive }) =>
                                          isActive ? "active-style" : "none"
                                       }
                                       to={`/`}
                                    >
                                       Logout
                                    </NavLink>
                                 </li>
                              </ul>
                           </li>
                        </>
                     ) : (
                        <li>
                           <Link to="/login">Login</Link>
                        </li>
                     )}
                  </ul>
               </nav>
               {isResponsiveclose === true ? (
                  <></>
               ) : (
                  <div className="nav-responsive-div">
                     <a
                        href="#navPanel"
                        onClick={toggleClass}
                        className="navPanelToggle"
                     >
                        <span className="label">Menu</span>
                     </a>
                  </div>
               )}
            </div>
         </header>
         {isResponsiveclose === true ? (
            <>
               <div id="navPanel" className="visible">
                  <nav>
                     <a
                        className="link depth-0"
                        href="/"
                        style={{ WebkitTapHighlightColor: "rgba(0, 0, 0, 0)" }}
                     >
                        <span className="indent-0"></span>Home
                     </a>
                     <>
                           <a
                              className="link depth-0"
                              href="#"
                              style={{
                                 WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                              }}
                           >
                              <span className="indent-0"></span>Membership
                           </a>
                           <div
                              className="link depth-1"
                              style={{
                                 WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                              }}
                           >
                              <NavLink
                                 id="membership_link"
                                 onClick={toggleMembershipClass}
                                 className={({ isActive }) =>
                                    isActive ? "active-style" : "none"
                                 }
                                 to={`/membership-benefits`}
                              >
                                 <span className="indent-1"></span>Why Join?
                              </NavLink>
                           </div>
                           <div
                              className="link depth-1"
                              style={{
                                 WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                              }}
                           >
                              <NavLink
                                 id="membership_link"
                                 onClick={toggleMembershipClass}
                                 className={({ isActive }) =>
                                    isActive ? "active-style" : "none"
                                 }
                                 to={`/screener-methodology`}
                              >
                                 <span className="indent-1"></span>Screener
                              </NavLink>
                           </div>

                           <div
                              className="link depth-1"
                              style={{
                                 WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                              }}
                           >
                              <NavLink
                                 id="membership_pricing_link"
                                 onClick={toggleMembershipClass}
                                 className={({ isActive }) =>
                                    isActive ? "active-style" : "none"
                                 }
                                 to={`/pricing`}
                              >
                                 <span className="indent-1"></span>Pricing
                              </NavLink>
                           </div>
                        </>
                     <a
                        className="link depth-0"
                        href="/learningcenter"
                        style={{ WebkitTapHighlightColor: "rgba(0, 0, 0, 0)" }}
                     >
                        <span className="indent-0"></span>Learning Center
                     </a>
                     <a
                        className="link depth-0"
                        href="/companies"
                        style={{ WebkitTapHighlightColor: "rgba(0, 0, 0, 0)" }}
                     >
                        <span className="indent-0"></span>Company Data
                     </a>
                     <>
                           <a
                              className="link depth-0"
                              href="#"
                              style={{
                                 WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                              }}
                           >
                              <span className="indent-0"></span>Top 30
                           </a>
                           <div
                              className="link depth-1"
                              style={{
                                 WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                              }}
                           >
                              <NavLink
                                 id="current_top30_link"
                                 onClick={toggleTop30Class}
                                 className={({ isActive }) =>
                                    isActive ? "active-style" : "none"
                                 }
                                 to={`/current-top30`}
                              >
                                 <span className="indent-1"></span>Current
                              </NavLink>
                           </div>

                           <div
                              className="link depth-1"
                              style={{
                                 WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                              }}
                           >
                              <NavLink
                                 id="historical_top30_link"
                                 onClick={toggleTop30Class}
                                 className={({ isActive }) =>
                                    isActive ? "active-style" : "none"
                                 }
                                 to={`/top30`}
                              >
                                 <span className="indent-1"></span>Historical
                              </NavLink>
                           </div>

                           <div
                              className="link depth-1"
                              style={{
                                 WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                              }}
                           >
                              <NavLink
                                 id="top30_calculator_link"
                                 onClick={toggleTop30Class}
                                 className={({ isActive }) =>
                                    isActive ? "active-style" : "none"
                                 }
                                 to={`/top30-calculator`}
                              >
                                 <span className="indent-1"></span>Calculator
                              </NavLink>
                           </div>
                        </>
                     <a
                        className="link depth-0"
                        href="/trade-simulator"
                        style={{ WebkitTapHighlightColor: "rgba(0, 0, 0, 0)" }}
                     >
                        <span className="indent-0"></span>Trade Simulator
                     </a>
                     <a
                        className="link depth-0"
                        href="/portfolio"
                        style={{ WebkitTapHighlightColor: "rgba(0, 0, 0, 0)" }}
                     >
                        <span className="indent-0"></span>Portfolio
                     </a>
                     <a
                        className="link depth-0"
                        href="/pricing"
                        style={{ WebkitTapHighlightColor: "rgba(0, 0, 0, 0)" }}
                     >
                        <span className="indent-0"></span>Pricing
                     </a>

                     {auth.account ? (
                        <>
                           <a
                              className="link depth-0"
                              href="#"
                              style={{
                                 WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                              }}
                           >
                              <span className="indent-0"></span>Account
                           </a>

                           <div
                              className="link depth-1"
                              style={{
                                 WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                              }}
                           >
                              <NavLink
                                 id="account_link"
                                 onClick={toggleClass}
                                 className={({ isActive }) =>
                                    isActive ? "active-style" : "none"
                                 }
                                 to={`/account`}
                              >
                                 <span className="indent-1"></span>Account
                              </NavLink>
                           </div>
                           <div
                              onClick={handleLogout}
                              className="link depth-1"
                              style={{
                                 WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                              }}
                           >
                              <NavLink
                                 id="logout_link"
                                 onClick={toggleClass}
                                 className={({ isActive }) =>
                                    isActive ? "active-style" : "none"
                                 }
                                 to={`/`}
                              >
                                 <span className="indent-1"></span>Logout
                              </NavLink>
                           </div>
                        </>
                     ) : (
                        <a
                           className="link depth-0"
                           href="/login"
                           style={{
                              WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                           }}
                        >
                           <span className="indent-0"></span>Login
                        </a>
                     )}
                  </nav>
                  <a
                     onClick={toggleClass}
                     href="#navPanel"
                     className="close"
                     style={{ WebkitTapHighlightColor: "rgba(0, 0, 0, 0)" }}
                  ></a>
               </div>
            </>
         ) : (
            <></>
         )}
      </div>
   );
}

export default Header;
