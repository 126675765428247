import React, { useEffect, useState } from "react";
import authSlice from "../store/slices/auth.ts";
import { useDispatch } from "react-redux";
import transparent_color_full_logo from "../images/transparent_color_full_logo.svg";
import { config } from './constants';





import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import Layout from "../screener/layout";

export default function CheckoutForm({
  chargeAmount,
  user_id,
  membership_plan,
}) {

  var REACT_APP_BASE_URL = config.url.REACT_APP_BASE_URL
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  // console.log(user_id, membership_plan, chargeAmount);

  let return_url = `${REACT_APP_BASE_URL}/payment-success/`;

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }
    // console.log(user_id, membership_plan, chargeAmount);
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe, dispatch, user_id, membership_plan]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    // console.log(user_id, membership_plan);
    dispatch(
      authSlice.actions.setAccount({
        //some data is fake.  It will be deleted on logout after payment posted.  Only the id and membership plan will be needed
        id: `${user_id}`,
        is_active: true,
        email: "willy@test.com",
        first_name: "willy",
        last_name: "nilly",
        date_joined: "2022-06-23T03:26:13.117978Z",
        member: {
          membership_plan: `${membership_plan}`,
          current_membership_start_date: "2022-06-23T03:26:13.117978Z",
          last_payment_date: null,
          current_payment_due_date: null,
        },
      })
    );
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: return_url,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  return (
    <Layout>
      <div id="main">
        <div className="inner">
          <div id="content">
            <div className="row">
              <div className="container">
                <div className="col-xl-10">
                  <div className="card rounded-3 text-black">
                    <div className="row g-0">
                      <div className="col-lg-6">
                        <div className="card-body p-md-5 mx-md-4">
                          <div className="text-center">
                            <img
                              src={transparent_color_full_logo}
                              style={{ width: "80%" }}
                              alt="logo"
                            />
                            <br />
                            <br />
                          </div>
                          <form id="payment-form" onSubmit={handleSubmit}>
                            <h4 style={{ fontWeight: "bold" }}>
                              Amount to be charged: ${chargeAmount}
                            </h4>
                            <PaymentElement id="payment-element" />
                            <br />
                            <button
                              disabled={isLoading || !stripe || !elements}
                              id="submit"
                            >
                              <span id="button-text">
                                {isLoading ? (
                                  <div className="spinner" id="spinner"></div>
                                ) : (
                                  "Pay now"
                                )}
                              </span>
                            </button>
                            {/* Show any error or success messages */}
                            {message && (
                              <div id="payment-message">{message}</div>
                            )}
                          </form>
                        </div>
                      </div>
                      <div className="col-lg-6 d-flex align-items-center gradient-custom-2">
                        <div className="text-white px-3 py-4 p-md-5 mx-md-4">
                          <h4 className="mb-4">
                            Where halal investing meets value investing
                          </h4>
                          <p className="small mb-0">
                            Investing in stocks is a way for Muslim families to prepare for life essentials such as educating children, home ownership, haaj, or retirement.  The historical growth of the stock market provides a risk-controlled method to build and protect wealth.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

