import React, { useEffect } from "react";
import { Link} from "react-router-dom";

import YoutubeEmbed from "./YoutubeEmbed";
import "animate.css/animate.min.css";
import TwitterContainer from "./twittercontainer";

import Layout from "./layout";

function MembershipBenefits() {


	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Layout>
			<div id="main">
				<div className="inner">
					<div id="content">
						<section className="main">
						<header>	
							<h1>Why Become a Member?</h1>
							<p>WealthSeed, LLC's Halal Stock Screener teaches you how to invest profitably in stocks of high-quality value companies that enbale you to build wealth and reach your long-term financial goals.</p>
						</header>
						<div className="row">
							<div>
								<h4>By Joining this Website you have access to:</h4>
								<ul>
									<li>Unbiased investment education.</li>
									<li>Education in Islamic Investing Principles.</li>
									<li>Updated halal screening of thousands of stocks.</li>
									<li>Effective halal value stocks analysis tools.</li>
									<li>Updated list of the Top 30 Halal Value Stocks screened with our Halal Value Investing Formula.</li>
									<li>Historical data on companies that allow you to know your investments.</li>
									<li>Trade simulator with current price data that allows you to test your strategy before pulling the trigger.</li>
									<li>Virtual Portfolio that helps you keep track of your investments.</li>
									<li>Peace of mind knowing that you are building halal wealth.</li>
								</ul>
							</div>
						</div>
						<header>	
							<h1>How to Gain Maximum Benefit From this Website</h1>
						</header>
						<div style={{'marginTop':'40px'}}>
							<YoutubeEmbed embedId="7PDe_RB2eNo" />
						</div>

						</section>
					</div>

						<div id="sidebar">
							<section className="features">
								<Link to="/companies" className="accent1">
									<h2>Company Data</h2>
									<p>
										Explore data and charts of company
										fundamentals.
									</p>
								</Link>
								<Link to="/learningcenter" className="accent2">
									<h2>Learning Center</h2>
									<p>
										Enhance your understanding of halal and
										value investing.
									</p>
								</Link>
								<Link to="/portfolio" className="accent3">
									<h2>Virtual Portfolio</h2>
									<p>
										Track your virtual trades and test your
										investing strategy.
									</p>
								</Link>
							</section>

							
			           		<section className="mini-posts minimal main" style={{'marginTop':'60px', 'textAlign':'center'}}>
			                	<header>
				                  	<h2>Tweets</h2>
				                  	<h4>@WealthSeedLLC</h4>
				                </header>
				                <article style={{'height':'900px', 'overflowY':'auto'}}>
				                	<TwitterContainer/>
				                </article>
			            	</section>

						</div>
				</div>
			</div>
		</Layout>
	);
}

export default MembershipBenefits;
