
// import memoize from "lodash.memoize";
import axiosService from "../utils/axios.ts";
import { config } from './constants'



const getFundamentalsData = (ticker) =>
  new Promise((resolve) => {
    var REACT_APP_API_URL = config.url.REACT_APP_API_URL;
     axiosService.get(`${REACT_APP_API_URL}/fundamentals/`.concat(ticker))
     .then((res) => {
      // console.log(res.data);
       resolve(res.data);
     });
  });
export default getFundamentalsData;

