import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Layout from "../screener/layout";
import { useSelector } from "react-redux";
import axios from "axios";
import store from "../store/index.ts";
import { useDispatch } from "react-redux";
import authSlice from "../store/slices/auth.ts";
import transparent_color_full_logo from "../images/transparent_color_full_logo.svg";
import { config } from './constants';


function PaymentSuccess() {
  var REACT_APP_API_URL = config.url.REACT_APP_API_URL
  var REACT_API_IMAGE_URL = config.url.REACT_API_IMAGE_URL
  
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.auth);
  const dataFetchedRef = useRef(false);


  useEffect(() => {
    const { token } = store.getState().auth;
    const user_id = auth.account?.id;
    const membership_plan = auth.account?.member.membership_plan;

    // Avoid hitting API twice
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;


      // axiosService.post(`${REACT_APP_API_URL}/member_update/`, {user_id, membership_plan})
      axios({
        method: "post",
        url: `${REACT_APP_API_URL}/member_update/`,
        data: { user_id, membership_plan },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer" + { token },
        },
      }).then((res) => {
        dispatch(authSlice.actions.logout());
      });
  }, []);

  return (
    <Layout>
      <div id="main">
        <div className="inner">
          <div id="content">
            <div className="col-xl-10">
              <div className="card rounded-3 text-black">
                <div className="row g-0">
                  <div className="col-lg-6">
                    <div className="card-body p-md-5 mx-md-4">
                      <div className="text-center">
                        <img
                          src={transparent_color_full_logo}
                          style={{ width: "80%" }}
                          alt="logo"
                        />
                        <br />
                        <br />
                      </div>

                      <form method="post">
                        <br />
                        <h4> Your Payment Was Successful!</h4>
                        <br />
                        <br />
                        <div className="text-center pt-1 mb-5 pb-1">
                          <Link to="/login">
                            <button
                              type="submit"
                              className="btn btn-primary btn-block fa-lg gradient-custom-3 mb-3"
                            >
                              Login Now
                            </button>
                          </Link>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-lg-6 d-flex align-items-center gradient-custom-2">
                    <div className="text-white px-3 py-4 p-md-5 mx-md-4">
                      <h4 className="mb-4">
                        Where halal investing meets value investing
                      </h4>
                      <p className="small mb-0">
                        Investing in stocks is a way for Muslim families to
                        prepare for life essentials such as educating children,
                        home ownership, haaj, or retirement. The historical
                        growth of the stock market provides a risk-controlled
                        method to build and protect wealth.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default PaymentSuccess;
