import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccountResponse } from "../../types";


type State = {
  token: string | null;
  account: AccountResponse | null;
  tickers: string[] | null;
};

const initialState: State = { token: null, account: null, tickers: []};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthTokens(
      state: State,
      action: PayloadAction<{ token: string}>
    ) {
      state.token = action.payload.token;
    },
    setAccount(state: State, action: PayloadAction<AccountResponse>) {
      state.account = action.payload;
    },
    setTickers(state: State, action: PayloadAction<{ tickers: string[]}>) {
      state.tickers = action.payload;
    },
    logout(state: State) {
      state.account = null;
      state.token = null;
      state.tickers = [];
    },

  },
});

export default authSlice;